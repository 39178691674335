<template>
  <div>
    <!-- content : 화면 리사이징 되는 화면 resize  -->
    <div class="content_box beforetab">
      <!-- content_box : 탭있을 경우 beforetab  추가 -->
      <div v-show="fromBkg === 'N'">
        <div class="popup_dim" id="sch_popup">
          <component
            v-if="customComponentSch"
            :is="customComponentSch"
            @close="closePopup"
            @selectFunc="selectFunc"
            @booking="bookingBooking2"
            :parent-info="parentInfoSch"
          />
        </div>
      </div>
      <form id="frmLeg">
        <div class="border_bottom_gray_box">
          <table class="tbl_search">
            <colgroup>
              <col width="90px"><col><col width="90px"><col>
            </colgroup>
            <tbody>
              <tr>
                <th>{{ $t('msg.VOSD100.002') }}</th>
                <td>
                  <e-auto-complete-place
                    :auto-focus="true"
                    id="polPol"
                    @change="changePol"
                    :ctr-cd="pol.ctrCd"
                    :plc-cd="pol.plcCd"
                    :is-char="true"
                    :is-char-alert="true"
                  />
                </td>
                <th><span class="ml20">{{ $t('msg.VOSD100.003') }}</span></th>
                <td>
                  <e-auto-complete-place
                    id="podPod"
                    @change="changePod"
                    :ctr-cd="pod.ctrCd"
                    :plc-cd="pod.plcCd"
                    :is-char="true"
                    :is-char-alert="true"
                  />
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.VOSD100.032') }}</th> <!-- 조회년월 -->
                <td colspan="2">
                  <span>
                    <div class="input_calendar mid">
                      <e-month-picker v-model="yyyymmLeg" />
                    </div>
                  </span>
                  <span class="ml15" v-show="bkgOrNot === false">
                    <input type="radio" id="trad1" name="trad" v-model="regParams.bound" value="O" checked="checked" />
                    <label for="trad1" class="mr10"><span></span>{{ $t('msg.VOSD100_M3.008') }}</label>
                    <input type="radio" id="trad2" name="trad" v-model="regParams.bound" value="I" />
                    <label for="trad2" class="mr10"><span></span>{{ $t('msg.VOSD100_M3.007') }}</label>
                  </span>
                </td>
                <td class="text_right">
                  <span>
                    <input type="checkbox" id="bk1" name="bk" v-model="regParams.checkBkg" true-value="Y" false-value="N" />
                    <label for="bk1" class="mr10"><span></span>{{ $t('msg.VOSD100_M5.013') }}<!-- 부킹가능한 선박 --></label>
                    <input type="checkbox" id="bk2" name="bk" v-model="regParams.rf" true-value="Y" false-value="N" />
                    <label for="bk2" class="mr10"><span></span>RF</label>
                  </span>
                  <span class="position_relative">
                    <button class="tbl_icon help" @click.prevent="showRFinfo('Y')">help</button>
                    <div class="tooltip_wrap position_absolute text_left" style="width:180px; right:0; top:24px;" v-show="RFinfo === 'Y'"><!-- tooltip_wrap -->
                      <div class="cont"><!-- cont -->
                        <button class="close" @click.prevent="showRFinfo('N')">close</button>
                        <ul class="bul_list_sm">
                          <li>
                            {{ $t('msg.VOSD100_M5.012') }}<!-- 냉동 컨테이너 기준<br/> Booking 가능 스케줄 조회 -->
                          </li>
                        </ul>
                      </div><!-- cont -->
                    </div><!-- tooltip_wrap // -->
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="border_bottom_gray_box" v-if="$ekmtcCommon.isNotEmpty(userCtrCd)">
          <!-- border_bottom_gray_box -->
          <p class="tit_label ml10">
            <input type="checkbox" id="myschtit" name="myschtit" v-model="clickMySchedule" true-value="Y" false-value="N" />
            <label for="myschtit"><span></span>{{ $t('msg.VOSD100_M5.014') }}<!-- 나의 스케줄 --></label>
          </p>
          <div class="ml10" style="display:block;" v-show="clickMySchedule === 'Y'">
            <ul class="my_sche">
              <li v-for="(list,index) in myScheduleList" :key="index">
                <input
                  type="radio"
                  :id="list.schId"
                  :value="list.schId"
                  :checked="list.schId === schId"
                  name="mysch"
                  @click="selectMySchedule(list)"
                />
                <label :for="list.schId"><span></span>{{ list.polNm2 }} - {{ list.podNm2 }}</label>
                <span class="d_inline_block valign_top ml10 mt2"><button class="tbl_icon delete" @click.prevent="fnDeleteMySchedule(list.schId)">delete</button></span>
              </li>
            </ul>
          </div>
        </div><!-- border_bottom_gray_box // -->
        <div class="border_bottom_gray_box border_bottom_none">
          <!-- border_bottom_gray_box -->
          <p class="tit_label ml10">
            <input type="checkbox" id="detailschtit" name="detailschtit" v-model="clickDetailSearch" true-value="Y" false-value="N" />
            <label for="detailschtit"><span></span>{{ $t('msg.VOSD100_M5.015') }}<!-- 상세검색 --></label>
          </p>
          <div class="ml23" style="display:block;" v-show="clickDetailSearch === 'Y'">
            <table class="tbl_search">
              <colgroup>
                <col width="100px"><col><col width="100px"><col>
              </colgroup>
              <tbody>
                <tr>
                  <th class="valign_top">{{ $t('msg.VOSD100.002') }} {{ $t('msg.VOSD100.039') }}</th>
                  <td>
                    <span class="d_block" v-for="(trml, idx) in filterPolTrmlList" :key="idx">
                      <input
                        :id="'filterPolTrmlCd' + idx"
                        type="checkbox"
                        v-model="filterPolTrmlCd"
                        :value="trml.split('@@')[0]"
                        :disabled="rtnLegData.filterPolTrmlListSize === 1"
                        @change="filterSearch()"
                      />
                      <label :for="'filterPolTrmlCd' + idx"><span></span>{{ trml.split('@@')[0] }} {{ trml.split('@@')[1] }}</label>
                    </span>
                  </td>
                  <th class="valign_top">{{ $t('msg.VOSD100.003') }} {{ $t('msg.VOSD100.039') }}</th>
                  <td>
                    <span class="d_block" v-for="(trml, idx) in filterPodTrmlList" :key="idx">
                      <input
                        :id="'filterPodTrmlCd' + idx"
                        type="checkbox"
                        v-model="filterPodTrmlCd"
                        :value="trml.split('@@')[0]"
                        :disabled="rtnLegData.filterPodTrmlListSize === 1"
                        @change="filterSearch()"
                      />
                      <label :for="'filterPodTrmlCd' + idx"><span></span>{{ trml.split('@@')[0] }} {{ trml.split('@@')[1] }}</label>
                    </span>
                  </td>
                </tr>
                <tr>
                  <th class="valign_top">{{ $t('msg.VOSD100_M4.006') }}</th>
                  <td colspan="3">
                    <span class="" style="width: 30%;display: inline-block;" v-for="(trml, idx) in filterRteCdList" :key="idx">
                      <input
                        :id="'filterRteCd' + idx"
                        type="checkbox"
                        v-model="filterRteCd"
                        :value="trml.split('@@')[0]"
                        :disabled="filterRteCdList.length === 1"
                        @change="filterSearch()"
                      />
                      <label :for="'filterRteCd' + idx"><span></span>{{ trml.split('@@')[0] }} {{ trml.split('@@')[1] }}</label>
                    </span>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('msg.VOSD100.056') }}<!-- Operator --></th>
                  <td>
                    <span>
                      <input type="checkbox" id="oper1" name="oper" v-model="regParams.vslType01" true-value="01" false-value="N" />
                      <label for="oper1"><span></span>KMTC</label>
                    </span>
                    <span class="ml20">
                      <input type="checkbox" id="oper2" name="oper" v-model="regParams.vslType03" true-value="03" false-value="N" />
                      <label for="oper2"><span></span>Joint</label>
                    </span>
                  </td>
                  <th>{{ $t('msg.VOSD100.057') }}<!-- Priority --></th>
                  <td>
                    <span>
                      <!-- <span> -->
                      <input
                        id="filterDirect"
                        type="checkbox"
                        v-model="regParams.filterDirect"
                        true-value="Y"
                        false-value="N"
                      />
                      <label for="filterDirect"><span></span>{{ $t('msg.VOSD100.060') }}<!-- Direct --></label>
                    </span>
                    <span class="ml20">
                      <!-- <span class="ml20"> -->
                      <input
                        id="filterTS"
                        type="checkbox"
                        v-model="regParams.filterTS"
                        true-value="Y"
                        false-value="N"
                      />
                      <label for="filterTS"><span></span>{{ $t('msg.VOSD100.061') }}<!-- T/S --></label>
                    </span>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('msg.VOSD100.058') }}<!-- DG --></th>
                  <td>
                    <div class="tbl_form">
                      <span class="dv col_7 position_relative">
                        <input type="text" disabled="disabled" v-model="unnoDescTxt">
                        <a v-if="$ekmtcCommon.isNotEmpty(unnoDescTxt)" @click.prevent="resetUnnoTxt()" class="btn_txt_reset">X</a>
                      </span>
                      <span class="sp"></span>
                      <span class="dv">
                        <a class="button sm" href="#" @click.prevent="openUnnoPopup">{{ $t('msg.VOSD100.059') }}<!-- UN No. --></a>
                      </span>
                    </div>
                  </td>
                  <th>{{ $t('art.CMBA660.011') }}</th>
                  <td>
                    <e-auto-complete-commodity class="col_8" id="scheSearchCommodity" @change="changeCommodity" :ctr-cd="ctrCd" :cd-nm="commodityNm" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div><!-- border_bottom_gray_box // -->
        <!-- 검색 버튼 -->
        <div class="position_relative">
          <!-- 검색(Search) -->
          <span class="position_absolute" style="right:0; bottom:5px;"><a class="button blue sh" @click="clickChk(searchYear, searchMonth)">{{ $t('msg.CMBA100.00120') }}</a></span>
        </div><!-- 검색(Search) -->
      <!-- Filter
      <div class="position_relative">
        <span class="position_absolute" style="right:0; bottom:5px;"><a class="button blue sh short" @click="filterSearch()">Filter</a></span>
      </div> Filter -->
      </form>
    </div><!-- content_box // -->
    <div class="content_box mt10">
      <!-- content_box  -->
      <div class="wrap_schedule">
        <!-- wrap_schedule -->
        <div class="schedule_top">
          <!-- schedule_top -->
          <div class="sail_area" v-show="afterSearch === 'Y'">
            <p class="port">
              <span>{{ startPlcNameSel[0] }}</span>
              <span>{{ destPlcNameSel[0] }}</span>
              <span class="icon" v-if="$ekmtcCommon.isNotEmpty(userCtrCd)">
                <div v-if="myScheduleOrNot === 'N'">
                  <button class="tbl_icon myschedule" @click="fnAddMySchedule(rtnLegData)"><!-- 나의스케줄등록 --></button>
                </div>
                <div v-else>
                  <button class="tbl_icon myschedule on" @click="deleteMyScheduleStar(schId)"><!-- 나의스케줄등록 --></button>
                </div>
              </span>
            </p>
            <ul v-show="rtnLegData.voyCntSum !== 0">
              <li><span>{{ $t('msg.VOSD100_M5.016') }}</span> <span class="num">{{ rtnLegData.monCnt }}</span></li>
              <li><span>{{ $t('msg.VOSD100_M5.017') }}</span> <span class="num">{{ rtnLegData.tueCnt }}</span></li>
              <li><span>{{ $t('msg.VOSD100_M5.018') }}</span> <span class="num">{{ rtnLegData.wedCnt }}</span></li>
              <li><span>{{ $t('msg.VOSD100_M5.019') }}</span> <span class="num">{{ rtnLegData.thuCnt }}</span></li>
              <li><span>{{ $t('msg.VOSD100_M5.020') }}</span> <span class="num">{{ rtnLegData.friCnt }}</span></li>
              <li><span>{{ $t('msg.VOSD100_M5.021') }}</span> <span class="num">{{ rtnLegData.satCnt }}</span></li>
              <li><span>{{ $t('msg.VOSD100_M5.022') }}</span> <span class="num">{{ rtnLegData.sunCnt }}</span></li>
              <li class="week"><span class="week">{{ $t('msg.VOSD100_M5.031') }}</span> <span class="num">{{ rtnLegData.voyCntSum }}</span> <span>{{ $t('msg.VOSD100_M5.023') }}<!-- 항차 --></span></li>
            </ul>
            <div class="btn_area">
              <span><a class="button sm" @click="openPopup('EmailSchedulePop')">{{ $t('msg.VOSD100.040') }}</a></span>
              <span><a class="button sm" @click="excelDownload()">Download</a></span>
            </div>
          </div>
          <div class="date_area">
            <!-- date_area --><!-- kmtc premium 회원이 아닌 경우 연월, 가운데 정렬 세팅 -->
            <div class="remark" :style="kmtcPremiumUserYn === 'Y' ? '' : 'width: 25%;'">
              <span class="finish">{{ $t('msg.CSBL100_M1.017') }}</span><!-- 마감 -->
              <span class="possible">{{ $t('msg.CSBL100_M1.013') }}</span><!-- 가능 -->
              <span class="after">{{ $t('msg.VOSD100_M5.125', { bkgNotCnt: bkgNotCnt }) }}</span><!-- 60일 이후 -->
            </div>
            <div class="date">
              <span><a href="#" @click.prevent="fnChangeCalendar(prevMonth)" class="prev">prev</a></span>
              <span class="year">{{ currentYear }}</span>
              <span class="month">{{ currentMonth }}</span>
              <span><a href="#" @click.prevent="fnChangeCalendar(nextMonth)" class="next">next</a></span>
              <!-- kmtc premium 아이콘 이미지, 안내문구 -->
              <span>&nbsp;&nbsp;&nbsp;&nbsp;<img v-show="kmtcPremiumUserYn === 'Y'" src="../../assets/images/common/icon_premium.png" style="vertical-align: middle;"></span>
              <span v-show="kmtcPremiumUserYn === 'Y'" style="font-size: 15px; vertical-align: middle; color: #0080db;">&nbsp;&nbsp; : &nbsp;&nbsp;선복보장 PREMIUM 부킹 가능 스케줄
                <a class="button sm" style="text-indent: 0px; width: 110px; line-height: 22px; " href="#" @click.prevent="pdfDownload('KMTC_ON_PREMIUM_FAQ_240717.pdf')">PREMIUM 부킹 FAQ</a></span>
              <!-- kmtc premium 아이콘 이미지, 안내문구 -->
            </div>
            <div class="memu">
              <a href="#" @click.prevent="viewCalendar('C')" :class="calendarOrList === 'C' ? 'month on' : 'month off'"></a>
              <a href="#" @click.prevent="viewCalendar('L')" :class="calendarOrList === 'L' ? 'list on' : 'list off'"></a>
            </div>
          </div><!-- date_area // -->
        </div><!-- schedule_top // -->
        <div class="sc_calender_type" v-show="calendarOrList === 'C'">
          <!-- 캘린더 타입 -->
          <table class="tbl_schedule">
            <colgroup>
              <col span="7" width="14.2%">
            </colgroup>
            <thead>
              <tr>
                <th>{{ $t('msg.VOSD100_M5.022') }}</th>
                <th>{{ $t('msg.VOSD100_M5.016') }}</th>
                <th>{{ $t('msg.VOSD100_M5.017') }}</th>
                <th>{{ $t('msg.VOSD100_M5.018') }}</th>
                <th>{{ $t('msg.VOSD100_M5.019') }}</th>
                <th>{{ $t('msg.VOSD100_M5.020') }}</th>
                <th>{{ $t('msg.VOSD100_M5.021') }}</th>
              </tr>
            </thead>
            <tbody>
              <!-- make a calendar -->
              <tr v-for="(date, idx) in dates" :key="idx">
                <td v-for="(day, secondIdx) in date" :key="secondIdx">
                  <span v-show="holidayList.length !== 0" v-for="(holiday,dayIdx) in holidayList" :key="dayIdx">
                    <div v-if="day == holiday.bascDt2 && holiday.hldNm === 'NOT'">{{ day }}</div>
                    <div v-else-if="day == holiday.bascDt2 && holiday.hldNm !== 'NOT'">
                      <font color="#d81d6c">{{ day }}</font>
                    </div>
                  </span>
                  <span v-show="holidayList.length === 0">{{ day }}</span>
                  <div v-if="bound === 'O'">
                    <div v-if="day < 10">
                      <div v-for="(schedule,schIdx) in listSchedule" :key="schIdx">
                        <div v-if="todayStr2 + '0' + day === schedule.etd && schedule.filterChkYN ==='Y'">
                          <div v-if="schedule.bkgClose === 'Y'">
                            <div v-show="showBkg === 'N'">
                              <p class="finish">
                                <a href="#" :class="urlOrNot ? 'action' : ''" @click.prevent="fnDetailLayer2(schedule)">{{ schedule.vslNm }}</a><!-- 부킹 종료 -->
                                &nbsp;<img v-show="kmtcPremiumUserYn === 'Y' && schedule.kmtcPremiumLineYn === 'Y'" src="../../assets/images/common/icon_premium.png" style="vertical-align: bottom;"><!-- kmtc premium -->
                              </p>
                            </div>
                          </div>
                          <div v-else-if="schedule.bkgClose =='A'">
                            <p class="possible">
                              <a href="#" :class="urlOrNot ? 'action' : ''" @click.prevent="fnDetailLayer2(schedule)">{{ schedule.vslNm }}</a><!-- 부킹 체크 -->
                              &nbsp;<img v-show="kmtcPremiumUserYn === 'Y' && schedule.kmtcPremiumLineYn === 'Y'" src="../../assets/images/common/icon_premium.png" style="vertical-align: bottom;"><!-- kmtc premium -->
                            </p>
                          </div>
                          <div v-else-if="schedule.bkgClose ==='M'">
                            <p class="after">
                              <a href="#" :class="urlOrNot ? 'action' : ''" @click.prevent="fnDetailLayer2(schedule)">{{ schedule.vslNm }}</a>
                              &nbsp;<img v-show="kmtcPremiumUserYn === 'Y' && schedule.kmtcPremiumLineYn === 'Y'" src="../../assets/images/common/icon_premium.png" style="vertical-align: bottom;"><!-- kmtc premium -->
                            </p>
                          </div>
                          <div v-else>
                            <p class="possible">
                              <a href="#" :class="urlOrNot ? 'action' : ''" @click.prevent="fnDetailLayer2(schedule)">{{ schedule.vslNm }}</a><!-- 부킹 가능 -->
                              &nbsp;<img v-show="kmtcPremiumUserYn === 'Y' && schedule.kmtcPremiumLineYn === 'Y'" src="../../assets/images/common/icon_premium.png" style="vertical-align: bottom;"><!-- kmtc premium -->
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div v-for="(schedule,schIdx) in listSchedule" :key="schIdx">
                        <div v-if="todayStr2 + day === schedule.etd && schedule.filterChkYN ==='Y'">
                          <div v-if="schedule.bkgClose ==='Y'">
                            <div v-show="showBkg === 'N'">
                              <p class="finish">
                                <a href="#" :class="urlOrNot ? 'action' : ''" @click.prevent="fnDetailLayer2(schedule)">{{ schedule.vslNm }}</a><!-- 부킹 종료 -->
                                &nbsp;<img v-show="kmtcPremiumUserYn === 'Y' && schedule.kmtcPremiumLineYn === 'Y'" src="../../assets/images/common/icon_premium.png" style="vertical-align: bottom;"><!-- kmtc premium -->
                              </p>
                            </div>
                          </div>
                          <div v-else-if="schedule.bkgClose =='A'">
                            <p class="possible">
                              <a href="#" :class="urlOrNot ? 'action' : ''" @click.prevent="fnDetailLayer2(schedule)">{{ schedule.vslNm }}</a><!-- 부킹 체크 -->
                              &nbsp;<img v-show="kmtcPremiumUserYn === 'Y' && schedule.kmtcPremiumLineYn === 'Y'" src="../../assets/images/common/icon_premium.png" style="vertical-align: bottom;"><!-- kmtc premium -->
                            </p>
                          </div>
                          <div v-else-if="schedule.bkgClose ==='M'">
                            <p class="after">
                              <a href="#" :class="urlOrNot ? 'action' : ''" @click.prevent="fnDetailLayer2(schedule)">{{ schedule.vslNm }}</a>
                              &nbsp;<img v-show="kmtcPremiumUserYn === 'Y' && schedule.kmtcPremiumLineYn === 'Y'" src="../../assets/images/common/icon_premium.png" style="vertical-align: bottom;"><!-- kmtc premium -->
                            </p>
                          </div>
                          <div v-else>
                            <p class="possible">
                              <a href="#" :class="urlOrNot ? 'action' : ''" @click.prevent="fnDetailLayer2(schedule)">{{ schedule.vslNm }}</a><!-- 부킹 가능 -->
                              &nbsp;<img v-show="kmtcPremiumUserYn === 'Y' && schedule.kmtcPremiumLineYn === 'Y'" src="../../assets/images/common/icon_premium.png" style="vertical-align: bottom;"><!-- kmtc premium -->
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="bound === 'I'">
                    <div v-if="day < 10">
                      <div v-for="(schedule,schIdx) in listSchedule" :key="schIdx">
                        <div v-if="todayStr2 + '0' + day === schedule.eta && schedule.filterChkYN ==='Y'">
                          <div v-if="schedule.bkgClose ==='Y'">
                            <p class="possible"><a href="#" :class="urlOrNot ? 'action' : ''" @click.prevent="fnDetailLayer2(schedule)">{{ schedule.vslNm }}</a></p>
                          </div>
                          <div v-else-if="schedule.bkgClose =='A'">
                            <p class="possible"><a href="#" :class="urlOrNot ? 'action' : ''" @click.prevent="fnDetailLayer2(schedule)">{{ schedule.vslNm }}</a></p>
                          </div>
                          <div v-else-if="schedule.bkgClose ==='M'">
                            <p class="possible"><a href="#" :class="urlOrNot ? 'action' : ''" @click.prevent="fnDetailLayer2(schedule)">{{ schedule.vslNm }}</a></p>
                          </div>
                          <div v-else>
                            <p class="possible"><a href="#" :class="urlOrNot ? 'action' : ''" @click.prevent="fnDetailLayer2(schedule)">{{ schedule.vslNm }}</a></p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div v-for="(schedule,schIdx) in listSchedule" :key="schIdx">
                        <div v-if="todayStr2 + day === schedule.eta && schedule.filterChkYN ==='Y'">
                          <div v-if="schedule.bkgClose ==='Y'">
                            <p class="possible"><a href="#" :class="urlOrNot ? 'action' : ''" @click.prevent="fnDetailLayer2(schedule)">{{ schedule.vslNm }}</a></p>
                          </div>
                          <div v-else-if="schedule.bkgClose =='A'">
                            <p class="possible"><a href="#" :class="urlOrNot ? 'action' : ''" @click.prevent="fnDetailLayer2(schedule)">{{ schedule.vslNm }}</a></p>
                          </div>
                          <div v-else-if="schedule.bkgClose ==='M'">
                            <p class="possible"><a href="#" :class="urlOrNot ? 'action' : ''" @click.prevent="fnDetailLayer2(schedule)">{{ schedule.vslNm }}</a></p>
                          </div>
                          <div v-else>
                            <p class="possible"><a href="#" :class="urlOrNot ? 'action' : ''" @click.prevent="fnDetailLayer2(schedule)">{{ schedule.vslNm }}</a></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <!-- make a calendar -->
            </tbody>
          </table>
        </div>
        <!-- 캘린더 타입 // -->
        <!-- 리스트 타입 -->
        <div class="sc_list_type div_detailLayer" v-show="calendarOrList === 'L'">
          <table class="tbl_col">
            <colgroup>
              <col style="width:7%">
              <col style="width:15%">
              <col style="width:auto">
              <col style="width:24%" v-show="bound === 'O'">
              <col style="width:34%" v-show="bound === 'I'">
              <col style="width:10%">
              <col style="width:12%" v-show="bound === 'O'">
              <col style="width:8%" v-show="bound === 'O'">
            </colgroup>
            <thead>
              <tr>
                <th scope="col">{{ $t('msg.CMBA100.00154') }}</th>
                <th scope="col">{{ $t('msg.VOSD100_M4.005') }}</th>
                <th scope="col">{{ $t('msg.VOSD100.002') }}</th>
                <th scope="col">{{ $t('msg.VOSD100.003') }}</th>
                <th scope="col">{{ $t('msg.CSBL100_M1.015') }}</th>
                <th scope="col" v-show="bound === 'O'">{{ $t('msg.VOSD100_M4.009') }}/{{ $t('msg.VOSD100_M4.010') }}</th>
                <th scope="col" v-show="bound === 'O'">Booking</th>
              </tr>
            </thead>
            <tbody v-for="(schedule,schIdx) in listSchedule4List" :key="schIdx">
              <!-- T/S Start -->
              <tr v-if="schedule.ts === 'Y' && schIdx >= startIdx && schIdx < endIdx">
                <td rowspan="10">T/S</td>
                <td class="text_left" rowspan="2">
                  <a v-if="schedule.vslNm1 !== 'Rail' && schedule.vslNm1 !== 'Truck'" class="td_underline" href="#none" @click.prevent="openPopup('VesselScheduleInfoPop', 1, schedule)">{{ schedule.vslNm1 }} {{ schedule.voyNo }}</a>
                  <a v-if="schedule.vslNm1 === 'Rail' || schedule.vslNm1 === 'Truck'">{{ schedule.vslNm1 }} {{ schedule.voyNo }}</a><br>
                  <a v-if="$ekmtcCommon.isNotEmpty(schedule.rteCd)" class="td_underline" href="#none" @click.prevent="openPopup('RoutePop', schedule.rteCd, schedule.vslNm1)">(Route : {{ schedule.rteCd }})</a>
                  &nbsp;<img v-show="kmtcPremiumUserYn === 'Y' && schedule.kmtcPremiumLineYn === 'Y'" src="../../assets/images/common/icon_premium.png" style="vertical-align: top;">
                </td>
                <td>
                  <a class="td_underline" href="#none" @click.prevent="openPopup('PortTerminalCYPop', schedule.pol1, schedule.polTrml1)">{{ schedule.polNm }}</a>
                </td>
                <td>
                  <a class="td_underline" href="#none" @click.prevent="openPopup('PortTerminalCYPop', schedule.pod1, schedule.podTrml1)">{{ schedule.pol2Nm }}(<span class="color_red">T/S</span>)</a>
                </td>
                <td rowspan="2">
                  <ul>
                    <li>{{ schedule.transitTime1 }}</li>
                  </ul>
                </td>
                <td :rowspan="schedule.tsDegree * 2 + 1" v-show="bound === 'O'">
                  <ul>
                    <li>{{ changeDateTime2Display(schedule.bkgDocCls, lang) }}</li>
                    <li>{{ changeDateTime2Display(schedule.bkgCgoCls, lang) }}</li>
                  </ul>
                </td>
                <td :rowspan="schedule.tsDegree * 2 + 1" class="color_red" v-show="bound === 'O'">
                  <div v-if="schedule.bkgClose === 'Y'">
                    {{ $t('msg.CSBL100_M1.017') }}<!-- 마감 -->
                  </div>
                  <div v-else-if="schedule.bkgClose !== 'M'">
                    <ul>
                      <li class="mt5"><a class="button sm blue" @click="bookingBooking4List(schedule)">Booking</a></li>
                    </ul>
                  </div>
                  <div v-else>
                    -
                  </div>
                </td>
              </tr>
              <tr v-if="schedule.ts === 'Y' && schIdx >= startIdx && schIdx < endIdx">
                <td class="bor-left">
                  <ul>
                    <li>{{ $t('msg.VOSD100_M2.003') }}<!-- 입항 --> : {{ changeDate2Display(schedule.polEtb, schedule.polEtbTm, lang) }}</li>
                    <li>{{ $t('msg.VOSD100_M2.004') }}<!-- 출항 --> : {{ changeDate2Display(schedule.etd,schedule.etdTm, lang) }}</li>
                    <li><a class="td_underline" href="#none" @click.prevent="openPopup('PortTerminalCYPop', schedule.pol1, schedule.polTrml1)">{{ fnTrmlText(schedule.otrmlCd, schedule.otrmlNm) }}</a></li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>{{ $t('msg.VOSD100_M2.003') }}<!-- 입항 --> : {{ changeDate2Display(schedule.podEtd1, schedule.etaTm1, lang) }}</li>
                    <li><a class="td_underline" href="#none" @click.prevent="openPopup('PortTerminalCYPop', schedule.pod1, schedule.podTrml1)">{{ fnTrmlText(schedule.podTrml1, schedule.podTrml1Nm) }}</a></li>
                  </ul>
                </td>
              </tr>
              <tr v-if="schedule.ts === 'Y' && schIdx >= startIdx && schIdx < endIdx">
                <td colspan="3" class="bor-left">{{ $t('msg.VOSD100_M5.069') }}</td><!-- T/S 대기 -->
                <td>{{ schedule.transitTSTime2 }}</td>
              </tr>
              <!-- 2nd line -->
              <tr v-if="schedule.ts === 'Y' && schIdx >= startIdx && schIdx < endIdx">
                <td class="text_left bor-left" rowspan="2">
                  <a v-if="schedule.vslNm2 !== 'Rail' && schedule.vslNm2 !== 'Truck'" class="td_underline" href="#none" @click.prevent="openPopup('VesselScheduleInfoPop', 2, schedule)">{{ schedule.vslNm2 }} {{ schedule.voyNo2 }}</a>
                  <a v-if="schedule.vslNm2 === 'Rail' || schedule.vslNm2 === 'Truck'">{{ schedule.vslNm2 }} {{ schedule.voyNo2 }}</a><br>
                  <a v-if="$ekmtcCommon.isNotEmpty(schedule.rteCd2)" class="td_underline" href="#none" @click.prevent="openPopup('RoutePop', schedule.rteCd2, schedule.vslNm2)">(Route : {{ schedule.rteCd2 }})</a>
                  &nbsp;<img v-show="kmtcPremiumUserYn === 'Y' && schedule.kmtcPremiumLineYn === 'Y'" src="../../assets/images/common/icon_premium.png" style="vertical-align: top;">
                  <div class="mt5" v-show="schedule.tsDegree === '2' && vessel2ndButton === 'Y'">
                    <a class="button sm" @click="change2ndVessel4List(schedule, schIdx)">{{ $t('msg.VOSD100_M5.027') }}<!-- 선박 변경 --><span class="btn_icon icoup"></span></a>
                  </div>
                </td>
                <td>
                  <a class="td_underline" href="#none" @click.prevent="openPopup('PortTerminalCYPop', schedule.pol2, schedule.polTrml2)">{{ schedule.pod1Nm }}(<span class="color_red">T/S</span>)</a>
                </td>
                <td>
                  <a class="td_underline" href="#none" @click.prevent="openPopup('PortTerminalCYPop', schedule.pod2, schedule.podTrml2)">{{ schedule.pod2Nm }}</a>
                </td>
                <td rowspan="2">
                  <ul>
                    <li>{{ schedule.transitTime2 }}</li>
                  </ul>
                </td>
              </tr>
              <tr v-if="schedule.ts === 'Y' && schIdx >= startIdx && schIdx < endIdx">
                <td class="bor-left">
                  <ul>
                    <li>{{ $t('msg.VOSD100_M2.004') }} : {{ changeDate2Display(schedule.polEtd2, schedule.etdTm2, lang) }}</li>
                    <li><a class="td_underline" href="#none" @click.prevent="openPopup('PortTerminalCYPop', schedule.pol2, schedule.polTrml2)">{{ fnTrmlText(schedule.polTrml2, schedule.polTrml2Nm) }}</a></li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>{{ $t('msg.VOSD100_M2.003') }} : {{ changeDate2Display(schedule.podEtd2, schedule.etaTm2, lang) }}</li>
                    <li><a class="td_underline" href="#none" @click.prevent="openPopup('PortTerminalCYPop', schedule.pod2, schedule.podTrml2)">{{ fnTrmlText(schedule.podTrml2, schedule.podTrml2Nm) }}</a></li>
                  </ul>
                </td>
              </tr>
              <!-- 3rd line -->
              <tr v-if="schedule.ts === 'Y' && schedule.tsDegree >= 3 && schIdx >= startIdx && schIdx < endIdx">
                <td class="text_left bor-left" rowspan="2">
                  {{ schedule.rteCd3 }}
                  <a v-if="schedule.vslNm3 !== 'Rail' && schedule.vslNm3 !== 'Truck'" class="td_underline" href="#none" @click.prevent="openPopup('VesselScheduleInfoPop', 3, schedule)">{{ schedule.vslNm3 }} {{ schedule.voyNo3 }}</a>
                  <a v-if="schedule.vslNm3 === 'Rail' || schedule.vslNm3 === 'Truck'">{{ schedule.vslNm3 }} {{ schedule.voyNo3 }}</a><br>
                  <a v-if="$ekmtcCommon.isNotEmpty(schedule.rteCd3)" class="td_underline" href="#none" @click.prevent="openPopup('RoutePop', schedule.rteCd3, schedule.vslNm3)">(Route : {{ schedule.rteCd3 }})</a>
                  &nbsp;<img v-show="kmtcPremiumUserYn === 'Y' && schedule.kmtcPremiumLineYn === 'Y'" src="../../assets/images/common/icon_premium.png" style="vertical-align: top;">
                </td>
                <td>
                  <a class="td_underline" href="#none" @click.prevent="openPopup('PortTerminalCYPop', schedule.pol3, schedule.polTrml3)">{{ schedule.pod2Nm }}</a>
                </td>
                <td>
                  <a class="td_underline" href="#none" @click.prevent="openPopup('PortTerminalCYPop', schedule.pod3, schedule.podTrml3)">{{ schedule.pod3Nm }}</a>
                </td>
                <td rowspan="2">
                  <ul>
                    <li>{{ schedule.transitTime3 }}</li>
                  </ul>
                </td>
              </tr>
              <tr v-if="schedule.ts === 'Y' && schedule.tsDegree >= 3 && schIdx >= startIdx && schIdx < endIdx">
                <td class="bor-left">
                  <ul>
                    <li>{{ $t('msg.VOSD100_M2.004') }} : {{ changeDate2Display(schedule.polEtd3, schedule.etdTm3, lang) }}</li>
                    <li><a class="td_underline" href="#none" @click.prevent="openPopup('PortTerminalCYPop', schedule.pol3, schedule.polTrml3)">{{ fnTrmlText(schedule.polTrml3, schedule.polTrml3Nm) }}</a></li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>{{ $t('msg.VOSD100_M2.003') }} : {{ changeDate2Display(schedule.podEtd3, schedule.etaTm3, lang) }}</li>
                    <li><a class="td_underline" href="#none" @click.prevent="openPopup('PortTerminalCYPop', schedule.pod3, schedule.podTrml3)">{{ fnTrmlText(schedule.podTrml3, schedule.podTrml3Nm) }}</a></li>
                  </ul>
                </td>
              </tr>
              <!-- 4th line -->
              <tr v-if="schedule.ts === 'Y' && schedule.tsDegree >= 4 && schIdx >= startIdx && schIdx < endIdx">
                <td class="text_left bor-left" rowspan="2">
                  <a v-if="schedule.vslNm4 !== 'Rail' && schedule.vslNm4 !== 'Truck'" class="td_underline" href="#none" @click.prevent="openPopup('VesselScheduleInfoPop', 4, schedule)">{{ schedule.vslNm4 }} {{ schedule.voyNo4 }}</a>
                  <a v-if="schedule.vslNm4 === 'Rail' || schedule.vslNm4 === 'Truck'">{{ schedule.vslNm4 }} {{ schedule.voyNo4 }}</a><br>
                  <a v-if="$ekmtcCommon.isNotEmpty(schedule.rteCd4)" class="td_underline" href="#none" @click.prevent="openPopup('RoutePop', schedule.rteCd4, schedule.vslNm4)">(Route : {{ schedule.rteCd4 }})</a>
                  &nbsp;<img v-show="kmtcPremiumUserYn === 'Y' && schedule.kmtcPremiumLineYn === 'Y'" src="../../assets/images/common/icon_premium.png" style="vertical-align: top;">
                </td>
                <td>
                  <a class="td_underline" href="#none" @click.prevent="openPopup('PortTerminalCYPop', schedule.pol4, schedule.polTrml4)">{{ schedule.pod3Nm }}</a>
                </td>
                <td>
                  <a class="td_underline" href="#none" @click.prevent="openPopup('PortTerminalCYPop', schedule.pod4, schedule.podTrml4)">{{ schedule.pod4Nm }}</a>
                </td>
                <td rowspan="2">
                  <ul>
                    <li>{{ schedule.transitTime4 }}</li>
                  </ul>
                </td>
              </tr>
              <tr v-if="schedule.ts === 'Y' && schedule.tsDegree >= 4 && schIdx >= startIdx && schIdx < endIdx">
                <td class="bor-left">
                  <ul>
                    <li>{{ $t('msg.VOSD100_M2.004') }} : {{ changeDate2Display(schedule.polEtd4, schedule.etdTm4, lang) }}</li>
                    <li><a class="td_underline" href="#none" @click.prevent="openPopup('PortTerminalCYPop', schedule.pol4, schedule.polTrml4)">{{ fnTrmlText(schedule.polTrml4, schedule.polTrml4Nm) }}</a></li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>{{ $t('msg.VOSD100_M2.003') }} : {{ changeDate2Display(schedule.podEtd4, schedule.etaTm4, lang) }}</li>
                    <li><a class="td_underline" href="#none" @click.prevent="openPopup('PortTerminalCYPop', schedule.pod4, schedule.podTrml4)">{{ fnTrmlText(schedule.podTrml4, schedule.podTrml4Nm) }}</a></li>
                  </ul>
                </td>
              </tr>
              <!-- T/S Finish -->
              <!-- 2nd Vessel Start -->
              <tr :id="'vessel2nd' + schIdx" v-show="show2ndVessel === 'Y' && calendarOrList === 'L' && schIdx === listIndexNo">
                <td class="bor-left pd0" colspan="6">
                  <div class="inner_table">
                    <div class="port_info">
                      <p class="port t2">
                        <span>{{ schedule.polNm.split(',')[0] }}</span>
                        <span>{{ schedule.podNm.split(',')[0] }}</span>
                      </p>
                      <p class="txt_desc none">{{ $t('msg.VOSD100_M5.028') }}<!-- 변경을 원하는 선박을 선택해주세요. --></p>
                      <div class="btn_port_cancel">
                        <a class="button sm gray" @click="cancel2ndVessel4List(schIdx)">{{ $t('msg.VOSD100_M5.029') }}<!-- 선박 변경 취소 --></a>
                      </div>
                    </div>
                    <table class="tbl_col t2 tac mt10">
                      <colgroup>
                        <col width="24%">
                        <col width="24%">
                        <col width="24%">
                        <col width="18%">
                        <col width="10%">
                      </colgroup>
                      <thead>
                        <tr>
                          <th>{{ $t('menu.MENU09.060') }}</th>
                          <th>ETD</th>
                          <th>ETA</th>
                          <th>{{ $t('msg.CSBL100_M1.015') }}</th>
                          <th>{{ $t('msg.CSBL200.075') }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(list,idx) in vessel2ndList" :key="idx">
                          <td>{{ list.vslNm }} {{ list.voyNo }}</td>
                          <td>{{ list.etdStrDT }}</td>
                          <td>{{ list.etaStrDT }}</td>
                          <td>{{ list.transitTime }}</td>
                          <td><a class="button gray sm" @click="select2ndVessel4List(list, schIdx)">{{ $t('msg.CSBL200.075') }}</a></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
              <!-- 2nd Vessel Finish -->
              <tr v-if="schedule.ts === 'Y' && schIdx >= startIdx && schIdx < endIdx">
                <td class="bor-left pd0" colspan="6">
                  <P class="mt5 text_right">{{ $t('msg.VOSD100_M5.110') }} : {{ schedule.transitTime }}</P><!-- 총 소요시간 -->
                </td>
              </tr>
              <!-- Direct Start -->
              <tr v-if="schedule.ts !== 'Y' && schIdx >= startIdx && schIdx < endIdx">
                <td rowspan="2">Direct</td>
                <td rowspan="2" class="text_left">
                  <a v-if="schedule.vslNm !== 'Rail' && schedule.vslNm !== 'Truck'" class="td_underline" href="#none" @click.prevent="openPopup('VesselScheduleInfoPop', 1, schedule)">{{ schedule.vslNm }} {{ schedule.voyNo }}</a>
                  <a v-if="schedule.vslNm === 'Rail' || schedule.vslNm === 'Truck'">{{ schedule.vslNm }} {{ schedule.voyNo }}</a><br>
                  <a v-if="$ekmtcCommon.isNotEmpty(schedule.rteCd)" class="td_underline" href="#none" @click.prevent="openPopup('RoutePop', schedule.rteCd, schedule.vslNm)">(Route : {{ schedule.rteCd }})</a>
                  <img v-show="kmtcPremiumUserYn === 'Y' && schedule.kmtcPremiumLineYn === 'Y'" src="../../assets/images/common/icon_premium.png" style="vertical-align: top;">
                </td>
                <td>
                  <a class="td_underline" href="#none" @click.prevent="openPopup('PortTerminalCYPop', schedule.pol, schedule.polTml)">{{ schedule.polNm }}</a>
                </td>
                <td>
                  <a class="td_underline" href="#none" @click.prevent="openPopup('PortTerminalCYPop', schedule.pod, schedule.podTml)">{{ schedule.podNm }}</a>
                </td>
                <td rowspan="2">
                  <ul>
                    <li>{{ schedule.transitTime }}</li>
                  </ul>
                </td>
                <td rowspan="2" v-show="bound === 'O'">
                  <ul>
                    <li>{{ changeDateTime2Display(schedule.bkgDocCls, lang) }}</li>
                    <li>{{ changeDateTime2Display(schedule.bkgCgoCls, lang) }}</li>
                  </ul>
                </td>
                <td rowspan="2" class="color_red" v-show="bound === 'O'">
                  <div v-if="schedule.bkgClose === 'Y'">
                    {{ $t('msg.CSBL100_M1.017') }}<!-- 마감 -->
                  </div>
                  <div v-else-if="schedule.bkgClose !== 'M'">
                    <ul>
                      <li class="mt5"><a class="button sm blue" @click="bookingBooking4List(schedule)">Booking</a></li>
                    </ul>
                  </div>
                  <div v-else>
                    -
                  </div>
                </td>
              </tr>
              <tr v-if="schedule.ts !== 'Y' && schIdx >= startIdx && schIdx < endIdx">
                <td class="bor-left">
                  <ul>
                    <li>{{ $t('msg.VOSD100_M2.003') }} : {{ changeDate2Display(schedule.polEtb, schedule.polEtbTm, lang) }}</li>
                    <li>{{ $t('msg.VOSD100_M2.004') }} : {{ changeDate2Display(schedule.etd, schedule.etdTm, lang) }}</li>
                    <li><a class="td_underline" href="#none" @click.prevent="openPopup('PortTerminalCYPop', schedule.pol, schedule.polTml)">{{ schedule.otrmlCd }} ({{ schedule.otrmlNm }})</a></li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>{{ $t('msg.VOSD100_M2.003') }} : {{ changeDate2Display(schedule.eta, schedule.etaTm, lang) }}</li>
                    <li><a class="td_underline" href="#none" @click.prevent="openPopup('PortTerminalCYPop', schedule.pod, schedule.podTml)">{{ schedule.itrmlCd }} ({{ schedule.itrmlNm }})</a></li>
                  </ul>
                </td>
              </tr>
              <!-- Direct Finish -->
            </tbody>
          </table>
          <!-- paging -->
          <div
            v-if="pagingList.length > 0"
            class="paging"
          >
            <a
              class="btn prev"
              @click.prevent="getList4Schedule(curPage-1)"
            >
              Prev
            </a>
            <a
              v-for="idx in pagingList"
              :key="idx"
              :class="[idx === curPage ? 'on' : '']"
              href="#"
              @click.prevent="getList4Schedule(idx)"
            >
              {{ idx }}
            </a>
            <a
              class="btn next"
              @click.prevent="getList4Schedule(curPage+1)"
            >
              Next
            </a>
          </div>
          <!-- paging // -->
        </div><!-- 리스트 타입 // -->
      </div><!-- wrap_schedule // -->
    </div><!-- content_box // -->
    <div class="content_box mt10 div_detailLayer" v-show="detailLayer2 === 'Y' && calendarOrList === 'C'">
      <!-- content_box  -->
      <table class="tbl_col mt10">
        <colgroup>
          <col width="22%">
          <col width="34%">
          <col width="34%">
          <col width="10%">
        </colgroup>
        <thead>
          <tr>
            <th>{{ $t('msg.VOSD100_M4.005') }}</th>
            <th>{{ $t('msg.VOSD100.002') }}</th>
            <th>{{ $t('msg.VOSD100.003') }}</th>
            <th>{{ $t('msg.VOSD100_M5.030') }}<!-- 소요시간 --></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text_left" rowspan="2">
              <a v-if="detailResp2.vslNm1 !== 'Rail' && detailResp2.vslNm1 !== 'Truck'" :class="urlOrNot ? 'td_underline dsm_info' : 'td_underline'" @click.prevent="openPopup('VesselScheduleInfoPop', 1)">{{ detailResp2.vslNm1 }} {{ detailResp2.voyNo }}</a>
              <a v-if="detailResp2.vslNm1 === 'Rail' || detailResp2.vslNm1 === 'Truck'" :class="urlOrNot ? 'dsm_info' : ''">{{ detailResp2.vslNm1 }} {{ detailResp2.voyNo }}</a><br>
              <a v-if="$ekmtcCommon.isNotEmpty(detailResp2.rteCd)" :class="urlOrNot ? 'td_underline dsm_info' : 'td_underline'" @click.prevent="openPopup('RoutePop', detailResp2.rteCd, detailResp2.vslNm1)">(ROUTE : {{ detailResp2.rteCd }})</a><br>
              <a v-if="vslDelayReasonBtnFlag" class="button sm" @click.prevent="openPopup('ChangeNoticePop')">{{ $t('msg.SCHD010P040.017') }}</a><!-- 지연사유서 PDF 다운로드 -->
            </td>
            <td class="text_left" v-show="detailResp2.ts !== 'Y'"><a href="#" class="td_underline" @click.prevent="openPopup('PortTerminalCYPop', detailResp2.pol, detailResp2.polTmlCd)">{{ detailResp2.polNm }}</a></td>
            <td class="text_left" v-show="detailResp2.ts !== 'Y'"><a href="#" class="td_underline" @click.prevent="openPopup('PortTerminalCYPop', detailResp2.pod, detailResp2.podTmlCd)">{{ detailResp2.pod1Nm }}</a></td>
            <td class="text_left" v-show="detailResp2.ts === 'Y'"><a href="#" class="td_underline" @click.prevent="openPopup('PortTerminalCYPop', detailResp2.pol1, detailResp2.polTmlCd)">{{ detailResp2.polNm }}</a></td>
            <td class="text_left" v-show="detailResp2.ts === 'Y'"><a href="#" class="td_underline" @click.prevent="openPopup('PortTerminalCYPop', detailResp2.pod1, detailResp2.podTmlCd)">{{ detailResp2.pod1Nm }}(<span class="color_red">T/S</span>)</a></td>
            <td v-show="detailResp2.ts !== 'Y'" rowspan="2">{{ detailResp2.transitTime }}</td>
            <td v-show="detailResp2.ts === 'Y'" rowspan="2">{{ detailResp2.transitTime1 }}</td>
          </tr>
          <tr>
            <td class="text_left border_left">
              <p>{{ $t('msg.VOSD100_M4.007') }}<span class="txt_bar"></span> {{ detailResp2.polEtbDT }}</p>
              <p>{{ $t('msg.VOSD100_M4.008') }}<span class="txt_bar"></span> {{ detailResp2.etdDT }}</p>
              <p v-show="detailResp2.ts !== 'Y'"><a href="#" class="td_underline" @click.prevent="openPopup('PortTerminalCYPop', detailResp2.pol, detailResp2.polTmlCd)">{{ detailResp2.polTml }}</a></p>
              <p v-show="detailResp2.ts === 'Y'"><a href="#" class="td_underline" @click.prevent="openPopup('PortTerminalCYPop', detailResp2.pol1, detailResp2.polTmlCd)">{{ detailResp2.polTml }}</a></p>
            </td>
            <td class="text_left">
              <p>{{ $t('msg.VOSD100_M4.007') }}<span class="txt_bar"></span> {{ detailResp2.etaDT }}</p>
              <p v-show="detailResp2.ts !== 'Y'"><a href="#" class="td_underline" @click.prevent="openPopup('PortTerminalCYPop', detailResp2.pod, detailResp2.podTmlCd)">{{ detailResp2.podTml }}</a></p>
              <p v-show="detailResp2.ts === 'Y'"><a href="#" class="td_underline" @click.prevent="openPopup('PortTerminalCYPop', detailResp2.pod1, detailResp2.podTmlCd)">{{ detailResp2.podTml }}</a></p>
            </td>
          </tr>
          <tr v-show="detailResp2.ts === 'Y'">
            <td colspan="3">{{ $t('msg.VOSD100_M5.069') }}</td><!-- T/S 대기 -->
            <td>{{ detailResp2.transitTSTime2 }}</td>
          </tr>
          <tr v-show="detailResp2.ts === 'Y'">
            <td class="text_left" rowspan="2">
              <a v-if="detailResp2.vslNm2 !== 'Rail' && detailResp2.vslNm2 !== 'Truck'" class="td_underline" @click.prevent="openPopup('VesselScheduleInfoPop', 2)">{{ detailResp2.vslNm2 }} {{ detailResp2.voyNo2 }}</a>
              <a v-if="detailResp2.vslNm2 === 'Rail' || detailResp2.vslNm2 === 'Truck'">{{ detailResp2.vslNm2 }} {{ detailResp2.voyNo2 }}</a><br>
              <a v-if="$ekmtcCommon.isNotEmpty(detailResp2.rteCd2)" class="td_underline" @click.prevent="openPopup('RoutePop', detailResp2.rteCd2, detailResp2.vslNm2)">(ROUTE : {{ detailResp2.rteCd2 }})</a>
              <p class="mt10" v-show="detailResp2.tsDegree === '2' && vessel2ndButton === 'Y'">
                <a class="button sm" @click="change2ndVessel(scheduleDetailData)">{{ $t('msg.VOSD100_M5.027') }}<span class="btn_icon icoup"></span></a>
              </p>
            </td>
            <td class="text_left"><a class="td_underline" href="#none" @click.prevent="openPopup('PortTerminalCYPop', detailResp2.pol2, detailResp2.polTmlCd2)">{{ detailResp2.pod1Nm }}(<span class="color_red">T/S</span>)</a></td>
            <td class="text_left"><a class="td_underline" href="#none" @click.prevent="openPopup('PortTerminalCYPop', detailResp2.pod2, detailResp2.podTmlCd2)">{{ detailResp2.pod2Nm }}</a></td>
            <td rowspan="2">{{ detailResp2.transitTime2 }}</td>
          </tr>
          <tr v-show="detailResp2.ts === 'Y'">
            <td class="text_left border_left">
              <p>{{ $t('msg.VOSD100_M4.008') }}<span class="txt_bar"></span> {{ detailResp2.polEtbDT2 }} </p>
              <p><a class="td_underline" href="#none" @click.prevent="openPopup('PortTerminalCYPop', detailResp2.pol2, detailResp2.polTmlCd2)">{{ detailResp2.polTml2 }}</a></p>
            </td>
            <td class="text_left">
              <p>{{ $t('msg.VOSD100_M4.007') }}<span class="txt_bar"></span> {{ detailResp2.etaDT2 }}</p>
              <p><a class="td_underline" href="#none" @click.prevent="openPopup('PortTerminalCYPop', detailResp2.pod2, detailResp2.podTmlCd2)">{{ detailResp2.podTml2 }}</a></p>
            </td>
          </tr>
          <!-- 3rd -->
          <tr v-show="detailResp2.ts === 'Y' && detailResp2.tsDegree >= 3">
            <td colspan="3">{{ $t('msg.VOSD100_M5.069') }}</td><!-- T/S 대기 -->
            <td>{{ detailResp2.transitTSTime3 }}</td>
          </tr>
          <tr v-show="detailResp2.ts === 'Y' && detailResp2.tsDegree >= 3">
            <td class="text_left" rowspan="2">
              <a v-if="detailResp2.vslNm3 !== 'Rail' && detailResp2.vslNm3 !== 'Truck'" class="td_underline" @click.prevent="openPopup('VesselScheduleInfoPop', 3)">{{ detailResp2.vslNm3 }} {{ detailResp2.voyNo3 }}</a>
              <a v-if="detailResp2.vslNm3 === 'Rail' || detailResp2.vslNm3 === 'Truck'">{{ detailResp2.vslNm3 }} {{ detailResp2.voyNo3 }}</a><br>
              <a v-if="$ekmtcCommon.isNotEmpty(detailResp2.rteCd3)" class="td_underline" href="#none" @click.prevent="openPopup('RoutePop', detailResp2.rteCd3, detailResp2.vslNm3)">(ROUTE : {{ detailResp2.rteCd3 }})</a>
            </td>
            <td class="text_left"><a class="td_underline" href="#none">{{ detailResp2.pod2Nm }}</a></td>
            <td class="text_left"><a class="td_underline" href="#none">{{ detailResp2.pod3Nm }}</a></td>
            <td rowspan="2">{{ detailResp2.transitTime3 }}</td>
          </tr>
          <tr v-show="detailResp2.ts === 'Y' && detailResp2.tsDegree >= 3">
            <td class="text_left border_left">
              <p>{{ $t('msg.VOSD100_M4.008') }}<span class="txt_bar"></span> {{ detailResp2.polEtbDT3 }} </p>
              <p><a class="td_underline" href="#none">{{ detailResp2.polTml3 }}</a></p>
            </td>
            <td class="text_left">
              <p>{{ $t('msg.VOSD100_M4.007') }}<span class="txt_bar"></span> {{ detailResp2.etaDT3 }}</p>
              <p><a class="td_underline" href="#none">{{ detailResp2.podTml3 }}</a></p>
            </td>
          </tr>
          <!-- 4th -->
          <tr v-show="detailResp2.ts === 'Y' && detailResp2.tsDegree >= 4">
            <td colspan="3">{{ $t('msg.VOSD100_M5.069') }}</td><!-- T/S 대기 -->
            <td>{{ detailResp2.transitTSTime4 }}</td>
          </tr>
          <tr v-show="detailResp2.ts === 'Y' && detailResp2.tsDegree >= 4">
            <td class="text_left" rowspan="2">
              <a v-if="detailResp2.vslNm4 !== 'Rail' && detailResp2.vslNm4 !== 'Truck'" class="td_underline" href="#none" @click.prevent="openPopup('VesselScheduleInfoPop', 4)">{{ detailResp2.vslNm4 }} {{ detailResp2.voyNo4 }}</a>
              <a v-if="detailResp2.vslNm4 === 'Rail' || detailResp2.vslNm4 === 'Truck'">{{ detailResp2.vslNm4 }} {{ detailResp2.voyNo4 }}</a><br>
              <a v-if="$ekmtcCommon.isNotEmpty(detailResp2.rteCd4)" class="td_underline" href="#none" @click.prevent="openPopup('RoutePop', detailResp2.rteCd4, detailResp2.vslNm4)">(ROUTE : {{ detailResp2.rteCd4 }})</a>
            </td>
            <td class="text_left"><a class="td_underline" href="#none">{{ detailResp2.pod3Nm }}</a></td>
            <td class="text_left"><a class="td_underline" href="#none">{{ detailResp2.pod4Nm }}</a></td>
            <td rowspan="2">{{ detailResp2.transitTime4 }}</td>
          </tr>
          <tr v-show="detailResp2.ts === 'Y' && detailResp2.tsDegree >= 4">
            <td class="text_left border_left">
              <p>{{ $t('msg.VOSD100_M4.008') }}<span class="txt_bar"></span> {{ detailResp2.polEtbDT4 }} </p>
              <p><a class="td_underline" href="#none">{{ detailResp2.polTml4 }}</a></p>
            </td>
            <td class="text_left">
              <p>{{ $t('msg.VOSD100_M4.007') }}<span class="txt_bar"></span> {{ detailResp2.etaDT4 }}</p>
              <p><a class="td_underline" href="#none">{{ detailResp2.podTml4 }}</a></p>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="gr_tbl_box" v-show="show2ndVessel === 'Y' && calendarOrList === 'C'">
        <!-- 선박변경 클릭시 나타탐 -->
        <div>
          <p class="port">
            <span>{{ detailResp2.pod1Nm.split(',')[0] }}</span>
            <span>{{ detailResp2.pod2Nm.split(',')[0] }}</span>
          </p>
          <p class="txt">{{ $t('msg.VOSD100_M5.028') }}<!-- 변경을 원하는 선박을 선택해주세요. --></p>
          <div class="btn_port_cancel">
            <a class="sm gray button" @click="cancel2ndVessel()">{{ $t('msg.VOSD100_M5.029') }}<!-- 선박 변경 취소 --></a>
          </div>
        </div>
        <table class="tbl_col t2 tac mt10">
          <colgroup>
            <col width="24%">
            <col width="24%">
            <col width="24%">
            <col width="18%">
            <col width="10%">
          </colgroup>
          <thead>
            <tr>
              <th>{{ $t('menu.MENU09.060') }}</th>
              <th>ETD</th>
              <th>ETA</th>
              <th>{{ $t('msg.CSBL100_M1.015') }}</th>
              <th>{{ $t('msg.CSBL200.075') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(list,idx) in vessel2ndList" :key="idx">
              <td>{{ list.vslNm }} {{ list.voyNo }}</td>
              <td>{{ list.etdStrDT }}</td>
              <td>{{ list.etaStrDT }}</td>
              <td>{{ list.transitTime }}</td>
              <td><a class="button gray sm" @click="select2ndVessel(list)">{{ $t('msg.CSBL200.075') }}</a></td>
            </tr>
          </tbody>
        </table>
      </div><!--  선박변경 클릭시 나타남 // -->
      <P class="mt5 text_right">{{ $t('msg.VOSD100_M5.110') }} : {{ detailResp2.transitTime }}</P><!-- 총 소요시간 -->
      <!-- <table class="tbl_row">
        <colgroup>
          <col width="130"><col><col width="150"><col>
        </colgroup>
        <tbody>
          <tr v-if="bound === 'O'">
            <th>{{ $t('msg.VOSD100_M4.009') }}</th>
            <td>{{ detailResp2.bkgDocCls }}</td>
            <th>CY Open</th>
            <td>{{ detailResp2.closInfVar }}</td>
          </tr>
          <tr v-if="bound === 'O'">
            <th>VGM {{ $t('msg.CSBL100_M1.017') }}</th>
            <td>{{ detailResp2.bkgDocCls }}</td>
            <th>Container {{ $t('msg.VOSD100_M4.010') }}</th>
            <td>{{ detailResp2.bkgCgoCls }}</td>
          </tr>
          <tr v-if="bound === 'O'">
            <th>{{ $t('msg.CSBL100_M1.012') }}</th>
            <td>{{ detailResp2.bkgMfCls }}</td>
            <th>CFS {{ $t('msg.VOSD100_M4.010') }}</th>
            <td>{{ detailResp2.cfsCls }}</td>
          </tr>
          <tr v-if="bound === 'O'">
            <th>MRN NO.</th>
            <td>{{ detailResp2.mrnNo }}</td>
            <th>CALL SIGN</th>
            <td>{{ detailResp2.callSign }}</td>
          </tr>
          <tr v-if="bound === 'I'">
            <th>{{ $t('msg.VOSD100_M2.003') }} {{ $t('msg.MAIN100.006') }} MRN NO.</th>
            <td>{{ detailResp2.mrnNo }}</td>
            <th>{{ $t('msg.VOSD100_M2.003') }} {{ $t('msg.MAIN100.006') }} CALL SIGN</th>
            <td>{{ detailResp2.callSign }}</td>
          </tr>
          <tr v-if="bound === 'O'">
            <th>{{ $t('btn.CMBTK044') }}</th>
            <td>{{ detailResp2.jrstCstm }}</td>
            <th>{{ $t('msg.VOSD100_M4.008') }} {{ $t('msg.VOSD100_M3.006') }}</th>
            <td>{{ detailResp2.polTml }}</td>
          </tr>
        </tbody>
      </table> -->
      <!-- component start -->
      <template v-if="bound === 'O'">
        <close-info-sch
          ref="CloseInfo"
          :parent-info="closeInfoSch"
        />
      </template>
      <template v-if="bound === 'I'">
        <div>
          <table class="tbl_row mt10">
            <colgroup>
              <col width="150"><col>
              <col width="150"><col>
              <col width="150"><col>
            </colgroup>
            <tbody>
              <tr>
                <th>{{ $t('msg.VOSD100_M2.003') }} {{ $t('msg.MAIN100.006') }} MRN NO.</th>
                <td>{{ detailResp2.mrnNo }}</td>
                <th>{{ $t('msg.VOSD100_M2.003') }} {{ $t('msg.MAIN100.006') }} {{ $t('msg.CSCT060G030.035') }}</th>
                <td>{{ detailResp2.apoTcnt }}</td>
                <th>{{ $t('msg.VOSD100_M2.003') }} {{ $t('msg.MAIN100.006') }} CALL SIGN</th>
                <td>{{ detailResp2.callSign }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
      <!-- component finish -->
    </div>
    <!-- content_box // -->
    <!-- 마감이 되지 않은 구간 -->
    <div class="flex_box mt10" v-show="afterClickDetail === 'Y'">
      <div v-if="parentInfo.cancelRequestPop !== 'Y' && bound === 'O'" class="ml_auto">
        <a v-show="frtResult === 'Y' || (frtResult === 'A' && rmPopOpenFlag)" :class="urlOrNot ? 'button blue lg mr5 action' : 'button blue lg mr5'" @click="openFreSurchargePopup">{{ $t('menu.MENU05.000') }}{{ $t('btn.CMBTK013') }}</a><!-- 운임확인(To-be) / 견적운임보기(As-is) -->
        <a v-show="frtResult === 'N' || (frtResult === 'A' && !rmPopOpenFlag)" class="button blue lg mr5" @click="openRequestQuotePopup">{{ $t('menu.MENU05.010') }}</a><!-- 운임문의(To-be) / 견적운임문의(As-is) -->
        <a :class="urlOrNot ? 'button blue lg action' : 'button blue lg'" @click="beforeGoBooking(detailResp2)">{{ $t('msg.CSBL100_M1.016') }}</a><!-- Booking -->
      </div>
      <div v-if="parentInfo.cancelRequestPop !== 'Y' && bound === 'I'" class="ml_auto">
        <a v-show="frtResult === 'Y' || (frtResult === 'A' && rmPopOpenFlag)" :class="urlOrNot ? 'button blue lg mr5 action' : 'button blue lg mr5'" @click="openFreSurchargePopup">{{ $t('menu.MENU05.000') }}{{ $t('btn.CMBTK013') }}</a><!-- 운임확인(To-be) / 견적운임보기(As-is) -->
        <a v-show="frtResult === 'N' || (frtResult === 'A' && !rmPopOpenFlag)" class="button blue lg mr5" @click="openRequestQuotePopup">{{ $t('menu.MENU05.010') }}</a><!-- 운임문의(To-be) / 견적운임문의(As-is) -->
      </div>
      <div v-if="parentInfo.cancelRequestPop === 'Y'" class="ml_auto">
        <a :class="urlOrNot ? 'button blue lg action' : 'button blue lg'" @click="beforeGoBooking(detailResp2)">{{ $t('msg.CSBK100.062') }}</a><!-- Booking -->
      </div>
    </div>
    <!-- 마감이 된 구간 중 KMTC ON 구간 설정에서 미마감으로 설정된 구간 -->
    <div class="flex_box mt10" v-show="afterClickDetail !== 'Y' && detailLayer2 === 'Y' && kmtcPremiumClosYn === 'N' && kmtcPremiumUserYn === 'Y' && kmtcPremiumLineYn === 'Y'">
      <div v-if="parentInfo.cancelRequestPop !== 'Y' && bound === 'O'" class="ml_auto">
        <a v-show="frtResult === 'Y' || (frtResult === 'A' && rmPopOpenFlag)" :class="urlOrNot ? 'button blue lg mr5 action' : 'button blue lg mr5'" @click="openFreSurchargePopup">{{ $t('menu.MENU05.000') }}{{ $t('btn.CMBTK013') }}</a><!-- 운임확인(To-be) / 견적운임보기(As-is) -->
        <a v-show="frtResult === 'N' || (frtResult === 'A' && !rmPopOpenFlag)" class="button blue lg mr5" @click="openRequestQuotePopup">{{ $t('menu.MENU05.010') }}</a><!-- 운임문의(To-be) / 견적운임문의(As-is) -->
        <a :class="urlOrNot ? 'button blue lg action' : 'button blue lg'" @click="beforeGoBooking(detailResp2)">{{ $t('msg.CSBL100_M1.016') }}</a><!-- Booking -->
      </div>
    </div>
    <!-- KMTC ON PREMIUM 이미지 표시 -->
    <div class="flex_box mt10" v-show="detailLayer2 === 'Y' && kmtcPremiumClosYn === 'N' && kmtcPremiumUserYn === 'Y' && kmtcPremiumLineYn === 'Y'">
      <span style="color: #075bb9; font-weight: 500; font-size: 15px; margin-left: auto !important; margin-top:-10px;">PREMIUM 부킹 가능&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
    </div>
  </div><!-- content // -->
</template>
<script>
import { rootComputed } from '@/store/helpers'
import schedules from '@/api/rest/schedule/schedule'
import commons from '@/api/services/commons'
import cp from '@/api/rest/cp/cp'
import mySchedules from '@/api/rest/schedule/mySchedule'
import vslScheduleInfo from '@/api/rest/schedule/vslScheduleInfo'
import EAutoCompletePlace from '@/components/common/EAutoCompletePlace'
import EAutoCompleteCommodity from '@/components/common/EAutoCompleteCommodity'
import UnnoPop from '@/pages/trans/popup/UnnoPop'/* 스케줄 Unno 검색 팝업 */
import RoutePop from '@/pages/schedule/popup/RoutePop'/* Route 팝업 */
import EmailSchedulePop from '@/pages/schedule/popup/EmailSchedulePop'/* 스케줄 구독 팝업 */
import VesselScheduleInfoPop from '@/pages/schedule/popup/VesselScheduleInfoPop'/* 선박정보 및 스케줄 팝업 */
import FreSurchargePop from '@/pages/schedule/popup/FreSurchargePop'/* 운임확인(1) 팝업 */
import FreSurchargePop2 from '@/pages/schedule/popup/FreSurchargePop2'/* 운임확인(2) 팝업 */
import RequestQuotePop from '@/pages/schedule/popup/RequestQuotePop'/* 운임문의 팝업 */
import PortTerminalCYPop from '@/pages/schedule/popup/PortTerminalCYPop'/* PortTerminalCYPop 팝업 */
import intergis from '@/api/rest/common/intergis'

export default {
  name: 'ScheduleLeg',
  computed: {
    ...rootComputed
  },
  watch: {
    yyyymmLeg () {
      this.searchYear = this.yyyymmLeg.substring(0, 4)
      this.searchMonth = this.yyyymmLeg.substring(4, 6)
    },
    '$store.state.schedule.suggestionSchedule' () {
      if (this.$store.state.schedule.suggestionSchedule !== null) {
        this.setVuexSearchParams()
      }
    }
  },
  mounted: function () {
    console.log('@@@@ mount scheduleLeg')
     if (this.$store.state.schedule.suggestionSchedule !== null) {
       this.setVuexSearchParams()
     }

     this.$nextTick(function () {
      window.addEventListener('keydown', this.handleKeydown)
     })
  },
  destroyed () {
    window.removeEventListener('keydown', this.handleKeydown)
  },
  created () {
    // [s] router.query 작업
    // Schedule 화면 이동시
    // const param = {
    //     porCtrCd: 'KR',
    //     porPlcCd: 'INC',
    //     dlyCtrCd: 'JP',
    //     dlyPlcCd: 'TYO',
    //     yyyymm: '202202'
    //   }
    // this.$router.push({ name: 'leg', query: param }).catch(() => {})
    // Schedule 화면 이동시
    this._ = require('lodash')
    if (Object.keys(this.$route.query).length > 0) {
      const vo = this.$route.query
      if (vo !== null && vo !== undefined) {
        // JamSin DSM url 로 연결된 선호항로, 선복보장 인 경우
        if (vo.dsmType === '015' || vo.dsmType === '018') {
          this.urlOrNot = true
          this.urlParam = vo
        } else { // 메인 스케줄 검색으로 오는 경우
          this.parentInfo.polCtrCd = vo.porCtrCd
          this.parentInfo.polPlcCd = vo.porPlcCd
          this.parentInfo.podCtrCd = vo.dlyCtrCd
          this.parentInfo.podPlcCd = vo.dlyPlcCd
          this.parentInfo.ScheduleLegPop = 'D'
          this.parentInfo.yyyymm = vo.yyyymm
        }
      }
    }
    // [e] router.query 작업
    // console.log('$$$$$$$$$$$$ auth $$$$$$$$$$$$')
    // console.log(this.auth)
    // console.log('$$$$$$$$ memberDetail $$$$$$$$')
    // console.log(this.memberDetail)
    //this.initLeg()
    const date = new Date()
    this.year = date.getFullYear()
    this.month = date.getMonth() + 1
    this.day = date.getDate()
    if (this.month < 10) {
      this.month = '0' + this.month
    }
    if (this.day < 10) {
      this.day = '0' + this.day
    }
    this.yyyymmdd = this.year + '.' + this.month + '.' + this.day

    if (this.$store.state.schedule.suggestionSchedule != null) {
      this.parentInfo.promotionChk = this.$store.state.schedule.suggestionSchedule.promotionChk
    }

    if (this.parentInfo.promotionChk !== 'Y') {
      this.initLeg()
    }

    if (this.memberDetail !== undefined) {
      this.oonlYn = this.memberDetail.oonlYn // 수출영업(Export) 기준으로 온라인 견적 대상 화주인 경우
      this.jonlYn = this.memberDetail.jonlYn // 일본영업 기준으로 온라인 견적 대상 화주인 경우
      this.ionlYn = this.memberDetail.ionlYn // 수입영업(Import) 기준으로 온라인 견적 대상 화주인 경우
      this.oengOnlYn = this.memberDetail.oengOnlYn
    }

    // kmtc spot(kmtc on) user는 kmtc premium user와 동일함
    // kmtc premium 여부 세팅
    schedules.getKmtcSpotTarget('01').then((response) => {
      this.kmtcPremiumUserYn = response.data[0].kmtcSpotUserYn
      this.kmtcPremiumNegoYn = response.data[0].kmtcSpotNegoYn
    })
  },
  components: {
    EAutoCompletePlace,
    EAutoCompleteCommodity,
    UnnoPop,
    EmailSchedulePop,
    RoutePop,
    VesselScheduleInfoPop,
    FreSurchargePop,
    FreSurchargePop2,
    RequestQuotePop,
    PortTerminalCYPop,
    'close-info-sch': () => import('@/components/trans/CloseInfo.vue'),
    EMonthPicker: () => import('@/components/common/EMonthPicker'),
    ChangeNoticePop: () => import('@/pages/pdf/popup/ChangeNoticePop')
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          ScheduleLegPop: 'N',
          cancelRequestPop: 'N',
          podCtrCd: '',
          podPlcCd: '',
          polCtrCd: '',
          polPlcCd: '',
          promotionChk: 'N',
          scenarioCd: '',
          promoNo: '',
          voyNo: '',
          vslCd: '',
          inOutCd: ''
        }
      }
    }
  },
  data: function () {
    return {
      lang: 'KOR',
      userCtrCd: 'KR',
      fromBkg: 'N',
      pol: {
        ctrCd: '',
        plcCd: ''
      },
      pod: {
        ctrCd: '',
        plcCd: ''
      },
      yyyymmLeg: '',
      yyyymmdd: '',
      ctrCd: 'KR',
      commodityNm: '',
      commodityCd: '',
      calendarOrList: 'C',
      searchYear: '',
      searchMonth: '',
      startPlcCdList: [],
      myScheduleList: [],
      dates: [],
      currentYear: 0,
      currentMonth: 0,
      year: 0,
      month: 0,
      holidayList: [],
      bound: '',
      listSchedule: [],
      listScheduleBkg: [],
      listSchedule4List: [],
      todayStr2: '',
      rtnLegData: [],
      filterPolTrmlList: [],
      filterPodTrmlList: [],
      filterRteCdList: [],
      startPlcNameSel: [],
      destPlcNameSel: [],
      prevMonth: '',
      nextMonth: '',
      myScheduleOrNot: '',
      schId: 0,
      startPolName: '',
      destPodName: '',
      scheduleForm: {
        startPlcCd: '',
        startPlcName: '',
        destPlcCd: '',
        startCtrCd: '',
        destCtrCd: '',
        destPlcName: '',
        startPolName: '',
        destPodName: ''
      },
      scheduleDetailData: {
        pol2nd: '',
        pod2nd: '',
        etd2nd: ''
      },
      vessel2ndList: [],
      detailResp2: {
        tsDegree: '',
        vslNm1: '',
        voyNo: '',
        rteCd: '',
        polNm: '',
        podNm: '',
        pod1Nm: '',
        transitTime: '',
        transitTime1: '',
        polEtbDT: '',
        etdDT: '',
        polTml: '',
        polTmlCd: '',
        etaDT: '',
        podTml: '',
        vslNm2: '', // 2nd
        voyNo2: '',
        rteCd2: '',
        pod2Nm: '',
        transitTime2: '',
        polEtbDT2: '',
        polTml2: '',
        etaDT2: '',
        podTml2: '',
        vslNm3: '', // 3rd
        voyNo3: '',
        rteCd3: '',
        pod3Nm: '',
        transitTime3: '',
        polEtbDT3: '',
        polTml3: '',
        etaDT3: '',
        podTml3: '',
        vslNm4: '', // 4th
        voyNo4: '',
        rteCd4: '',
        pod4Nm: '',
        transitTime4: '',
        polEtbDT4: '',
        polTml4: '',
        etaDT4: '',
        podTml4: '',
        bkgDocCls: '',
        bkgCgoCls: '',
        bkgMfCls: '',
        cfsCls: '',
        mrnNo: '',
        callSign: '',
        jrstCstm: '',
        ts: '',
        vslCd: '',
        pol: '',
        pod: '',
        bkgClose: '',
        dtBkgYn: '',
        bkgVslCd: '',
        bkgVoyNo: '',
        hotDealYn: '',
        refFrtAppNo: ''
      },
      tempDetailResp2: {
        vslNm2BK: '',
        voyNo2BK: '',
        rteCd2BK: '',
        transitTime2BK: '',
        polEtbDT2BK: '',
        etaDT2BK: ''
      },
      regParams: {
        bound: 'O',
        checkBkg: 'N',
        vslType01: '01',
        vslType03: '03',
        filterDirect: 'Y',
        filterTS: 'Y',
        rf: 'N'
      },
      showBkg: 'N',
      show2ndVessel: 'N',
      detailLayer2: 'N',
      pagingList: [],
      startIdx: 0,
      endIdx: 10,
      curPage: 1,
      finalPage: 0,
      pageSize: 10,
      clickMySchedule: 'N',
      clickDetailSearch: 'N',
      afterSearch: 'N',
      afterClickDetail: 'N',
      filterPolTrmlCd: [],
      filterPodTrmlCd: [],
      filterRteCd: [],
      finalPolTrmlCd: '',
      finalPodTrmlCd: '',
      filterYn: 'N',
      filterDirect: '',
      filterTS: '',
      polTrmlStr: '',
      podTrmlStr: '',
      rteCdStr: '',
      listIndexNo: 0,
      RFinfo: 'N',
      vessel2ndButton: 'N',
      cpYn: 'N',
      cpS101: {},
      cpS201: {},
      cpS301: {},
      // Pop-up
      selectFunc: null,
      unnoDesc: '',
      unnoDescTxt: '',
      parentInfoSch: {},
      customComponentSch: null,
      popupParams: {
        UnnoPop: {
          unno: '',
          cntrTypCd: 'GP',
          scheduleFlag: 'Y'
        },
        EmailSchedulePop: {
          polCtrCd: '',
          polPlcCd: '',
          polPlcNm: '',
          podCtrCd: '',
          podPlcCd: '',
          podPlcNm: '',
          eiCatCd: ''
        },
        RoutePop: {
          rteCd: '',
          vesselNm: ''
        },
        VesselScheduleInfoPop: {
          vslNm: '',
          vslCd: '',
          voyNo: '',
          polPortCd: '',
          podPortCd: '',
          bound: '',
          polTrmlCd: '',
          bkgDocCls: '',
          bkgCgoCls: '',
          bkgMfCls: '',
          cfsCls: '',
          mrnNo: ''
        },
        PortTerminalCYPop: {
          asPortCd: '',
          asCyCd: '',
          asSvcLang: '',
          asIcdPlcCd: '',
          flag: 'schedule'
        },
        FreSurchargePop: {
          porCtrCd: '',
          porPlcCd: '',
          dlyCtrCd: '',
          dlyPlcCd: '',
          polNm: '',
          podNm: '',
          startDt: '',
          endDt: '',
          etd: '',
          frtAppNo: '',
          vslCd: '',
          voyNo: '',
          rteCd: '',
          eiCatCd: '',
          frtResult: '',
          reqRno: '',
          bkgClose: '',
          raTsParam: '',
          promotionChk: 'N',
          scenarioCd: '',
          promoNo: '',
          hotDealYn: 'N',
          refFrtAppNo: '',
          kmtcSpotYn: 'N',
          kmtcPremiumNegoYn: 'N'
        },
        FreSurchargePop2: {
          porCtrCd: '',
          porPlcCd: '',
          dlyCtrCd: '',
          dlyPlcCd: '',
          polNm: '',
          podNm: '',
          startDt: '',
          endDt: '',
          etd: '',
          frtAppNo: '',
          vslCd: '',
          vslNm: '',
          voyNo: '',
          rteCd: '',
          eiCatCd: '',
          frtResult: '',
          reqRno: '',
          bkgClose: '',
          raTsParam: '',
          promotionChk: 'N',
          scenarioCd: '',
          promoNo: '',
          hotDealYn: 'N',
          refFrtAppNo: '',
          kmtcSpotYn: 'N',
          kmtcPremiumNegoYn: 'N'
        },
        RequestQuotePop: {
          porCtrCd: '',
          porPlcCd: '',
          dlyCtrCd: '',
          dlyPlcCd: ''
        }
      },
      // Pop-up
      bkgParams: {
        vslCd: '', // [0]선명코드
        voyNo: '', // [1]항차
        vslNm: '', // [2]선명
        polName: '', // [3]출발지 포트명
        podName: '', // [4]도착지 포트명
        etd: '', // [5]입항일
        etdTm: '', // [6]입항시간
        eta: '', // [7]출항일
        etaTm: '', // [8]출항시간
        polPortCd: '', // [9]출발지 포트코드
        podPortCd: '', // [10]도착지 포트코드
        polCtrCd: '', // [11]출발지 국가코드
        podCtrCd: '', // [12]도착지 국가코드
        cct: '', // [13] ?
        docCls: '', // [14]서류마감
        docPic: '', // [15]서류마감 담당자
        polTrmlCd: '', // [16]출발지 터미널 코드
        podTrmlCd: '', // [18]도착지 터미널 코드
        mrnNo: '', // [18]MRN Number
        callSign: '', // [19]Call sign
        hidCS008I: '', // [20]info (@_@로 붙어있는 정보)
        originInfo: '', // [20]info Backup (@_@로 붙어있는 정보)
        mfCls: '', // [21]EDI 신고마감
        cgoCls: '', // [22]반입마감
        cgoPic: '', // [23]반입마감 담당자
        finalEta: '', // [24]최종ETA
        cfsCls: '', // [25]cfs 반입마감
        cfsPic: '', // [26]cfs 반입마감 담당자
        bkgCloseYn: '', // [27] booking close 여부
        prrmSgEtd: '', // [28] ?
        polTrmlNm: '', // [29] 출발지 터미널명
        railCheck: '', // [30] rail booking 여부 ? >> schedule 처리 부분 없음(주석처리)
        frtAppNo: '', // [31] 운임 APPLICATION 번호 >> 견적운임조회
        logDtm: '', // [32] >> 견적운임조회
        srRest: '', // [33] SR 생성여부 >> 부킹 제약사항체크
        localDtm: '', // [34] 현재 시간 >> 부킹 제약사항체크
        vslRest: '', // [35] 선박 close 여부 >> 부킹 제약사항체크
        cgoRest: '', // [36] 반입 마감시한 >> 부킹 제약사항체크
        kmtcSpotYn: 'N'
      },
      vessel2ndParam: '',
      bkgScheduleData: [],
      // bkgDetailData: [],
      raTsParam: '',
      effectDate: '',
      toDate: '',
      bkgOrNot: false,
      mainOrNot: false,
      moveOrNot: false,
      searchYN: 'N',
      frtResult: '',
      rmPopOpenFlag: false,
      oonlYn: 'N',
      jonlYn: 'N',
      ionlYn: 'N',
      oengOnlYn: 'N',
      bookingYN: 'N',
      freAppData: {},
      eiCatCd: '',
      popupVo: {},
      vesselInfoDetailSch: {},
      closeInfoSch: {},
      etaBookingMsg: '',
      bkgNotCnt: '60',
      isAutoSearch: false,
      callDetailInfoCnt: 0,
      logPgmCatCd: '02',
      polPortCdB4: '',
      podPortCdB4: '',
      urlParam: {}, // JamSin DSM
      urlOrNot: false, // JamSin DSM
      dsmYn: true, //process.env.VUE_APP_MODE !== 'PRD', // JamSin DSM yn
      devYn: process.env.VUE_APP_MODE !== 'PRD',
      isCollectTermBkg: false, // 운임이 없는 부킹여부 (Collect Term 을 클릭하여 부킹하는 경우)
      vslDelayReasonBtnFlag: false,
      salInfo: {
        salNm: '',
        salTelNo: '',
        salUno: '',
        salEmlAddr: ''
      },
      hongkongTsYn: 'N',
      hongkongTsMsg: '',
      kmtcPremiumUserYn: 'N', // KMTC ON 구간 유저 여부
      kmtcPremiumLineYn: 'N', // KMTC ON 구간 해당 여부
      kmtcPremiumClosYn: 'Y', // KMTC ON 구간 마감 여부
      kmtcPremiumNegoYn: 'N' // KMTC ON NEGO 메뉴 사용 여부(회원등급 C등급 사용불가, D등급 사용가능)
    }
  },
  methods: {
    handleKeydown (e) {
      if (this.pol.plcEnm === '' || this.pod.plcEnm === '') return false
      //console.log('@@@@ keydown')
      //console.log('leg>>>>>>>>>>>>', $('li[name=leg]').hasClass('on'))
      if (e.key === 'Enter' && $('.tab ul li:eq(0)').hasClass('on')) {
        // 프로모션 배너가 아닌 스케줄 화면 검색시 프로모션 비활성화 처리
        this.parentInfo.promotionChk = 'N'
        this.getLegSchedule(this.searchYear, this.searchMonth)
      }
    },
    setVuexSearchParams () {
      /* TODO
        서브개인화 추천스케줄에서 링크 클릭시 vuex에 파라미터 정보가 저장되고,
        watch에서 저장되는 action을 감지하여 이 메소드가 호출되도록 하였습니다. 파라미터 세팅해서 검색하도록 구현해주세요.
        this.$store.state.schedule.suggestionSchedule.*
      */
      console.log('########## [ScheduleLeg] setVuexSearchParams')
      console.log(this.$store.state.schedule.suggestionSchedule)
      const vo = this.$store.state.schedule.suggestionSchedule
      // [s] router.query 작업
      if (vo !== null) {
        this.isAutoSearch = true
        this.parentInfo.polCtrCd = vo.polCtrCd
        this.parentInfo.polPlcCd = vo.polPortCd
        this.parentInfo.podCtrCd = vo.podCtrCd
        this.parentInfo.podPlcCd = vo.podPortCd
        this.parentInfo.yyyymm = vo.rvsdDpoCd
        this.parentInfo.ScheduleLegPop = 'D'
        this.parentInfo.voyNo = vo.voyNo
        this.parentInfo.vslCd = vo.vslCd
        this.parentInfo.promotionChk = vo.promotionChk
        this.parentInfo.scenarioCd = vo.scenarioCd
        this.parentInfo.promoNo = vo.promoNo
        this.parentInfo.inOutCd = vo.inOutCd

        // 프로모션 유입인 경우 화면 수출, 수입 세팅
        if (this.parentInfo.promotionChk === 'Y') {
          if (this.parentInfo.inOutCd === 'C') { // Collect
            this.eiCatCd = 'I'
            this.regParams.bound = 'I'
          } else { // Prepaid
            this.eiCatCd = 'O'
            this.regParams.bound = 'O'
          }
        }

        this.initLeg()
      }
      // [e] router.query 작업
      // setTimeout(() => {
      //   this.$store.commit('schedule/updateSuggestionSchedule', null)
      // }, 100)
    },
    async getVslDelayReason () {
      const params = {
        vslCd: this.detailResp2.vslCd,
        voyNo: this.detailResp2.voyNo,
        polPortCd: this.detailResp2.pol,
        eiCatCd: this.eiCatCd
      }
      //console.log('this.detailResp2>>>>', this.detailResp2)
      await schedules.getVslDelayReason(params)
        .then(response => {
          const data = response.data
          if (data !== undefined && this.$ekmtcCommon.isNotEmpty(data)) {
            // JamSin delay
            this.vslDelayReasonBtnFlag = true
          } else {
            this.vslDelayReasonBtnFlag = false
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    async initLeg () {
      this.lang = this.auth.serviceLang
      this.userCtrCd = (this.memberDetail === undefined) ? 'KR' : this.memberDetail.userCtrCd
      // 나의 스케줄
      if (this.auth.userId !== '') {
        const list = await mySchedules.getMySchedule()
        this.myScheduleList = list.data.mySchedule
      }
      this.fromBkg = this.parentInfo.ScheduleLegPop
      // rfYn
      this.regParams.rf = this.parentInfo.rfYn
      this.bkgOrNot = false
      this.mainOrNot = false
      this.moveOrNot = false
      let searchYearMove = ''
      let searchMonthMove = ''
      let boundMove = ''
      if (this.parentInfo.ScheduleLegPop === 'Y') {
        this.bkgOrNot = true
        this.logPgmCatCd = '03'
        if (this.dsmYn) {
          // JamSin DSM 대안항로 부킹에서 팝업으로 넘어온 경우 url param 저장
          if (this.$ekmtcCommon.isNotEmpty(this.parentInfo.urlParam)) {
            this.urlOrNot = true
            this.urlParam = this.parentInfo.urlParam

            // JamSin DSM 대안항로 ETD로 날짜 자동세팅 을 위해 대한항로의 vsl 정보 조회
            await vslScheduleInfo.getVslDsmInfo(this.urlParam).then(response => {
               const vslDsmInfo = response.data.vesselScheduleInfo

              // JamSin DSM 대안항로의 스케줄 정보에 ETD가 있으면 셋팅
               if (vslDsmInfo.length > 0) {
                 searchYearMove = vslDsmInfo[0].rvsdDpoDt.substring(0, 4)
                 searchMonthMove = vslDsmInfo[0].rvsdDpoDt.substring(4, 6)
                 //this.yyyymmLeg = searchYearMove + searchMonthMove
                 boundMove = 'O' // JamSin DSM boundMove 가 셋팅안되면 서버에서 오늘 날짜로 스케줄 조회하므로 O 로 추가
               }
            })
          }
        }
      } else if (this.parentInfo.ScheduleLegPop === 'M') {
        this.mainOrNot = true
        this.logPgmCatCd = '01'
      } else if (this.parentInfo.ScheduleLegPop === 'D') {
        this.moveOrNot = true
        if (this.parentInfo.yyyymm !== undefined) {
          searchYearMove = this.parentInfo.yyyymm.substring(0, 4)
          searchMonthMove = this.parentInfo.yyyymm.substring(4, 6)
        } else {
          searchYearMove = this.year
          searchMonthMove = this.month
        }
        boundMove = 'O'
      } else if (this.parentInfo.podCtrCd === '' ||
          this.parentInfo.podPlcCd === '' ||
          this.parentInfo.polCtrCd === '' ||
          this.parentInfo.polPlcCd === '') {
        this.bkgOrNot = false
      }
      let formData = []
      if (this.bkgOrNot || this.moveOrNot) {
        // Booking Schedule Pop-up
        const params = {
          type: 'B',
          key1: this.parentInfo.polCtrCd,
          key2: this.parentInfo.polPlcCd,
          key3: this.parentInfo.podCtrCd,
          key4: this.parentInfo.podPlcCd,
          pickupDate: '',
          searchYear: searchYearMove,
          searchMonth: searchMonthMove,
          bound: boundMove
        }

        formData = await schedules.popSearchScheduleBkg(params)
        // console.log('$$$$$$$$ Booking Schedule Pop-up $$$$$$$$')
        // console.log(formData)
        // set port
        this.scheduleForm.startPolName = formData.data.startCtrCd
        this.scheduleForm.destPodName = formData.data.destCtrCd
        this.scheduleForm.startCtrCd = formData.data.startCtrCd
        this.scheduleForm.startPlcCd = formData.data.startPlcCd
        this.scheduleForm.startPlcName = formData.data.startPlcName
        this.scheduleForm.destPlcName = formData.data.destPlcName
        this.scheduleForm.destCtrCd = formData.data.destCtrCd
        this.scheduleForm.destPlcCd = formData.data.destPlcCd
        this.pol.ctrCd = formData.data.startCtrCd
        this.pol.plcCd = formData.data.startPlcCd
        this.pod.ctrCd = formData.data.destCtrCd
        this.pod.plcCd = formData.data.destPlcCd
        const frm = document.querySelector('#frmLeg')
        const elemPol = frm.querySelector('#polPol')
        const elemPod = frm.querySelector('#podPod')
        this.$ekmtcCommon.hideErrorTooltip(elemPol)
        this.$ekmtcCommon.hideErrorTooltip(elemPod)
        if (this.scheduleForm.startPlcCd !== '' && this.scheduleForm.destPlcCd !== '') {
          // JamSin DSM 대안항로의 ETD 로 스케줄 조회
          if (this.bkgOrNot && this.urlOrNot && this.$ekmtcCommon.isNotEmpty(this.parentInfo.urlParam)) {
            this.getLegSchedule(searchYearMove, searchMonthMove)
          } else {
            this.getLegSchedule(formData.data.searchYear, formData.data.searchMonth)
          }
        }
      } else if (this.mainOrNot) {
        // Booking Schedule Pop-up
        const params = {
          type: 'B',
          key1: this.parentInfo.polCtrCd,
          key2: this.parentInfo.polPlcCd,
          key3: this.parentInfo.podCtrCd,
          key4: this.parentInfo.podPlcCd,
          pickupDate: '',
          searchYear: searchYearMove,
          searchMonth: searchMonthMove,
          bound: boundMove
        }
        formData = await schedules.popSearchScheduleBkg(params)
        // set port
        this.scheduleForm.startPolName = this.parentInfo.polCtrCd
        this.scheduleForm.destPodName = this.parentInfo.podCtrCd
        this.scheduleForm.startCtrCd = this.parentInfo.polCtrCd
        this.scheduleForm.startPlcCd = this.parentInfo.polPlcCd
        this.scheduleForm.startPlcName = formData.data.startPlcName
        this.scheduleForm.destPlcName = formData.data.destPlcName
        this.scheduleForm.destCtrCd = this.parentInfo.podCtrCd
        this.scheduleForm.destPlcCd = this.parentInfo.podPlcCd
        this.pol.ctrCd = this.parentInfo.polCtrCd
        this.pol.plcCd = this.parentInfo.polPlcCd
        this.pod.ctrCd = this.parentInfo.podCtrCd
        this.pod.plcCd = this.parentInfo.podPlcCd
        const frm = document.querySelector('#frmLeg')
        const elemPol = frm.querySelector('#polPol')
        const elemPod = frm.querySelector('#podPod')
        this.$ekmtcCommon.hideErrorTooltip(elemPol)
        this.$ekmtcCommon.hideErrorTooltip(elemPod)
        if (this.scheduleForm.startPlcCd !== '' && this.scheduleForm.destPlcCd !== '') {
          this.getLegSchedule(this.year, this.month)
        }
        if (this.$ekmtcCommon.isEmpty(formData.data)) {
          formData = await schedules.getLegScheduleForm()
        }
      } else if (this.urlOrNot && (this.urlParam.dsmType === '015' || this.urlParam.dsmType === '018') && this.auth.userId) { // JamSin DSM 선호항로, 선복보장 schedule search
        const params = {
          type: 'B',
          key1: this.urlParam.polCtrCd,
          key2: this.urlParam.polPlcCd,
          key3: this.urlParam.podCtrCd,
          key4: this.urlParam.podPlcCd,
          pickupDate: '',
          searchYear: this.year,
          searchMonth: this.month,
          bound: ''
        }
        formData = await schedules.popSearchScheduleBkg(params)

        this.scheduleForm.startPolName = this.urlParam.polCtrCd
        this.scheduleForm.destPodName = this.urlParam.podCtrCd
        this.scheduleForm.startCtrCd = this.urlParam.polCtrCd
        this.scheduleForm.startPlcCd = this.urlParam.polPlcCd
        this.scheduleForm.startPlcName = formData.data.startPlcName
        this.scheduleForm.destPlcName = formData.data.destPlcName
        this.scheduleForm.destCtrCd = this.urlParam.podCtrCd
        this.scheduleForm.destPlcCd = this.urlParam.podPlcCd
        this.pol.ctrCd = this.urlParam.polCtrCd
        this.pol.plcCd = this.urlParam.polPlcCd
        this.pod.ctrCd = this.urlParam.podCtrCd
        this.pod.plcCd = this.urlParam.podPlcCd

        if (this.scheduleForm.startPlcCd !== '' && this.scheduleForm.destPlcCd !== '') {
          this.getLegSchedule(this.year, this.month)
        }
      } else {
        // 구간별 스케줄 폼
        formData = await schedules.getLegScheduleForm()
      }
      this.searchYear = formData.data.searchYear
      this.searchMonth = formData.data.searchMonth
      this.currentYear = formData.data.searchYear
      this.currentMonth = formData.data.searchMonth
      this.yyyymmLeg = formData.data.searchYear + formData.data.searchMonth
      this.holidayList = formData.data.holidayList
      this.prevMonth = formData.data.prevMonth
      this.nextMonth = formData.data.nextMonth
      this.calendarData(formData.data.searchYear, formData.data.searchMonth)

      // JamSin DSM 대안항로 ETD로 날짜 자동세팅
      //if (this.parentInfo.ScheduleLegPop === 'Y' && this.bkgOrNot && this.urlOrNot) {
      //  this.yyyymmLeg = searchYearMove + searchMonthMove
      //}
    },
    changePol (vo) {
      this.pol = vo
      this.scheduleForm.startPolName = vo.plcEnm
      this.scheduleForm.startCtrCd = vo.ctrCd
      this.scheduleForm.startPlcCd = vo.plcCd
      this.scheduleForm.startPlcName = vo.plcEnm
      const frm = document.querySelector('#frmLeg')
      const elem = frm.querySelector('#polPol')
      this.$ekmtcCommon.hideErrorTooltip(elem)
      this.filterYn = 'N'
      this.fnSalUnoSet()
    },
    changePod (vo) {
      this.pod = vo
      this.scheduleForm.destPodName = vo.plcEnm
      this.scheduleForm.destCtrCd = vo.ctrCd
      this.scheduleForm.destPlcCd = vo.plcCd
      this.scheduleForm.destPlcName = vo.plcEnm
      const frm = document.querySelector('#frmLeg')
      const elem = frm.querySelector('#podPod')
      this.$ekmtcCommon.hideErrorTooltip(elem)
      this.filterYn = 'N'
      this.fnSalUnoSet()
    },
    changeCommodity (vo) {
      this.commodityNm = vo.cdNm
      this.commodityCd = vo.cd
    },
    checkValidationPol (frm) {
      const elem = frm.querySelector('#polPol')
      let msg = this.$t('art.CMATK068') + ' ' + this.$t('tem.CMAT003') // '출발지역을 선택하세요.'
      this.$ekmtcCommon.showErrorTooltip(elem, msg)
    },
    checkValidationPod (frm) {
      const elem = frm.querySelector('#podPod')
      let msg = this.$t('art.CMATK010') + ' ' + this.$t('tem.CMAT003') // '도착지역을 선택하세요.'
      this.$ekmtcCommon.showErrorTooltip(elem, msg)
    },
    getCpS101 () {
      // CP S101 : Priotiry표시
      // - 결과 = Y 이면
      // 1. 스케쥴 호출 시 파라미터에 cp=Y 추가
      // 2. 스케쥴 정보 수신 후 적용기준/시작일자/종료일자 이외의 스케쥴은 기존 스케쥴(Priority 높은것)을 유지함. (해당 기간에만 Priority 상관없이 모든 스케쥴을 받을 수 있음)
      // 3. 스케쥴 정보 수신 후 맨마지막 배열 (127번째)이 Priority 값으로, Y인 경우 2번의 기간이 아니면 스케쥴을 삭제함.
      const cpS101 = {
        actionCd: 'S101',
        porPlc: this.scheduleForm.startPlcCd,
        dlyPlc: this.scheduleForm.destPlcCd
      }
      cp.getCallActionHandler(cpS101).then((res) => {
        console.log('>>>>> CP / S101 <<<<<')
        console.log(res)
        this.cpYn = res.data.result
        this.cpS101 = res.data
      })
    },
    getCpS201 () {
      // CP S201 : 선명항차달력표시
      // - 결과 = Y 이면
      // 1. 스케쥴 정보 수신 후 적용기준/시작일자/종료일자/TS1/TS2/TS3/항로/선명/항차 조건에 걸리는 스케쥴은 삭제함
      // 2. Output은 필수값은 없기에 넘어온 값만큼 비교하여 대상을 삭제함.
      const cpS201 = {
        actionCd: 'S201',
        porPlc: this.scheduleForm.startPlcCd,
        dlyPlc: this.scheduleForm.destPlcCd
      }
      cp.getCallActionHandler(cpS201).then((res) => {
        console.log('>>>>> CP / S201 <<<<<')
        console.log(res)
        this.cpS201 = res.data
        // return this.cpS201
        // return res.data
      })
    },
    getCpS301 () {
      // CP S301 : 선명항차색깔표시 > Web Closing 선명/항차(빨간색)가 부킹 가능하게 파란색으로 보임
      // - 결과 = Y 이면
      // 1. 스케쥴 정보 수신 후 적용기준/시작일자/종료일자/TS1/TS2/TS3/항로/선명/항차 조건에 걸리는 스케쥴중 서류마감은 Closing 유지.
      //    서류마감이 되지 않았고, Web Closing건에 대해서는 부킹 가능하게 처리.
      // 2. Output은 필수값은 없기에 넘어온 값만큼 비교하여 처리.
      const cpS301 = {
        actionCd: 'S301',
        porPlc: this.scheduleForm.startPlcCd,
        dlyPlc: this.scheduleForm.destPlcCd
      }
      cp.getCallActionHandler(cpS301).then((res) => {
        console.log('>>>>> CP / S301 <<<<<')
        console.log(res)
        this.cpS301 = res.data
      })
    },
    async getLegSchedule (year, month, filter) {
      // Commodity는 값을 삭제시 change가 발생 안되서, 객체에 내용이 없는 경우 값을 지워준다.
      //console.log('>>>>> commodity comp value : ' + document.querySelector('#scheSearchCommodity #autocomplete-form-input').value)
      if (!document.querySelector('#scheSearchCommodity #autocomplete-form-input').value) {
        this.commodityNm = ''
        this.commodityCd = ''
      }

      this.searchYear = year + ''
      this.searchMonth = month + ''
      if (this.$ekmtcCommon.isEmpty(this.polPortCdB4) && this.$ekmtcCommon.isEmpty(this.podPortCdB4)) {
        this.polPortCdB4 = this.scheduleForm.startPlcCd
        this.podPortCdB4 = this.scheduleForm.destPlcCd
      }
      if (this.polPortCdB4 !== this.scheduleForm.startPlcCd || this.podPortCdB4 !== this.scheduleForm.destPlcCd) {
        this.regParams.vslType01 = '01'
        this.regParams.vslType03 = '03'
        this.regParams.filterDirect = 'Y'
        this.regParams.filterTS = 'Y'
        this.polPortCdB4 = this.scheduleForm.startPlcCd
        this.podPortCdB4 = this.scheduleForm.destPlcCd
      }
      // this.getCpS101()
      const cpS101 = {
        actionCd: 'S101',
        porPlc: this.scheduleForm.startPlcCd,
        dlyPlc: this.scheduleForm.destPlcCd
      }
      // CP 설정 조회 액션코드 S101 - Priority가 낮은 스케쥴도 표시
      await cp.getCallActionHandler(cpS101).then((res) => {
        console.log('>>>>> CP / S101 <<<<<')
        console.log(res)
        this.cpYn = res.data.result
        this.cpS101 = res.data
      })
      // CP 설정 조회 액션코드 S201 - 선명/항차가 달력에 표시 안됨
      this.getCpS201()
      // CP 설정 조회 액션코드 S301 - Web Closing 선명/항차(빨간색)가 부킹 가능하게 파란색으로 보임
      this.getCpS301()
      this.show2ndVessel = 'N'
      const frm = document.querySelector('#frmLeg')
      let isAllOk = true
      if (this.scheduleForm.startPlcCd === '') {
        // alert(this.$t('art.CMATK068') + this.$t('tem.CMAT003')) // 출발지역을 선택하세요.
        this.checkValidationPol(frm)
        this.curPage = 1
        isAllOk = false
      }
      if (this.scheduleForm.destPlcCd === '') {
        // alert(this.$t('art.CMATK010') + this.$t('tem.CMAT003')) // 도착지역을 선택하세요.
        this.checkValidationPod(frm)
        this.curPage = 1
        isAllOk = false
      }
      if (!isAllOk) {
        return
      }
      if (this.searchYN === 'Y' && !this.moveOrNot) {
        this.$ekmtcCommon.alertDefault(this.$t('tem.CMAT033'))
        return
      }
      this.searchYN = 'Y'
      if (filter === 'N') {
        this.filterYn = 'N'
        this.polTrmlStr = ''
        this.podTrmlStr = ''
      }
      //
      this.eiCatCd = (this.bkgParams.podCtrCd === 'KR') ? 'I' : 'O'
      if (this.oengOnlYn === 'Y' && this.userCtrCd !== 'KR') {
        this.eiCatCd = 'O'
      }
      if ('TH|CN|HK|ID|IN'.indexOf(this.userCtrCd) > -1) {
        this.eiCatCd = 'O'
      }
      const params = {
        startPlcCd: this.scheduleForm.startPlcCd,
        searchMonth: month,
        pointChangeYN: '',
        bound: this.regParams.bound,
        filterPolCd: '',
        pointLength: '',
        startPlcName: this.scheduleForm.startPlcName,
        destPlcCd: this.scheduleForm.destPlcCd,
        searchYear: year,
        filterYn: this.filterYn,
        searchYN: 'Y',
        filterPodCd: '',
        hiddestPlcCd: '',
        startCtrCd: this.scheduleForm.startCtrCd,
        destCtrCd: this.scheduleForm.destCtrCd,
        polTrmlStr: this.polTrmlStr,
        podTrmlStr: this.podTrmlStr,
        rteCd: this.rteCdStr,
        filterTs: this.regParams.filterTS,
        filterDirect: this.regParams.filterDirect,
        filterTranMax: '0',
        filterTranMin: '0',
        hidstartPlcCd: '',
        destPlcName: this.scheduleForm.destPlcName,
        main: 'N',
        legIdx: '0',
        vslType01: this.regParams.vslType01,
        vslType03: this.regParams.vslType03,
        unno: this.unnoDesc,
        commodityCd: this.commodityNm,
        rf: this.regParams.rf,
        eiCatCd: this.eiCatCd,
        calendarOrList: this.calendarOrList,
        cpYn: this.cpYn,
        promotionChk: this.parentInfo.promotionChk,
        vslCd: this.parentInfo.vslCd,
        voyNo: this.parentInfo.voyNo
      }
      await schedules.getLegSchedule(params).then((rtnData) => {
        console.log('$$$$$$$$ getLegSchedule $$$$$$$$')
        console.log(rtnData)
        this.bkgNotCnt = rtnData.data.bkgNotCnt
        this.bound = rtnData.data.bound
        this.listSchedule = rtnData.data.listSchedule
        this.todayStr2 = rtnData.data.todayStr2
        this.rtnLegData = rtnData.data
        this.filterPolTrmlList = rtnData.data.filterPolTrmlList
        this.filterPodTrmlList = rtnData.data.filterPodTrmlList
        this.startPlcNameSel = rtnData.data.startPlcNameSel
        this.destPlcNameSel = rtnData.data.destPlcNameSel
        this.prevMonth = rtnData.data.prevMonth
        this.nextMonth = rtnData.data.nextMonth
        this.holidayList = rtnData.data.holidayList
        this.searchYear = rtnData.data.todayStr2.substring(0, 4)
        this.searchMonth = rtnData.data.todayStr2.substring(4, 6)
        this.currentYear = rtnData.data.todayStr2.substring(0, 4)
        this.currentMonth = rtnData.data.todayStr2.substring(4, 6)
        this.effectDate = rtnData.data.effectDate
        this.toDate = rtnData.data.toDate
        this.showBkg = this.regParams.checkBkg
        this.etaBookingMsg = rtnData.data.etaBookingMsg
        this.filterRteCdList = rtnData.data.rteCd.split(', ')
        //JamSin DSM Schedule 조회 결과 filter [선호항로:015, 대안항로:020, 선복보장:018]
        //if (this.urlOrNot && this.urlParam.dsmType === '015' && this.auth.userId && this.urlParam.rteCd !== 'ALL') {
        if (this.urlOrNot && this.urlParam.dsmType === '015' && this.auth.userId && this.urlParam.rteCd.indexOf('ALL') < 0) {
          // JamSin DSM 선호항로는 route 로 filter, ALL 이면 필터 없음
          this.listSchedule = this.listSchedule.filter(vo => this.urlParam.rteCd.indexOf(vo.rteCd) > -1)
          // JamSin DSM 선호항로는 route가 여러개 들어올수 있어 컴마로 구분 indexOf로 체크하여 filter
        //} else if (this.urlOrNot && this.urlParam.dsmType === '018' && this.auth.userId && this.urlParam.rteCd !== 'ALL') {
        } else if (this.urlOrNot && this.urlParam.dsmType === '018' && this.auth.userId && this.urlParam.rteCd.indexOf('ALL') < 0) {
          // JamSin DSM 선복보장 URL로 진입시 route 로 filter, ALL 이면 필터 없음
          this.listSchedule = this.listSchedule.filter(vo => this.urlParam.rteCd.indexOf(vo.rteCd) > -1)
          // JamSin DSM 선복보장은 route가 여러개 들어올수 있어 컴마로 구분 indexOf로 체크하여 filter
        } else if (this.urlOrNot && this.urlParam.dsmType === '020' && this.auth.userId) {
          // JamSin DSM 대안항로는 vsl, voy 로 filter
          this.listSchedule = this.listSchedule.filter((vo) => {
            return vo.vslCd === this.urlParam.vslCd && vo.voyNo === this.urlParam.voyNo
          })
        }

        if (rtnData !== null) {
          this.calendarData(year, month)
          // 상세정보 표시
          this.detailLayer2 = 'N'
          this.afterSearch = 'Y'
          this.afterClickDetail = 'N'
          // this.calendarOrList = 'C'
          this.searchYN = 'N'
        }
        if (this.filterYn === 'N') {
          this.filterPolTrmlCd = []
          for (let i = 0; i < this.filterPolTrmlList.length; i++) {
            this.filterPolTrmlCd.push(this.filterPolTrmlList[i].split('@@')[0])
          }
          this.filterPodTrmlCd = []
          for (let i = 0; i < this.filterPodTrmlList.length; i++) {
            this.filterPodTrmlCd.push(this.filterPodTrmlList[i].split('@@')[0])
          }
          this.filterRteCd = []
          for (let i = 0; i < this.filterRteCdList.length; i++) {
            this.filterRteCd.push(this.filterRteCdList[i].split('@@')[0])
          }
        }
        // List > 부킹가능한 선박 && filter 검색
        var listSize = 0
        this.listSchedule4List = []
        this.listScheduleBkg = []
        // 부킹가능한 선박
        if (this.regParams.checkBkg === 'Y') {
          if (filter !== 'N') {
            for (const item of this.listSchedule) {
              if (item.bkgClose !== 'Y' && item.filterChkYN === 'Y') {
                this.listScheduleBkg.push(item)
              }
            }
            this.listSchedule4List = this.listScheduleBkg
            listSize = this.listScheduleBkg.length
          } else {
            for (const item of this.listSchedule) {
              if (item.bkgClose !== 'Y') {
                this.listScheduleBkg.push(item)
              }
            }
            this.listSchedule4List = this.listScheduleBkg
            listSize = this.listScheduleBkg.length
          }
        } else {
          // filter 검색
          if (filter !== 'N') {
            for (const item of this.listSchedule) {
              if (item.filterChkYN === 'Y') {
                this.listScheduleBkg.push(item)
              }
            }
            this.listSchedule4List = this.listScheduleBkg
            listSize = this.listScheduleBkg.length
          } else {
            this.listSchedule4List = this.listSchedule
            listSize = this.listSchedule.length
          }
        }
        // Paging
        this.pagingList = []
        this.startIdx = 0
        this.endIdx = 10
        this.curPage = 1
        if (listSize > this.pageSize) {
          listSize = Math.ceil(listSize / this.pageSize)
          this.finalPage = listSize
          for (let i = 0; i < listSize; i++) {
            this.pagingList.push(i + 1)
          }
        }
        if (this.cpS101.result === 'Y') {
          this.setCpS101()
        }
        if (this.cpS201.result === 'Y') {
          // this.listSchedule = this.setCpS201(this.listSchedule)
          this.setCpS201()
        }
        if (this.cpS301.result === 'Y') {
          this.setCpS301()
        }
        if (this.listSchedule === undefined || this.listSchedule.length === 0) {
          this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.ONEX010T010.073') }) // 검색 결과가 없습니다.
          if (this.$ekmtcCommon.isEmpty(this.unnoDescTxt) && this.$ekmtcCommon.isEmpty(this.commodityNm)) {
            this.clickDetailSearch = 'N'
          }
        } else {
          this.clickDetailSearch = 'Y'
        }
        if (this.moveOrNot && this.parentInfo.promotionChk !== 'Y') {
          this.callDetailInfo()
        }
      })
      // 나의 스케줄
      const paramsMySch = {
        startPolCn: this.scheduleForm.startCtrCd,
        startPolCd: this.scheduleForm.startPlcCd,
        destPodCn: this.scheduleForm.destCtrCd,
        destPodCd: this.scheduleForm.destPlcCd
      }
      mySchedules.checkMySchedule(paramsMySch).then((rtnMySchData) => {
        this.myScheduleOrNot = rtnMySchData.data.myScheduleOrNot
        this.schId = rtnMySchData.data.schId
      })
      // insertWB242H
      if (this.bkgOrNot) {
        this.insertWB242H('01', '03')
      } else if (this.mainOrNot) {
        this.insertWB242H('01', '01')
      } else {
        this.insertWB242H('01', '02')
      }
      if (this.isAutoSearch) {
        this.isAutoSearch = false
        for (let i = 0; i < this.listSchedule.length; i++) {
          if (this.parentInfo.voyNo === this.listSchedule[i].voyNo &&
            this.parentInfo.polPlcCd === this.listSchedule[i].pol &&
            this.parentInfo.podPlcCd === this.listSchedule[i].pod) {
              this.fnDetailLayer2(this.listSchedule[i])

            break
          }
        }
      }
    },
    callDetailInfo () {
      setTimeout(() => {
        if (this.callDetailInfoCnt < 3) {
          try {
            const vo = this.$store.state.schedule.suggestionSchedule
            let detailVo = {}
            for (const item of this.listSchedule) {
              if (item.etd === vo.rvsdDpoCd &&
                  item.voyNo === vo.voyNo &&
                  item.vslCd === vo.vslCd &&
                  item.vslNm === vo.vslNm
              ) {
                console.log(item)
                detailVo = item
              }
            }
            setTimeout(() => {
              this.$store.commit('schedule/updateSuggestionSchedule', null)
            }, 5000)
            this.callDetailInfoCnt = 0
            this.fnDetailLayer2(detailVo)
          } catch (e) {
            this.callDetailInfoCnt++
            this.callDetailInfo()
          }
        } else {
          this.callDetailInfoCnt = 0
        }
      }, 500)
    },
    setCpS101 () {
      // CP S101 : Priotiry표시
      // - 결과 = Y 이면
      // 1. 스케쥴 호출 시 파라미터에 cp=Y 추가
      // 2. 스케쥴 정보 수신 후 적용기준/시작일자/종료일자 이외의 스케쥴은 기존 스케쥴(Priority 높은것)을 유지함. (해당 기간에만 Priority 상관없이 모든 스케쥴을 받을 수 있음)
      // 3. 스케쥴 정보 수신 후 맨마지막 배열 (127번째)이 Priority 값으로 Y인 경우, 2번의 기간이 아니면 스케쥴을 삭제함.
      if (this.cpS101.result === 'Y') {
        // 적용기준
        if (!this.$ekmtcCommon.isEmpty(this.cpS101.periodDiv)) {
          if (this.cpS101.periodDiv === '입항일자') {
            // 입항일자 > polEtb
            if (!this.$ekmtcCommon.isEmpty(this.cpS101.periodFr) && !this.$ekmtcCommon.isEmpty(this.cpS101.periodTo)) {
              // 시작일자, 종료일자 있는 경우
              for (let i = 0; i < this.listSchedule.length; i++) {
                // if (this.cpS101.periodFr <= this.listSchedule[i].polEtb && this.listSchedule[i].polEtb <= this.cpS101.periodTo) {
                if (this.listSchedule[i].polEtb <= this.cpS101.periodFr || this.cpS101.periodTo <= this.listSchedule[i].polEtb) {
                  if (this.listSchedule[i].priority === 'Y') {
                    console.log('#### [S101] 입항일자 > 시작일자, 종료일자 있는 경우')
                    this.listSchedule.splice(i, 1)
                    i--
                  }
                }
              }
            } else if (!this.$ekmtcCommon.isEmpty(this.cpS101.periodFr) && this.$ekmtcCommon.isEmpty(this.cpS101.periodTo)) {
              // 시작일자만 있는 경우
              for (let i = 0; i < this.listSchedule.length; i++) {
                // if (this.cpS101.periodFr <= this.listSchedule[i].polEtb) {
                if (this.listSchedule[i].polEtb <= this.cpS101.periodFr) {
                  if (this.listSchedule[i].priority === 'Y') {
                    console.log('#### [S101] 입항일자 > 시작일자만 있는 경우')
                    this.listSchedule.splice(i, 1)
                    i--
                  }
                }
              }
            } else if (this.$ekmtcCommon.isEmpty(this.cpS101.periodFr) && !this.$ekmtcCommon.isEmpty(this.cpS101.periodTo)) {
              // 종료일자만 있는 경우
              for (let i = 0; i < this.listSchedule.length; i++) {
                // if (this.listSchedule[i].polEtb <= this.cpS101.periodTo) {
                if (this.cpS101.periodTo <= this.listSchedule[i].polEtb) {
                  if (this.listSchedule[i].priority === 'Y') {
                    console.log('#### [S101] 입항일자 > 종료일자만 있는 경우')
                    this.listSchedule.splice(i, 1)
                    i--
                  }
                }
              }
            } else {
              // 둘 다 없는 경우는 없겠지?!
              console.log('#### [S101] 입항일자 > 둘 다 없는 경우')
              for (let i = 0; i < this.listSchedule.length; i++) {
                if (this.listSchedule[i].priority === 'Y') {
                  this.listSchedule.splice(i, 1)
                  i--
                }
              }
            }
          } else {
            // 출항일자 > etd
            if (!this.$ekmtcCommon.isEmpty(this.cpS101.periodFr) && !this.$ekmtcCommon.isEmpty(this.cpS101.periodTo)) {
              // 시작일자, 종료일자 있는 경우
              for (let i = 0; i < this.listSchedule.length; i++) {
                // console.log('>>>> list[' + i + '].etd = ' + this.listSchedule[i].etd + ' / ' + this.listSchedule[i].vslNm + ' / ' + this.listSchedule[i].voyNo + ' / ' + this.listSchedule[i].priority)
                // if (this.cpS101.periodFr <= this.listSchedule[i].etd && this.listSchedule[i].etd <= this.cpS101.periodTo) {
                if (this.listSchedule[i].etd <= this.cpS101.periodFr || this.cpS101.periodTo <= this.listSchedule[i].etd) {
                  if (this.listSchedule[i].priority === 'Y') {
                    console.log('#### [S101] 출항일자 > 시작일자, 종료일자 있는 경우')
                    this.listSchedule.splice(i, 1)
                    i--
                  }
                }
              }
            } else if (!this.$ekmtcCommon.isEmpty(this.cpS101.periodFr) && this.$ekmtcCommon.isEmpty(this.cpS101.periodTo)) {
              // 시작일자만 있는 경우
              for (let i = 0; i < this.listSchedule.length; i++) {
                // if (this.cpS101.periodFr <= this.listSchedule[i].etd) {
                if (this.listSchedule[i].etd <= this.cpS101.periodFr) {
                  if (this.listSchedule[i].priority === 'Y') {
                    console.log('#### [S101] 출항일자 > 시작일자만 있는 경우')
                    this.listSchedule.splice(i, 1)
                    i--
                  }
                }
              }
            } else if (this.$ekmtcCommon.isEmpty(this.cpS101.periodFr) && !this.$ekmtcCommon.isEmpty(this.cpS101.periodTo)) {
              // 종료일자만 있는 경우
              for (let i = 0; i < this.listSchedule.length; i++) {
                // if (this.listSchedule[i].etd <= this.cpS101.periodTo) {
                if (this.cpS101.periodTo <= this.listSchedule[i].etd) {
                  if (this.listSchedule[i].priority === 'Y') {
                    console.log('#### [S101] 출항일자 > 종료일자만 있는 경우')
                    this.listSchedule.splice(i, 1)
                    i--
                  }
                }
              }
            } else {
              // 둘 다 없는 경우는 없겠지?!
              console.log('#### [S101] 출항일자 > 둘 다 없는 경우')
              for (let i = 0; i < this.listSchedule.length; i++) {
                if (this.listSchedule[i].priority === 'Y') {
                  this.listSchedule.splice(i, 1)
                  i--
                }
              }
            }
          }
        } else {
          // No 적용기준 > 시작, 종료일도 없음
          console.log('No 적용기준')
          for (let i = 0; i < this.listSchedule.length; i++) {
            if (this.listSchedule[i].priority === 'Y') {
              this.listSchedule.splice(i, 1)
              i--
            }
          }
        }
      }
      console.log(this.listSchedule)
    },
    setCpS201 () {
      // cp S201
      // - 결과 = Y 이면
      // 1. 스케쥴 정보 수신 후 적용기준/시작일자/종료일자/TS1/TS2/TS3/항로/선명/항차 조건에 걸리는 스케쥴은 삭제함
      // 2. Output은 필수값은 없기에 넘어온 값만큼 비교하여 대상을 삭제함.
      let onlyDate = false
      if (this.$ekmtcCommon.isEmpty(this.cpS201.route) &&
          this.$ekmtcCommon.isEmpty(this.cpS201.vessel) &&
          this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo) &&
          this.$ekmtcCommon.isEmpty(this.cpS201.ts1) &&
          this.$ekmtcCommon.isEmpty(this.cpS201.ts2) &&
          this.$ekmtcCommon.isEmpty(this.cpS201.ts3)) {
        onlyDate = true
      }
      let routeArr = []
      let vesselArr = []
      let voyageNoArr = []
      if (this.cpS201.route.indexOf(',') > -1) {
        routeArr = this.cpS201.route.split(',')
      }
      if (this.cpS201.vessel.indexOf(',') > -1) {
        vesselArr = this.cpS201.vessel.split(',')
      }
      if (this.cpS201.voyageNo.indexOf(',') > -1) {
        voyageNoArr = this.cpS201.voyageNo.split(',')
      }
      // for (let j = 0; j < routeArr.length; j++) {
      //   console.log('########### routeArr[' + j + '] = ' + routeArr[j])
      //   console.log('########## vesselArr[' + j + '] = ' + vesselArr[j])
      //   console.log('######## voyageNoArr[' + j + '] = ' + voyageNoArr[j])
      // }
      if (this.cpS201.result === 'Y') {
        // 적용기준
        if (!this.$ekmtcCommon.isEmpty(this.cpS201.periodDiv)) {
          if (this.cpS201.periodDiv === '입항일자') {
            // 입항일자 > polEtb
            if (!this.$ekmtcCommon.isEmpty(this.cpS201.periodFr) && !this.$ekmtcCommon.isEmpty(this.cpS201.periodTo)) {
              // 시작일자, 종료일자 있는 경우
              for (let i = 0; i < this.listSchedule.length; i++) {
                if (this.cpS201.periodFr <= this.listSchedule[i].polEtb && this.listSchedule[i].polEtb <= this.cpS201.periodTo) {
                  console.log('#### [S201] 입항일자 > 시작일자, 종료일자 있는 경우')
                  if (onlyDate) {
                    this.listSchedule.splice(i, 1)
                    i--
                  } else {
                    // 3개 있을 경우
                    if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (routeArr.length > 0) {
                        for (let j = 0; j < routeArr.length; j++) {
                          // 3개 있을 경우
                          if (!this.$ekmtcCommon.isEmpty(routeArr[j]) && !this.$ekmtcCommon.isEmpty(vesselArr[j]) && !this.$ekmtcCommon.isEmpty(voyageNoArr[j])) {
                            if (this.listSchedule[i].rteCd + this.listSchedule[i].vslCd + this.listSchedule[i].voyNo === routeArr[j] + vesselArr[j] + voyageNoArr[j]) {
                              this.listSchedule.splice(i, 1)
                              i--
                            }
                          // 2개 있을 경우
                          } else if (!this.$ekmtcCommon.isEmpty(routeArr[j]) && !this.$ekmtcCommon.isEmpty(vesselArr[j]) && this.$ekmtcCommon.isEmpty(voyageNoArr[j])) {
                            if (this.listSchedule[i].rteCd + this.listSchedule[i].vslCd === routeArr[j] + vesselArr[j]) {
                              this.listSchedule.splice(i, 1)
                              i--
                            }
                          } else if (!this.$ekmtcCommon.isEmpty(routeArr[j]) && this.$ekmtcCommon.isEmpty(vesselArr[j]) && !this.$ekmtcCommon.isEmpty(voyageNoArr[j])) {
                            if (this.listSchedule[i].rteCd + this.listSchedule[i].voyNo === routeArr[j] + voyageNoArr[j]) {
                              this.listSchedule.splice(i, 1)
                              i--
                            }
                          } else if (this.$ekmtcCommon.isEmpty(routeArr[j]) && !this.$ekmtcCommon.isEmpty(vesselArr[j]) && !this.$ekmtcCommon.isEmpty(voyageNoArr[j])) {
                            if (this.listSchedule[i].vslCd + this.listSchedule[i].voyNo === vesselArr[j] + voyageNoArr[j]) {
                              this.listSchedule.splice(i, 1)
                              i--
                            }
                          // 1개 있을 겨우
                          } else if (!this.$ekmtcCommon.isEmpty(routeArr[j]) && this.$ekmtcCommon.isEmpty(vesselArr[j]) && this.$ekmtcCommon.isEmpty(voyageNoArr[j])) {
                            if (!this.$ekmtcCommon.isEmpty(this.listSchedule[i])) {
                              if (this.listSchedule[i].rteCd === routeArr[j]) {
                                this.listSchedule.splice(i, 1)
                                i--
                              }
                            }
                          } else if (this.$ekmtcCommon.isEmpty(routeArr[j]) && !this.$ekmtcCommon.isEmpty(vesselArr[j]) && this.$ekmtcCommon.isEmpty(voyageNoArr[j])) {
                            if (!this.$ekmtcCommon.isEmpty(this.listSchedule[i])) {
                              if (this.listSchedule[i].vslCd === vesselArr[j]) {
                                this.listSchedule.splice(i, 1)
                                i--
                              }
                            }
                          } else if (this.$ekmtcCommon.isEmpty(routeArr[j]) && this.$ekmtcCommon.isEmpty(vesselArr[j]) && !this.$ekmtcCommon.isEmpty(voyageNoArr[j])) {
                            if (!this.$ekmtcCommon.isEmpty(this.listSchedule[i])) {
                              if (this.listSchedule[i].voyNo === voyageNoArr[j]) {
                                this.listSchedule.splice(i, 1)
                                i--
                              }
                            }
                          }
                        }
                      } else {
                        if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                          console.log('#### [S201] Route(' + this.cpS201.route + '), Vessel(' + this.cpS201.vessel + '), Voyage(' + this.cpS201.voyageNo + ') 있는 경우')
                          this.listSchedule.splice(i, 1)
                          i--
                        } else if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                          this.listSchedule.splice(i, 1)
                          i--
                        }
                      }
                    // 2개 있을 경우
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel) {
                        console.log('#### [S201] Route(' + this.cpS201.route + '), Vessel(' + this.cpS201.vessel + ') 있는 경우')
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel &&
                      (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                        console.log('#### [S201] Vessel(' + this.cpS201.vessel + '), Voyage(' + this.cpS201.voyageNo + ') 있는 경우')
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                      (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                        console.log('#### [S201] Route(' + this.cpS201.route + '), Voyage(' + this.cpS201.voyageNo + ') 있는 경우')
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                      (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    // 1개 있을 경우
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].rteCd === this.cpS201.route) {
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].rteCd === this.cpS201.route &&
                      (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].vslCd === this.cpS201.vessel) {
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].vslCd === this.cpS201.vessel &&
                      (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                      (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    // 없을 경우
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    }
                    // if (this.listSchedule[i].voyNo === this.cpS201.voyageNo || this.listSchedule[i].vslCd === this.cpS201.vessel || this.listSchedule[i].rteCd === this.cpS201.route ||
                    //     this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3) {
                    //   this.listSchedule.splice(i, 1)
                    //   i--
                    // }
                  }
                }
              }
            } else if (!this.$ekmtcCommon.isEmpty(this.cpS201.periodFr) && this.$ekmtcCommon.isEmpty(this.cpS201.periodTo)) {
              // 시작일자만 있는 경우
              for (let i = 0; i < this.listSchedule.length; i++) {
                if (this.cpS201.periodFr <= this.listSchedule[i].polEtb) {
                  console.log('#### [S201] 입항일자 > 시작일자만 있는 경우')
                  if (onlyDate) {
                    this.listSchedule.splice(i, 1)
                    i--
                  } else {
                    // 3개 있을 경우
                    if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                        console.log('#### [S201] Route(' + this.cpS201.route + '), Vessel(' + this.cpS201.vessel + '), Voyage(' + this.cpS201.voyageNo + ') 있는 경우')
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                      (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    // 2개 있을 경우
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel) {
                        console.log('#### [S201] Route(' + this.cpS201.route + '), Vessel(' + this.cpS201.vessel + ') 있는 경우')
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel &&
                      (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                        console.log('#### [S201] Vessel(' + this.cpS201.vessel + '), Voyage(' + this.cpS201.voyageNo + ') 있는 경우')
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                      (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                        console.log('#### [S201] Route(' + this.cpS201.route + '), Voyage(' + this.cpS201.voyageNo + ') 있는 경우')
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                      (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    // 1개 있을 경우
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].rteCd === this.cpS201.route) {
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].rteCd === this.cpS201.route &&
                      (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].vslCd === this.cpS201.vessel) {
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].vslCd === this.cpS201.vessel &&
                      (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                      (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    // 없을 경우
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    }
                    // if (this.listSchedule[i].voyNo === this.cpS201.voyageNo || this.listSchedule[i].vslCd === this.cpS201.vessel || this.listSchedule[i].rteCd === this.cpS201.route ||
                    //     this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3) {
                    //   this.listSchedule.splice(i, 1)
                    //   i--
                    // }
                  }
                }
              }
            } else if (this.$ekmtcCommon.isEmpty(this.cpS201.periodFr) && !this.$ekmtcCommon.isEmpty(this.cpS201.periodTo)) {
              // 종료일자만 있는 경우
              for (let i = 0; i < this.listSchedule.length; i++) {
                if (this.listSchedule[i].polEtb <= this.cpS201.periodTo) {
                  console.log('#### [S201] 입항일자 > 종료일자만 있는 경우')
                  if (onlyDate) {
                    this.listSchedule.splice(i, 1)
                    i--
                  } else {
                    // 3개 있을 경우
                    if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                        console.log('#### [S201] Route(' + this.cpS201.route + '), Vessel(' + this.cpS201.vessel + '), Voyage(' + this.cpS201.voyageNo + ') 있는 경우')
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                      (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    // 2개 있을 경우
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel) {
                        console.log('#### [S201] Route(' + this.cpS201.route + '), Vessel(' + this.cpS201.vessel + ') 있는 경우')
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel &&
                      (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                        console.log('#### [S201] Vessel(' + this.cpS201.vessel + '), Voyage(' + this.cpS201.voyageNo + ') 있는 경우')
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                      (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                        console.log('#### [S201] Route(' + this.cpS201.route + '), Voyage(' + this.cpS201.voyageNo + ') 있는 경우')
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                      (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    // 1개 있을 경우
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].rteCd === this.cpS201.route) {
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].rteCd === this.cpS201.route &&
                      (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].vslCd === this.cpS201.vessel) {
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].vslCd === this.cpS201.vessel &&
                      (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                      (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    // 없을 경우
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    }
                    // if (this.listSchedule[i].voyNo === this.cpS201.voyageNo || this.listSchedule[i].vslCd === this.cpS201.vessel || this.listSchedule[i].rteCd === this.cpS201.route ||
                    //     this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3) {
                    //   this.listSchedule.splice(i, 1)
                    //   i--
                    // }
                  }
                }
              }
            } else {
              // 둘 다 없는 경우는 없겠지?!
              console.log('#### [S201] 입항일자 > 둘 다 없는 경우')
              for (let i = 0; i < this.listSchedule.length; i++) {
                if (this.listSchedule[i].voyNo === this.cpS201.voyageNo || this.listSchedule[i].vslCd === this.cpS201.vessel || this.listSchedule[i].rteCd === this.cpS201.route ||
                    this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3) {
                  this.listSchedule.splice(i, 1)
                  i--
                }
              }
            }
          } else {
            // 출항일자 > etd
            if (!this.$ekmtcCommon.isEmpty(this.cpS201.periodFr) && !this.$ekmtcCommon.isEmpty(this.cpS201.periodTo)) {
              // 시작일자, 종료일자 있는 경우
              for (let i = 0; i < this.listSchedule.length; i++) {
                // console.log('Only Date >> list[' + i + '].etd = ' + this.listSchedule[i].etd + ' / ' + this.listSchedule[i].vslNm + ' / ' + this.listSchedule[i].voyNo)
                if (this.cpS201.periodFr <= this.listSchedule[i].etd && this.listSchedule[i].etd <= this.cpS201.periodTo) {
                  console.log('#### [S201] 출항일자 > 시작일자, 종료일자 있는 경우')
                  if (onlyDate) {
                    this.listSchedule.splice(i, 1)
                    i--
                  } else {
                    // 3개 있을 경우
                    if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (routeArr.length > 0) {
                        for (let j = 0; j < routeArr.length; j++) {
                          // 3개 있을 경우
                          if (!this.$ekmtcCommon.isEmpty(routeArr[j]) && !this.$ekmtcCommon.isEmpty(vesselArr[j]) && !this.$ekmtcCommon.isEmpty(voyageNoArr[j])) {
                            if (this.listSchedule[i].rteCd + this.listSchedule[i].vslCd + this.listSchedule[i].voyNo === routeArr[j] + vesselArr[j] + voyageNoArr[j]) {
                              this.listSchedule.splice(i, 1)
                              i--
                            }
                          // 2개 있을 경우
                          } else if (!this.$ekmtcCommon.isEmpty(routeArr[j]) && !this.$ekmtcCommon.isEmpty(vesselArr[j]) && this.$ekmtcCommon.isEmpty(voyageNoArr[j])) {
                            if (this.listSchedule[i].rteCd + this.listSchedule[i].vslCd === routeArr[j] + vesselArr[j]) {
                              this.listSchedule.splice(i, 1)
                              i--
                            }
                          } else if (!this.$ekmtcCommon.isEmpty(routeArr[j]) && this.$ekmtcCommon.isEmpty(vesselArr[j]) && !this.$ekmtcCommon.isEmpty(voyageNoArr[j])) {
                            if (this.listSchedule[i].rteCd + this.listSchedule[i].voyNo === routeArr[j] + voyageNoArr[j]) {
                              this.listSchedule.splice(i, 1)
                              i--
                            }
                          } else if (this.$ekmtcCommon.isEmpty(routeArr[j]) && !this.$ekmtcCommon.isEmpty(vesselArr[j]) && !this.$ekmtcCommon.isEmpty(voyageNoArr[j])) {
                            if (this.listSchedule[i].vslCd + this.listSchedule[i].voyNo === vesselArr[j] + voyageNoArr[j]) {
                              this.listSchedule.splice(i, 1)
                              i--
                            }
                          // 1개 있을 겨우
                          } else if (!this.$ekmtcCommon.isEmpty(routeArr[j]) && this.$ekmtcCommon.isEmpty(vesselArr[j]) && this.$ekmtcCommon.isEmpty(voyageNoArr[j])) {
                            if (!this.$ekmtcCommon.isEmpty(this.listSchedule[i])) {
                              if (this.listSchedule[i].rteCd === routeArr[j]) {
                                this.listSchedule.splice(i, 1)
                                i--
                              }
                            }
                          } else if (this.$ekmtcCommon.isEmpty(routeArr[j]) && !this.$ekmtcCommon.isEmpty(vesselArr[j]) && this.$ekmtcCommon.isEmpty(voyageNoArr[j])) {
                            if (!this.$ekmtcCommon.isEmpty(this.listSchedule[i])) {
                              if (this.listSchedule[i].vslCd === vesselArr[j]) {
                                this.listSchedule.splice(i, 1)
                                i--
                              }
                            }
                          } else if (this.$ekmtcCommon.isEmpty(routeArr[j]) && this.$ekmtcCommon.isEmpty(vesselArr[j]) && !this.$ekmtcCommon.isEmpty(voyageNoArr[j])) {
                            if (!this.$ekmtcCommon.isEmpty(this.listSchedule[i])) {
                              if (this.listSchedule[i].voyNo === voyageNoArr[j]) {
                                this.listSchedule.splice(i, 1)
                                i--
                              }
                            }
                          }
                        }
                      } else {
                        if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                          console.log('#### [S201] Route(' + this.cpS201.route + '), Vessel(' + this.cpS201.vessel + '), Voyage(' + this.cpS201.voyageNo + ') 있는 경우')
                          this.listSchedule.splice(i, 1)
                          i--
                        } else if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                          this.listSchedule.splice(i, 1)
                          i--
                        }
                      }
                    // 2개 있을 경우
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (routeArr.length > 0) {
                        for (let j = 0; j < routeArr.length; j++) {
                          if (!this.$ekmtcCommon.isEmpty(routeArr[j]) && !this.$ekmtcCommon.isEmpty(vesselArr[j])) {
                            if (this.listSchedule[i].rteCd === routeArr[j] && this.listSchedule[i].vslCd === vesselArr[j]) {
                              this.listSchedule.splice(i, 1)
                              i--
                            }
                          } else if (!this.$ekmtcCommon.isEmpty(routeArr[j]) && this.$ekmtcCommon.isEmpty(vesselArr[j])) {
                            if (this.listSchedule[i].rteCd === routeArr[j]) {
                              this.listSchedule.splice(i, 1)
                              i--
                            }
                          } else if (this.$ekmtcCommon.isEmpty(routeArr[j]) && !this.$ekmtcCommon.isEmpty(vesselArr[j])) {
                            if (this.listSchedule[i].vslCd === vesselArr[j]) {
                              this.listSchedule.splice(i, 1)
                              i--
                            }
                          }
                        }
                      } else {
                        if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel) {
                          console.log('#### [S201] Route(' + this.cpS201.route + '), Vessel(' + this.cpS201.vessel + ') 있는 경우')
                          this.listSchedule.splice(i, 1)
                          i--
                        } else if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                          this.listSchedule.splice(i, 1)
                          i--
                        }
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (vesselArr.length > 0) {
                        for (let j = 0; j < vesselArr.length; j++) {
                          if (!this.$ekmtcCommon.isEmpty(voyageNoArr[j]) && !this.$ekmtcCommon.isEmpty(vesselArr[j])) {
                            if (this.listSchedule[i].voyNo === voyageNoArr[j] && this.listSchedule[i].vslCd === vesselArr[j]) {
                              this.listSchedule.splice(i, 1)
                              i--
                            }
                          } else if (!this.$ekmtcCommon.isEmpty(voyageNoArr[j]) && this.$ekmtcCommon.isEmpty(vesselArr[j])) {
                            if (this.listSchedule[i].voyNo === voyageNoArr[j]) {
                              this.listSchedule.splice(i, 1)
                              i--
                            }
                          } else if (this.$ekmtcCommon.isEmpty(voyageNoArr[j]) && !this.$ekmtcCommon.isEmpty(vesselArr[j])) {
                            if (this.listSchedule[i].vslCd === vesselArr[j]) {
                              this.listSchedule.splice(i, 1)
                              i--
                            }
                          }
                        }
                      } else {
                        if (this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                          console.log('#### [S201] Vessel(' + this.cpS201.vessel + '), Voyage(' + this.cpS201.voyageNo + ') 있는 경우')
                          this.listSchedule.splice(i, 1)
                          i--
                        } else if (this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                          this.listSchedule.splice(i, 1)
                          i--
                        }
                      }
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (routeArr.length > 0) {
                        for (let j = 0; j < routeArr.length; j++) {
                          if (!this.$ekmtcCommon.isEmpty(routeArr[j]) && !this.$ekmtcCommon.isEmpty(voyageNoArr[j])) {
                            if (this.listSchedule[i].rteCd === routeArr[j] && this.listSchedule[i].voyNo === voyageNoArr[j]) {
                              this.listSchedule.splice(i, 1)
                              i--
                            }
                          } else if (!this.$ekmtcCommon.isEmpty(routeArr[j]) && this.$ekmtcCommon.isEmpty(voyageNoArr[j])) {
                            if (this.listSchedule[i].rteCd === routeArr[j]) {
                              this.listSchedule.splice(i, 1)
                              i--
                            }
                          } else if (this.$ekmtcCommon.isEmpty(routeArr[j]) && !this.$ekmtcCommon.isEmpty(voyageNoArr[j])) {
                            if (this.listSchedule[i].voyNo === voyageNoArr[j]) {
                              this.listSchedule.splice(i, 1)
                              i--
                            }
                          }
                        }
                      } else {
                        if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                          console.log('#### [S201] Route(' + this.cpS201.route + '), Voyage(' + this.cpS201.voyageNo + ') 있는 경우')
                          this.listSchedule.splice(i, 1)
                          i--
                        } else if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                          this.listSchedule.splice(i, 1)
                          i--
                        }
                      }
                    // 1개 있을 경우
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (routeArr.length > 0) {
                        for (let j = 0; j < routeArr.length; j++) {
                          if (!this.$ekmtcCommon.isEmpty(routeArr[j])) {
                            if (this.listSchedule[i].rteCd === routeArr[j]) {
                              this.listSchedule.splice(i, 1)
                              i--
                            }
                          }
                        }
                      } else {
                        if (this.listSchedule[i].rteCd === this.cpS201.route) {
                          this.listSchedule.splice(i, 1)
                          i--
                        } else if (this.listSchedule[i].rteCd === this.cpS201.route &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                          this.listSchedule.splice(i, 1)
                          i--
                        }
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (vesselArr.length > 0) {
                        for (let j = 0; j < vesselArr.length; j++) {
                          if (!this.$ekmtcCommon.isEmpty(vesselArr[j])) {
                            if (this.listSchedule[i].vslCd === vesselArr[j]) {
                              this.listSchedule.splice(i, 1)
                              i--
                            }
                          }
                        }
                      } else {
                        if (this.listSchedule[i].vslCd === this.cpS201.vessel) {
                          this.listSchedule.splice(i, 1)
                          i--
                        } else if (this.listSchedule[i].vslCd === this.cpS201.vessel &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                          this.listSchedule.splice(i, 1)
                          i--
                        }
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (voyageNoArr.length > 0) {
                        for (let j = 0; j < voyageNoArr.length; j++) {
                          if (!this.$ekmtcCommon.isEmpty(voyageNoArr[j])) {
                            if (this.listSchedule[i].voyNo === voyageNoArr[j]) {
                              this.listSchedule.splice(i, 1)
                              i--
                            }
                          }
                        }
                      } else {
                        if (this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                          this.listSchedule.splice(i, 1)
                          i--
                        } else if (this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                        (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                          this.listSchedule.splice(i, 1)
                          i--
                        }
                      }
                    // 없을 경우
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    }
                    // if (this.listSchedule[i].voyNo === this.cpS201.voyageNo || this.listSchedule[i].vslCd === this.cpS201.vessel || this.listSchedule[i].rteCd === this.cpS201.route ||
                    //     this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3) {
                    //       this.listSchedule.splice(i, 1)
                    //       i--
                    // }
                  }
                }
              }
            } else if (!this.$ekmtcCommon.isEmpty(this.cpS201.periodFr) && this.$ekmtcCommon.isEmpty(this.cpS201.periodTo)) {
              // 시작일자만 있는 경우
              for (let i = 0; i < this.listSchedule.length; i++) {
                if (this.cpS201.periodFr <= this.listSchedule[i].etd) {
                  console.log('#### [S201] 출항일자 > 시작일자만 있는 경우')
                  if (onlyDate) {
                    this.listSchedule.splice(i, 1)
                    i--
                  } else {
                    // 3개 있을 경우
                    if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                        console.log('#### [S201] Route(' + this.cpS201.route + '), Vessel(' + this.cpS201.vessel + '), Voyage(' + this.cpS201.voyageNo + ') 있는 경우')
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                      (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    // 2개 있을 경우
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel) {
                        console.log('#### [S201] Route(' + this.cpS201.route + '), Vessel(' + this.cpS201.vessel + ') 있는 경우')
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel &&
                      (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                        console.log('#### [S201] Vessel(' + this.cpS201.vessel + '), Voyage(' + this.cpS201.voyageNo + ') 있는 경우')
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                      (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                        console.log('#### [S201] Route(' + this.cpS201.route + '), Voyage(' + this.cpS201.voyageNo + ') 있는 경우')
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                      (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    // 1개 있을 경우
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].rteCd === this.cpS201.route) {
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].rteCd === this.cpS201.route &&
                      (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].vslCd === this.cpS201.vessel) {
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].vslCd === this.cpS201.vessel &&
                      (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                      (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    // 없을 경우
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    }
                    // if (this.listSchedule[i].voyNo === this.cpS201.voyageNo || this.listSchedule[i].vslCd === this.cpS201.vessel || this.listSchedule[i].rteCd === this.cpS201.route ||
                    //     this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3) {
                    //   this.listSchedule.splice(i, 1)
                    //   i--
                    // }
                  }
                }
              }
            } else if (this.$ekmtcCommon.isEmpty(this.cpS201.periodFr) && !this.$ekmtcCommon.isEmpty(this.cpS201.periodTo)) {
              // 종료일자만 있는 경우
              for (let i = 0; i < this.listSchedule.length; i++) {
                if (this.listSchedule[i].etd <= this.cpS201.periodTo) {
                  console.log('#### [S201] 출항일자 > 종료일자만 있는 경우')
                  if (onlyDate) {
                    this.listSchedule.splice(i, 1)
                    i--
                  } else {
                    // 3개 있을 경우
                    if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                        console.log('#### [S201] Route(' + this.cpS201.route + '), Vessel(' + this.cpS201.vessel + '), Voyage(' + this.cpS201.voyageNo + ') 있는 경우')
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                      (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    // 2개 있을 경우
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel) {
                        console.log('#### [S201] Route(' + this.cpS201.route + '), Vessel(' + this.cpS201.vessel + ') 있는 경우')
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel &&
                      (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                        console.log('#### [S201] Vessel(' + this.cpS201.vessel + '), Voyage(' + this.cpS201.voyageNo + ') 있는 경우')
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                      (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                        console.log('#### [S201] Route(' + this.cpS201.route + '), Voyage(' + this.cpS201.voyageNo + ') 있는 경우')
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                      (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    // 1개 있을 경우
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].rteCd === this.cpS201.route) {
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].rteCd === this.cpS201.route &&
                      (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].vslCd === this.cpS201.vessel) {
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].vslCd === this.cpS201.vessel &&
                      (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                        this.listSchedule.splice(i, 1)
                        i--
                      } else if (this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                      (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    // 없을 경우
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                      if (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3) {
                        this.listSchedule.splice(i, 1)
                        i--
                      }
                    }
                    // if (this.listSchedule[i].voyNo === this.cpS201.voyageNo || this.listSchedule[i].vslCd === this.cpS201.vessel || this.listSchedule[i].rteCd === this.cpS201.route ||
                    //     this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3) {
                    //   this.listSchedule.splice(i, 1)
                    //   i--
                    // }
                  }
                }
              }
            } else {
              // 둘 다 없는 경우는 없겠지?!
              console.log('#### [S201] 출항일자 > 둘 다 없는 경우')
              for (let i = 0; i < this.listSchedule.length; i++) {
                // 3개 있을 경우
                if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                  if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                    console.log('#### [S201] Route(' + this.cpS201.route + '), Vessel(' + this.cpS201.vessel + '), Voyage(' + this.cpS201.voyageNo + ') 있는 경우')
                    this.listSchedule.splice(i, 1)
                    i--
                  } else if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                  (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                    this.listSchedule.splice(i, 1)
                    i--
                  }
                // 2개 있을 경우
                } else if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                  if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel) {
                    console.log('#### [S201] Route(' + this.cpS201.route + '), Vessel(' + this.cpS201.vessel + ') 있는 경우')
                    this.listSchedule.splice(i, 1)
                    i--
                  } else if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].vslCd === this.cpS201.vessel &&
                  (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                    this.listSchedule.splice(i, 1)
                    i--
                  }
                } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                  if (this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                    console.log('#### [S201] Vessel(' + this.cpS201.vessel + '), Voyage(' + this.cpS201.voyageNo + ') 있는 경우')
                    this.listSchedule.splice(i, 1)
                    i--
                  } else if (this.listSchedule[i].vslCd === this.cpS201.vessel && this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                  (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                    this.listSchedule.splice(i, 1)
                    i--
                  }
                } else if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                  if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                    console.log('#### [S201] Route(' + this.cpS201.route + '), Voyage(' + this.cpS201.voyageNo + ') 있는 경우')
                    this.listSchedule.splice(i, 1)
                    i--
                  } else if (this.listSchedule[i].rteCd === this.cpS201.route && this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                  (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                    this.listSchedule.splice(i, 1)
                    i--
                  }
                // 1개 있을 경우
                } else if (!this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                  if (this.listSchedule[i].rteCd === this.cpS201.route) {
                    this.listSchedule.splice(i, 1)
                    i--
                  } else if (this.listSchedule[i].rteCd === this.cpS201.route &&
                  (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                    this.listSchedule.splice(i, 1)
                    i--
                  }
                } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && !this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                  if (this.listSchedule[i].vslCd === this.cpS201.vessel) {
                    this.listSchedule.splice(i, 1)
                    i--
                  } else if (this.listSchedule[i].vslCd === this.cpS201.vessel &&
                  (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                    this.listSchedule.splice(i, 1)
                    i--
                  }
                } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                  if (this.listSchedule[i].voyNo === this.cpS201.voyageNo) {
                    this.listSchedule.splice(i, 1)
                    i--
                  } else if (this.listSchedule[i].voyNo === this.cpS201.voyageNo &&
                  (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3)) {
                    this.listSchedule.splice(i, 1)
                    i--
                  }
                // 없을 경우
                } else if (this.$ekmtcCommon.isEmpty(this.cpS201.route) && this.$ekmtcCommon.isEmpty(this.cpS201.vessel) && this.$ekmtcCommon.isEmpty(this.cpS201.voyageNo)) {
                  if (this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3) {
                    this.listSchedule.splice(i, 1)
                    i--
                  }
                }
                // if (this.listSchedule[i].voyNo === this.cpS201.voyageNo || this.listSchedule[i].vslCd === this.cpS201.vessel || this.listSchedule[i].rteCd === this.cpS201.route ||
                //     this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3) {
                //   this.listSchedule.splice(i, 1)
                //   i--
                // }
              }
            }
          }
        } else {
          // No 적용기준 > 시작, 종료일도 없음
          console.log('No 적용기준')
          for (let i = 0; i < this.listSchedule.length; i++) {
            if (this.listSchedule[i].voyNo === this.cpS201.voyageNo || this.listSchedule[i].vslCd === this.cpS201.vessel || this.listSchedule[i].rteCd === this.cpS201.route ||
                this.listSchedule[i].pol2 === this.cpS201.ts1 || this.listSchedule[i].pol3 === this.cpS201.ts2 || this.listSchedule[i].pol4 === this.cpS201.ts3) {
              this.listSchedule.splice(i, 1)
              i--
            }
          }
        }
      }
    },
    async setCpS301 () {
      // CP S301 : 선명항차색깔표시 > Web Closing 선명/항차(빨간색)가 부킹 가능하게 파란색으로 보임
      // - 결과 = Y 이면
      // 1. 스케쥴 정보 수신 후 적용기준/시작일자/종료일자/TS1/TS2/TS3/항로/선명/항차 조건에 걸리는 스케쥴중 서류마감은 Closing 유지.
      //    서류마감이 되지 않았고, Web Closing건에 대해서는 부킹 가능하게 처리.
      // 2. Output은 필수값은 없기에 넘어온 값만큼 비교하여 처리.
      let onlyDate = false
      if (this.$ekmtcCommon.isEmpty(this.cpS301.route) &&
          this.$ekmtcCommon.isEmpty(this.cpS301.vessel) &&
          this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo) &&
          this.$ekmtcCommon.isEmpty(this.cpS301.ts1) &&
          this.$ekmtcCommon.isEmpty(this.cpS301.ts2) &&
          this.$ekmtcCommon.isEmpty(this.cpS301.ts3)) {
        onlyDate = true
      }
      let routeArr = []
      let vesselArr = []
      let voyageNoArr = []
      if (this.cpS301.route.indexOf(',') > -1) {
        routeArr = this.cpS301.route.split(',')
      }
      if (this.cpS301.vessel.indexOf(',') > -1) {
        vesselArr = this.cpS301.vessel.split(',')
      }
      if (this.cpS301.voyageNo.indexOf(',') > -1) {
        voyageNoArr = this.cpS301.voyageNo.split(',')
      }
      let serverDt = ''
      await commons.getServerTime().then(res => {
        if (res.data !== undefined) {
          serverDt = res.data.length > 12 ? res.data.substring(0, 12) : res.data
        }
      })

      let idx = 0
      if (this.cpS301.result === 'Y') {
        // 적용기준
        if (!this.$ekmtcCommon.isEmpty(this.cpS301.periodDiv)) {
          if (this.cpS301.periodDiv === '입항일자') {
            // 입항일자 > polEtb
            if (!this.$ekmtcCommon.isEmpty(this.cpS301.periodFr) && !this.$ekmtcCommon.isEmpty(this.cpS301.periodTo)) {
              // 시작일자, 종료일자 있는 경우
              for (const item of this.listSchedule) {
                if (this.$ekmtcCommon.isNotEmpty(item.bkgDocCls) && Number(serverDt) > Number(item.bkgDocCls)) {
                  continue
                }
                if (item.closeInfoVO.bkgClosed === 'CLOSED') {
                  if (this.cpS301.periodFr <= item.polEtb && item.polEtb <= this.cpS301.periodTo) {
                    //console.log('#### [S301] 입항일자 > 시작일자, 종료일자 있는 경우')
                    if (onlyDate) {
                      item.bkgClose = 'A'
                    } else {
                      // 3개 있을 경우
                      if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                        if (routeArr.length > 0) {
                          for (let j = 0; j < routeArr.length; j++) {
                            // 3개 있을 경우
                            if (!this.$ekmtcCommon.isEmpty(routeArr[j]) && !this.$ekmtcCommon.isEmpty(vesselArr[j]) && !this.$ekmtcCommon.isEmpty(voyageNoArr[j])) {
                              if (item.rteCd + item.vslCd + item.voyNo === routeArr[j] + vesselArr[j] + voyageNoArr[j]) {
                                item.bkgClose = 'A'
                              }
                            // 2개 있을 경우
                            } else if (!this.$ekmtcCommon.isEmpty(routeArr[j]) && !this.$ekmtcCommon.isEmpty(vesselArr[j]) && this.$ekmtcCommon.isEmpty(voyageNoArr[j])) {
                              if (item.rteCd + item.vslCd === routeArr[j] + vesselArr[j]) {
                                item.bkgClose = 'A'
                              }
                            } else if (!this.$ekmtcCommon.isEmpty(routeArr[j]) && this.$ekmtcCommon.isEmpty(vesselArr[j]) && !this.$ekmtcCommon.isEmpty(voyageNoArr[j])) {
                              if (item.rteCd + item.voyNo === routeArr[j] + voyageNoArr[j]) {
                                item.bkgClose = 'A'
                              }
                            } else if (this.$ekmtcCommon.isEmpty(routeArr[j]) && !this.$ekmtcCommon.isEmpty(vesselArr[j]) && !this.$ekmtcCommon.isEmpty(voyageNoArr[j])) {
                              if (item.vslCd + item.voyNo === vesselArr[j] + voyageNoArr[j]) {
                                item.bkgClose = 'A'
                              }
                            // 1개 있을 겨우
                            } else if (!this.$ekmtcCommon.isEmpty(routeArr[j]) && this.$ekmtcCommon.isEmpty(vesselArr[j]) && this.$ekmtcCommon.isEmpty(voyageNoArr[j])) {
                              if (item.rteCd === routeArr[j]) {
                                item.bkgClose = 'A'
                              }
                            } else if (this.$ekmtcCommon.isEmpty(routeArr[j]) && !this.$ekmtcCommon.isEmpty(vesselArr[j]) && this.$ekmtcCommon.isEmpty(voyageNoArr[j])) {
                              if (item.vslCd === vesselArr[j]) {
                                item.bkgClose = 'A'
                              }
                            } else if (this.$ekmtcCommon.isEmpty(routeArr[j]) && this.$ekmtcCommon.isEmpty(vesselArr[j]) && !this.$ekmtcCommon.isEmpty(voyageNoArr[j])) {
                              if (item.voyNo === voyageNoArr[j]) {
                                item.bkgClose = 'A'
                              }
                            }
                          }
                        } else {
                          if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo) {
                            console.log('#### [S301] Route(' + this.cpS301.route + '), Vessel(' + this.cpS301.vessel + '), Voyage(' + this.cpS301.voyageNo + ') 있는 경우')
                            item.bkgClose = 'A'
                          } else if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo &&
                          (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                            item.bkgClose = 'A'
                          }
                        }
                      // 2개 있을 경우
                      } else if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                        if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel) {
                          console.log('#### [S301] Route(' + this.cpS301.route + '), Vessel(' + this.cpS301.vessel + ') 있는 경우')
                          item.bkgClose = 'A'
                        } else if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                          item.bkgClose = 'A'
                        }
                      } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                        if (item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo) {
                          console.log('#### [S301] Vessel(' + this.cpS301.vessel + '), Voyage(' + this.cpS301.voyageNo + ') 있는 경우')
                          item.bkgClose = 'A'
                        } else if (item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                          item.bkgClose = 'A'
                        }
                      } else if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                        if (item.rteCd === this.cpS301.route && item.voyNo === this.cpS301.voyageNo) {
                          console.log('#### [S301] Route(' + this.cpS301.route + '), Voyage(' + this.cpS301.voyageNo + ') 있는 경우')
                          item.bkgClose = 'A'
                        } else if (item.rteCd === this.cpS301.route && item.voyNo === this.cpS301.voyageNo &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                          item.bkgClose = 'A'
                        }
                      // 1개 있을 경우
                      } else if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                        if (item.rteCd === this.cpS301.route) {
                          item.bkgClose = 'A'
                        } else if (item.rteCd === this.cpS301.route &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                          item.bkgClose = 'A'
                        }
                      } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                        if (item.vslCd === this.cpS301.vessel) {
                          item.bkgClose = 'A'
                        } else if (item.vslCd === this.cpS301.vessel &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                          item.bkgClose = 'A'
                        }
                      } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                        if (item.voyNo === this.cpS301.voyageNo) {
                          item.bkgClose = 'A'
                        } else if (item.voyNo === this.cpS301.voyageNo &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                          item.bkgClose = 'A'
                        }
                      // 없을 경우
                      } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                        if (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3) {
                          item.bkgClose = 'A'
                        }
                      }
                      // if (item.voyNo === this.cpS301.voyageNo || item.vslCd === this.cpS301.vessel || item.rteCd === this.cpS301.route ||
                      //     item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3) {
                      //   item.bkgClose = 'A'
                      // }
                    }
                  }
                }
                idx++
              }
            } else if (!this.$ekmtcCommon.isEmpty(this.cpS301.periodFr) && this.$ekmtcCommon.isEmpty(this.cpS301.periodTo)) {
              // 시작일자만 있는 경우
              for (const item of this.listSchedule) {
                if (this.$ekmtcCommon.isNotEmpty(item.bkgDocCls) && Number(serverDt) > Number(item.bkgDocCls)) {
                  continue
                }

                if (item.closeInfoVO.bkgClosed === 'CLOSED') {
                  if (this.cpS301.periodFr <= item.polEtb) {
                    //console.log('#### [S301] 입항일자 > 시작일자만 있는 경우')
                    if (onlyDate) {
                      item.bkgClose = 'A'
                    } else {
                      // 3개 있을 경우
                      if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                        if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo) {
                          console.log('#### [S301] Route(' + this.cpS301.route + '), Vessel(' + this.cpS301.vessel + '), Voyage(' + this.cpS301.voyageNo + ') 있는 경우')
                          item.bkgClose = 'A'
                        } else if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                          item.bkgClose = 'A'
                        }
                      // 2개 있을 경우
                      } else if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                        if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel) {
                          console.log('#### [S301] Route(' + this.cpS301.route + '), Vessel(' + this.cpS301.vessel + ') 있는 경우')
                          item.bkgClose = 'A'
                        } else if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                          item.bkgClose = 'A'
                        }
                      } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                        if (item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo) {
                          console.log('#### [S301] Vessel(' + this.cpS301.vessel + '), Voyage(' + this.cpS301.voyageNo + ') 있는 경우')
                          item.bkgClose = 'A'
                        } else if (item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                          item.bkgClose = 'A'
                        }
                      } else if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                        if (item.rteCd === this.cpS301.route && item.voyNo === this.cpS301.voyageNo) {
                          console.log('#### [S301] Route(' + this.cpS301.route + '), Voyage(' + this.cpS301.voyageNo + ') 있는 경우')
                          item.bkgClose = 'A'
                        } else if (item.rteCd === this.cpS301.route && item.voyNo === this.cpS301.voyageNo &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                          item.bkgClose = 'A'
                        }
                      // 1개 있을 경우
                      } else if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                        if (item.rteCd === this.cpS301.route) {
                          item.bkgClose = 'A'
                        } else if (item.rteCd === this.cpS301.route &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                          item.bkgClose = 'A'
                        }
                      } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                        if (item.vslCd === this.cpS301.vessel) {
                          item.bkgClose = 'A'
                        } else if (item.vslCd === this.cpS301.vessel &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                          item.bkgClose = 'A'
                        }
                      } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                        if (item.voyNo === this.cpS301.voyageNo) {
                          item.bkgClose = 'A'
                        } else if (item.voyNo === this.cpS301.voyageNo &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                          item.bkgClose = 'A'
                        }
                      // 없을 경우
                      } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                        if (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3) {
                          item.bkgClose = 'A'
                        }
                      }
                      // if (item.voyNo === this.cpS301.voyageNo || item.vslCd === this.cpS301.vessel || item.rteCd === this.cpS301.route ||
                      //     item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3) {
                      //   item.bkgClose = 'A'
                      // }
                    }
                  }
                }
                idx++
              }
            } else if (this.$ekmtcCommon.isEmpty(this.cpS301.periodFr) && !this.$ekmtcCommon.isEmpty(this.cpS301.periodTo)) {
              // 종료일자만 있는 경우
              for (const item of this.listSchedule) {
                if (this.$ekmtcCommon.isNotEmpty(item.bkgDocCls) && Number(serverDt) > Number(item.bkgDocCls)) {
                  continue
                }
                if (item.polEtb <= this.cpS301.periodTo) {
                  //console.log('#### [S301] 입항일자 > 종료일자만 있는 경우')
                  if (onlyDate) {
                    item.bkgClose = 'A'
                  } else {
                    // 3개 있을 경우
                    if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo) {
                        console.log('#### [S301] Route(' + this.cpS301.route + '), Vessel(' + this.cpS301.vessel + '), Voyage(' + this.cpS301.voyageNo + ') 있는 경우')
                        item.bkgClose = 'A'
                      } else if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo &&
                      (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                        item.bkgClose = 'A'
                      }
                    // 2개 있을 경우
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel) {
                        console.log('#### [S301] Route(' + this.cpS301.route + '), Vessel(' + this.cpS301.vessel + ') 있는 경우')
                        item.bkgClose = 'A'
                      } else if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel &&
                      (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                        item.bkgClose = 'A'
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo) {
                        console.log('#### [S301] Vessel(' + this.cpS301.vessel + '), Voyage(' + this.cpS301.voyageNo + ') 있는 경우')
                        item.bkgClose = 'A'
                      } else if (item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo &&
                      (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                        item.bkgClose = 'A'
                      }
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.rteCd === this.cpS301.route && item.voyNo === this.cpS301.voyageNo) {
                        console.log('#### [S301] Route(' + this.cpS301.route + '), Voyage(' + this.cpS301.voyageNo + ') 있는 경우')
                        item.bkgClose = 'A'
                      } else if (item.rteCd === this.cpS301.route && item.voyNo === this.cpS301.voyageNo &&
                      (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                        item.bkgClose = 'A'
                      }
                    // 1개 있을 경우
                    } else if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.rteCd === this.cpS301.route) {
                        item.bkgClose = 'A'
                      } else if (item.rteCd === this.cpS301.route &&
                      (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                        item.bkgClose = 'A'
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.vslCd === this.cpS301.vessel) {
                        item.bkgClose = 'A'
                      } else if (item.vslCd === this.cpS301.vessel &&
                      (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                        item.bkgClose = 'A'
                      }
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.voyNo === this.cpS301.voyageNo) {
                        item.bkgClose = 'A'
                      } else if (item.voyNo === this.cpS301.voyageNo &&
                      (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                        item.bkgClose = 'A'
                      }
                    // 없을 경우
                    } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                      if (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3) {
                        item.bkgClose = 'A'
                      }
                    }
                    // if (item.voyNo === this.cpS301.voyageNo || item.vslCd === this.cpS301.vessel || item.rteCd === this.cpS301.route ||
                    //     item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3) {
                    //   item.bkgClose = 'A'
                    // }
                  }
                }
                idx++
              }
            } else {
              // 둘 다 없는 경우는 없겠지?!
              //console.log('#### [S301] 입항일자 > 둘 다 없는 경우')
              for (const item of this.listSchedule) {
                if (this.$ekmtcCommon.isNotEmpty(item.bkgDocCls) && Number(serverDt) > Number(item.bkgDocCls)) {
                  continue
                }
                if (item.voyNo === this.cpS301.voyageNo || item.vslCd === this.cpS301.vessel || item.rteCd === this.cpS301.route ||
                    item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3) {
                  item.bkgClose = 'A'
                }
                idx++
              }
            }
          } else {
            // 출항일자 > etd
            if (!this.$ekmtcCommon.isEmpty(this.cpS301.periodFr) && !this.$ekmtcCommon.isEmpty(this.cpS301.periodTo)) {
              // 시작일자, 종료일자 있는 경우
              for (const item of this.listSchedule) {
                if (this.$ekmtcCommon.isNotEmpty(item.bkgDocCls) && Number(serverDt) > Number(item.bkgDocCls)) {
                  continue
                }
                if (item.closeInfoVO.bkgClosed === 'CLOSED') {
                  if (this.cpS301.periodFr <= item.etd && item.etd <= this.cpS301.periodTo) {
                    //console.log('#### [S301] 출항일자 > 시작일자, 종료일자 있는 경우')
                    if (onlyDate) {
                      // WEB 마감
                      item.bkgClose = 'A'
                    } else {
                      // 3개 있을 경우
                      if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                        if (routeArr.length > 0) {
                          for (let j = 0; j < routeArr.length; j++) {
                            // 3개 있을 경우
                            if (!this.$ekmtcCommon.isEmpty(routeArr[j]) && !this.$ekmtcCommon.isEmpty(vesselArr[j]) && !this.$ekmtcCommon.isEmpty(voyageNoArr[j])) {
                              if (item.rteCd + item.vslCd + item.voyNo === routeArr[j] + vesselArr[j] + voyageNoArr[j]) {
                                item.bkgClose = 'A'
                              }
                            // 2개 있을 경우
                            } else if (!this.$ekmtcCommon.isEmpty(routeArr[j]) && !this.$ekmtcCommon.isEmpty(vesselArr[j]) && this.$ekmtcCommon.isEmpty(voyageNoArr[j])) {
                              if (item.rteCd + item.vslCd === routeArr[j] + vesselArr[j]) {
                                item.bkgClose = 'A'
                              }
                            } else if (!this.$ekmtcCommon.isEmpty(routeArr[j]) && this.$ekmtcCommon.isEmpty(vesselArr[j]) && !this.$ekmtcCommon.isEmpty(voyageNoArr[j])) {
                              if (item.rteCd + item.voyNo === routeArr[j] + voyageNoArr[j]) {
                                item.bkgClose = 'A'
                              }
                            } else if (this.$ekmtcCommon.isEmpty(routeArr[j]) && !this.$ekmtcCommon.isEmpty(vesselArr[j]) && !this.$ekmtcCommon.isEmpty(voyageNoArr[j])) {
                              if (item.vslCd + item.voyNo === vesselArr[j] + voyageNoArr[j]) {
                                item.bkgClose = 'A'
                              }
                            // 1개 있을 겨우
                            } else if (!this.$ekmtcCommon.isEmpty(routeArr[j]) && this.$ekmtcCommon.isEmpty(vesselArr[j]) && this.$ekmtcCommon.isEmpty(voyageNoArr[j])) {
                              if (item.rteCd === routeArr[j]) {
                                item.bkgClose = 'A'
                              }
                            } else if (this.$ekmtcCommon.isEmpty(routeArr[j]) && !this.$ekmtcCommon.isEmpty(vesselArr[j]) && this.$ekmtcCommon.isEmpty(voyageNoArr[j])) {
                              if (item.vslCd === vesselArr[j]) {
                                item.bkgClose = 'A'
                              }
                            } else if (this.$ekmtcCommon.isEmpty(routeArr[j]) && this.$ekmtcCommon.isEmpty(vesselArr[j]) && !this.$ekmtcCommon.isEmpty(voyageNoArr[j])) {
                              if (item.voyNo === voyageNoArr[j]) {
                                item.bkgClose = 'A'
                              }
                            }
                          }
                        } else {
                          if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo) {
                            console.log('#### [S301] Route(' + this.cpS301.route + '), Vessel(' + this.cpS301.vessel + '), Voyage(' + this.cpS301.voyageNo + ') 있는 경우')
                            item.bkgClose = 'A'
                          } else if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo &&
                          (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                            item.bkgClose = 'A'
                          }
                        }
                      // 2개 있을 경우
                      } else if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                        if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel) {
                          console.log('#### [S301] Route(' + this.cpS301.route + '), Vessel(' + this.cpS301.vessel + ') 있는 경우')
                          item.bkgClose = 'A'
                        } else if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                          item.bkgClose = 'A'
                        }
                      } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                        if (item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo) {
                          console.log('#### [S301] Vessel(' + this.cpS301.vessel + '), Voyage(' + this.cpS301.voyageNo + ') 있는 경우')
                          item.bkgClose = 'A'
                        } else if (item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                          item.bkgClose = 'A'
                        }
                      } else if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                        if (item.rteCd === this.cpS301.route && item.voyNo === this.cpS301.voyageNo) {
                          console.log('#### [S301] Route(' + this.cpS301.route + '), Voyage(' + this.cpS301.voyageNo + ') 있는 경우')
                          item.bkgClose = 'A'
                        } else if (item.rteCd === this.cpS301.route && item.voyNo === this.cpS301.voyageNo &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                          item.bkgClose = 'A'
                        }
                      // 1개 있을 경우
                      } else if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                        if (item.rteCd === this.cpS301.route) {
                          item.bkgClose = 'A'
                        } else if (item.rteCd === this.cpS301.route &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                          item.bkgClose = 'A'
                        }
                      } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                        if (item.vslCd === this.cpS301.vessel) {
                          item.bkgClose = 'A'
                        } else if (item.vslCd === this.cpS301.vessel &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                          item.bkgClose = 'A'
                        }
                      } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                        if (item.voyNo === this.cpS301.voyageNo) {
                          item.bkgClose = 'A'
                        } else if (item.voyNo === this.cpS301.voyageNo &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                          item.bkgClose = 'A'
                        }
                      // 없을 경우
                      } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                        if (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3) {
                          item.bkgClose = 'A'
                        }
                      }
                      // if (item.voyNo === this.cpS301.voyageNo || item.vslCd === this.cpS301.vessel || item.rteCd === this.cpS301.route ||
                      //     item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3) {
                      //   item.bkgClose = 'A'
                      // }
                    }
                  }
                }
                idx++
              }
            } else if (!this.$ekmtcCommon.isEmpty(this.cpS301.periodFr) && this.$ekmtcCommon.isEmpty(this.cpS301.periodTo)) {
              // 시작일자만 있는 경우
              for (const item of this.listSchedule) {
                if (this.$ekmtcCommon.isNotEmpty(item.bkgDocCls) && Number(serverDt) > Number(item.bkgDocCls)) {
                  continue
                }
                if (item.closeInfoVO.bkgClosed === 'CLOSED') {
                  if (this.cpS301.periodFr <= item.etd) {
                    //console.log('#### [S301] 출항일자 > 시작일자만 있는 경우')
                    if (onlyDate) {
                      item.bkgClose = 'A'
                    } else {
                      // 3개 있을 경우
                      if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                        if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo) {
                          console.log('#### [S301] Route(' + this.cpS301.route + '), Vessel(' + this.cpS301.vessel + '), Voyage(' + this.cpS301.voyageNo + ') 있는 경우')
                          item.bkgClose = 'A'
                        } else if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                          item.bkgClose = 'A'
                        }
                      // 2개 있을 경우
                      } else if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                        if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel) {
                          console.log('#### [S301] Route(' + this.cpS301.route + '), Vessel(' + this.cpS301.vessel + ') 있는 경우')
                          item.bkgClose = 'A'
                        } else if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                          item.bkgClose = 'A'
                        }
                      } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                        if (item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo) {
                          console.log('#### [S301] Vessel(' + this.cpS301.vessel + '), Voyage(' + this.cpS301.voyageNo + ') 있는 경우')
                          item.bkgClose = 'A'
                        } else if (item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                          item.bkgClose = 'A'
                        }
                      } else if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                        if (item.rteCd === this.cpS301.route && item.voyNo === this.cpS301.voyageNo) {
                          console.log('#### [S301] Route(' + this.cpS301.route + '), Voyage(' + this.cpS301.voyageNo + ') 있는 경우')
                          item.bkgClose = 'A'
                        } else if (item.rteCd === this.cpS301.route && item.voyNo === this.cpS301.voyageNo &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                          item.bkgClose = 'A'
                        }
                      // 1개 있을 경우
                      } else if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                        if (item.rteCd === this.cpS301.route) {
                          item.bkgClose = 'A'
                        } else if (item.rteCd === this.cpS301.route &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                          item.bkgClose = 'A'
                        }
                      } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                        if (item.vslCd === this.cpS301.vessel) {
                          item.bkgClose = 'A'
                        } else if (item.vslCd === this.cpS301.vessel &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                          item.bkgClose = 'A'
                        }
                      } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                        if (item.voyNo === this.cpS301.voyageNo) {
                          item.bkgClose = 'A'
                        } else if (item.voyNo === this.cpS301.voyageNo &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                          item.bkgClose = 'A'
                        }
                      // 없을 경우
                      } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                        if (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3) {
                          item.bkgClose = 'A'
                        }
                      }
                      // if (item.voyNo === this.cpS301.voyageNo || item.vslCd === this.cpS301.vessel || item.rteCd === this.cpS301.route ||
                      //     item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3) {
                      //   item.bkgClose = 'A'
                      // }
                    }
                  }
                }
                idx++
              }
            } else if (this.$ekmtcCommon.isEmpty(this.cpS301.periodFr) && !this.$ekmtcCommon.isEmpty(this.cpS301.periodTo)) {
              // 종료일자만 있는 경우
              for (const item of this.listSchedule) {
                if (this.$ekmtcCommon.isNotEmpty(item.bkgDocCls) && Number(serverDt) > Number(item.bkgDocCls)) {
                  continue
                }
                if (item.closeInfoVO.bkgClosed === 'CLOSED') {
                  if (item.etd <= this.cpS301.periodTo) {
                    //console.log('#### [S301] 출항일자 > 종료일자만 있는 경우')
                    if (onlyDate) {
                      item.bkgClose = 'A'
                    } else {
                      // 3개 있을 경우
                      if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                        if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo) {
                          console.log('#### [S301] Route(' + this.cpS301.route + '), Vessel(' + this.cpS301.vessel + '), Voyage(' + this.cpS301.voyageNo + ') 있는 경우')
                          item.bkgClose = 'A'
                        } else if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                          item.bkgClose = 'A'
                        }
                      // 2개 있을 경우
                      } else if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                        if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel) {
                          console.log('#### [S301] Route(' + this.cpS301.route + '), Vessel(' + this.cpS301.vessel + ') 있는 경우')
                          item.bkgClose = 'A'
                        } else if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                          item.bkgClose = 'A'
                        }
                      } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                        if (item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo) {
                          console.log('#### [S301] Vessel(' + this.cpS301.vessel + '), Voyage(' + this.cpS301.voyageNo + ') 있는 경우')
                          item.bkgClose = 'A'
                        } else if (item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                          item.bkgClose = 'A'
                        }
                      } else if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                        if (item.rteCd === this.cpS301.route && item.voyNo === this.cpS301.voyageNo) {
                          console.log('#### [S301] Route(' + this.cpS301.route + '), Voyage(' + this.cpS301.voyageNo + ') 있는 경우')
                          item.bkgClose = 'A'
                        } else if (item.rteCd === this.cpS301.route && item.voyNo === this.cpS301.voyageNo &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                          item.bkgClose = 'A'
                        }
                      // 1개 있을 경우
                      } else if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                        if (item.rteCd === this.cpS301.route) {
                          item.bkgClose = 'A'
                        } else if (item.rteCd === this.cpS301.route &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                          item.bkgClose = 'A'
                        }
                      } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                        if (item.vslCd === this.cpS301.vessel) {
                          item.bkgClose = 'A'
                        } else if (item.vslCd === this.cpS301.vessel &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                          item.bkgClose = 'A'
                        }
                      } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                        if (item.voyNo === this.cpS301.voyageNo) {
                          item.bkgClose = 'A'
                        } else if (item.voyNo === this.cpS301.voyageNo &&
                        (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                          item.bkgClose = 'A'
                        }
                      // 없을 경우
                      } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
                        if (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3) {
                          item.bkgClose = 'A'
                        }
                      }
                      // if (item.voyNo === this.cpS301.voyageNo || item.vslCd === this.cpS301.vessel || item.rteCd === this.cpS301.route ||
                      //     item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3) {
                      //   item.bkgClose = 'A'
                      // }
                    }
                  }
                }
                idx++
              }
            } else {
              // 둘 다 없는 경우는 없겠지?!
              //console.log('#### [S301] 출항일자 > 둘 다 없는 경우')
              for (const item of this.listSchedule) {
                if (this.$ekmtcCommon.isNotEmpty(item.bkgDocCls) && Number(serverDt) > Number(item.bkgDocCls)) {
                  continue
                }
                if (item.closeInfoVO.bkgClosed === 'CLOSED') {
                  if (item.voyNo === this.cpS301.voyageNo || item.vslCd === this.cpS301.vessel || item.rteCd === this.cpS301.route ||
                      item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3) {
                    item.bkgClose = 'A'
                  }
                }
                idx++
              }
            }
          }
        } else {
          // No 적용기준 > 시작, 종료일도 없음
          //console.log('No 적용기준')
          for (const item of this.listSchedule) {
            if (this.$ekmtcCommon.isNotEmpty(item.bkgDocCls) && Number(serverDt) > Number(item.bkgDocCls)) {
              continue
            }
            // 3개 있을 경우
            if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
              if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo) {
                console.log('#### [S301] Route(' + this.cpS301.route + '), Vessel(' + this.cpS301.vessel + '), Voyage(' + this.cpS301.voyageNo + ') 있는 경우')
                item.bkgClose = 'A'
              } else if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo &&
              (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                item.bkgClose = 'A'
              }
            // 2개 있을 경우
            } else if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
              if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel) {
                console.log('#### [S301] Route(' + this.cpS301.route + '), Vessel(' + this.cpS301.vessel + ') 있는 경우')
                item.bkgClose = 'A'
              } else if (item.rteCd === this.cpS301.route && item.vslCd === this.cpS301.vessel &&
              (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                item.bkgClose = 'A'
              }
            } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
              if (item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo) {
                console.log('#### [S301] Vessel(' + this.cpS301.vessel + '), Voyage(' + this.cpS301.voyageNo + ') 있는 경우')
                item.bkgClose = 'A'
              } else if (item.vslCd === this.cpS301.vessel && item.voyNo === this.cpS301.voyageNo &&
              (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                item.bkgClose = 'A'
              }
            } else if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
              if (item.rteCd === this.cpS301.route && item.voyNo === this.cpS301.voyageNo) {
                console.log('#### [S301] Route(' + this.cpS301.route + '), Voyage(' + this.cpS301.voyageNo + ') 있는 경우')
                item.bkgClose = 'A'
              } else if (item.rteCd === this.cpS301.route && item.voyNo === this.cpS301.voyageNo &&
              (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                item.bkgClose = 'A'
              }
            // 1개 있을 경우
            } else if (!this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
              if (item.rteCd === this.cpS301.route) {
                item.bkgClose = 'A'
              } else if (item.rteCd === this.cpS301.route &&
              (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                item.bkgClose = 'A'
              }
            } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && !this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
              if (item.vslCd === this.cpS301.vessel) {
                item.bkgClose = 'A'
              } else if (item.vslCd === this.cpS301.vessel &&
              (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                item.bkgClose = 'A'
              }
            } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && !this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
              if (item.voyNo === this.cpS301.voyageNo) {
                item.bkgClose = 'A'
              } else if (item.voyNo === this.cpS301.voyageNo &&
              (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3)) {
                item.bkgClose = 'A'
              }
            // 없을 경우
            } else if (this.$ekmtcCommon.isEmpty(this.cpS301.route) && this.$ekmtcCommon.isEmpty(this.cpS301.vessel) && this.$ekmtcCommon.isEmpty(this.cpS301.voyageNo)) {
              if (item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3) {
                item.bkgClose = 'A'
              }
            }
            // if (item.voyNo === this.cpS301.voyageNo || item.vslCd === this.cpS301.vessel || item.rteCd === this.cpS301.route ||
            //     item.pol2 === this.cpS301.ts1 || item.pol3 === this.cpS301.ts2 || item.pol4 === this.cpS301.ts3) {
            //   item.bkgClose = 'A'
            // }
            idx++
          }
        }
      }
    },
    async popFreAppNoCheck (logYn) {
      // this.eiCatCd = (this.bkgParams.podCtrCd === 'KR') ? 'I' : 'O'
      // if (this.oengOnlYn === 'Y' && this.userCtrCd !== 'KR') {
      //   this.eiCatCd = 'O'
      // }
      // if ('TH|CN|HK|ID|IN'.indexOf(this.userCtrCd) > -1) {
      //   this.eiCatCd = 'O'
      // }
      // 220704. 출발지/도착지에 따라 수입/수출 구분을 넣어준다.
      this.eiCatCd = this.getUsrCtrEiCatCd(this.scheduleForm.startCtrCd, this.scheduleForm.destCtrCd) // 수입/수출 구분코드
      const params = {
        porCtrCd: this.scheduleForm.startCtrCd,
        porPlcCd: this.scheduleForm.startPlcCd,
        dlyCtrCd: this.scheduleForm.destCtrCd,
        dlyPlcCd: this.scheduleForm.destPlcCd,
        eiCatCd: this.eiCatCd,
        logYn: logYn,
        etd: this.detailResp2.etd, //this.yyyymmdd.replaceAll('.', '')
        promotionChk: this.parentInfo.promotionChk,
        vslCd: this.bkgParams.vslCd,
        voyNo: this.bkgParams.voyNo,
        rteCd: this.detailResp2.rteCd,
        hotDealYn: 'N',
        hotDealReqRno: '',
        raTsParam: this.raTsParam
      }
      await schedules.popFreAppNo(params).then((data) => {
        // console.log('@@@@@ popFreAppNoCheck Return Data @@@@@')
        // console.log(data)
        this.frtResult = data.data.frtResult
        this.freAppData = data.data

        this.detailResp2.frtResult = this.freAppData.frtResult
        this.frtResult = this.freAppData.frtResult
        this.detailResp2.strDt = this.freAppData.strDt
        this.detailResp2.endDt = this.freAppData.endDt
        this.detailResp2.frtAppNo = this.freAppData.frtAppNo
        this.detailResp2.frtEstnYn = this.freAppData.frtEstnYn
        this.detailResp2.reqRno = this.freAppData.reqRno
        this.detailResp2.hotDealYn = this.freAppData.hotDealYn
        this.detailResp2.refFrtAppNo = this.freAppData.refFrtAppNo

        this.rmPopOpenFlag = false

        const oonlYn = this.memberDetail.oonlYn // 수출영업(Export) 기준으로 온라인 견적 대상 화주인 경우
        const jonlYn = this.memberDetail.jonlYn // 일본영업 기준으로 온라인 견적 대상 화주인 경우
        const ionlYn = this.memberDetail.ionlYn // 수입영업(Import) 기준으로 온라인 견적 대상 화주인 경우
        const oengOnlYn = this.memberDetail.oengOnlYn
        const userCtrCd = this.memberDetail.userCtrCd
        if (oonlYn === 'Y' && jonlYn === 'Y') {
          if (this.scheduleForm.startCtrCd === 'KR') {
           this.rmPopOpenFlag = true
          }
          if (this.scheduleForm.startCtrCd === 'KR' && this.scheduleForm.destCtrCd === 'JP') {
           this.rmPopOpenFlag = true
          }
          if (this.scheduleForm.startCtrCd === 'JP' && this.scheduleForm.destCtrCd === 'KR') {
           this.rmPopOpenFlag = true
          }
        } else {
          if (oonlYn === 'Y') {
            if (this.scheduleForm.startCtrCd === 'KR' && this.scheduleForm.destCtrCd !== 'JP') {
              this.rmPopOpenFlag = true
            }
          } else if (jonlYn === 'Y') {
            if (this.scheduleForm.startCtrCd === 'KR' && this.scheduleForm.destCtrCd === 'JP') {
              this.rmPopOpenFlag = true
            }
            if (this.scheduleForm.startCtrCd === 'JP' && this.scheduleForm.destCtrCd === 'KR') {
              this.rmPopOpenFlag = true
            }
          }
        }
        if (ionlYn === 'Y') {
          if (this.scheduleForm.startCtrCd !== 'JP' && this.scheduleForm.destCtrCd === 'KR') {
            this.rmPopOpenFlag = true
          }
        }
        if (oengOnlYn === 'Y' && userCtrCd !== 'KR') {
          this.rmPopOpenFlag = true
        }
      })
    },
    filterSearch () {
      this.filterYn = 'Y'
      this.polTrmlStr = ''
      const TH = this
      if (this.filterPolTrmlCd.length === 1 && this.filterPolTrmlCd[0] !== '') {
        this.finalPolTrmlCd = this.filterPolTrmlCd[0]
      } else if (this.filterPolTrmlCd.length === 0) {
        TH.$ekmtcCommon.alertCallback(TH.$t('msg.VOSD100_M5.109'), function () {
          setTimeout(() => {
            TH.filterPolTrmlCd.push(TH.finalPolTrmlCd)
          }, 50)
        })
        return
      }
      if (this.filterPodTrmlCd.length === 1 && this.filterPodTrmlCd[0] !== '') {
        this.finalPodTrmlCd = this.filterPodTrmlCd[0]
      } else if (this.filterPodTrmlCd.length === 0) {
        TH.$ekmtcCommon.alertCallback(TH.$t('msg.VOSD100_M5.109'), function () {
          setTimeout(() => {
            TH.filterPodTrmlCd.push(TH.finalPodTrmlCd)
          }, 50)
        })
        return
      }
      if (this.filterRteCd.length === 1 && this.filterRteCd[0] !== '') {
        this.finalRteCd = this.filterRteCd[0]
      } else if (this.filterRteCd.length === 0) {
        TH.$ekmtcCommon.alertCallback(TH.$t('msg.VOSD100_M5.128'), function () {
          setTimeout(() => {
            TH.filterRteCd.push(TH.finalRteCd)
          }, 50)
        })
        return
      }
      for (let i = 0; i < this.filterPolTrmlCd.length; i++) {
        this.polTrmlStr += this.filterPolTrmlCd[i] + ','
      }
      this.polTrmlStr = this.polTrmlStr.substring(0, this.polTrmlStr.length - 1)
      this.podTrmlStr = ''
      for (let i = 0; i < this.filterPodTrmlCd.length; i++) {
        this.podTrmlStr += this.filterPodTrmlCd[i] + ','
      }
      this.podTrmlStr = this.podTrmlStr.substring(0, this.podTrmlStr.length - 1)
      this.rteCdStr = ''
      for (let i = 0; i < this.filterRteCd.length; i++) {
        this.rteCdStr += this.filterRteCd[i] + ','
      }
      this.rteCdStr = this.rteCdStr.substring(0, this.rteCdStr.length - 1)
      // this.getLegSchedule(this.searchYear, this.searchMonth, this.filterYn)
    },
    getCpS401 (vo) {
      const cpS401 = {
        actionCd: 'S401',
        etdDate: vo.etd,
        porPlc: vo.pol,
        ts1: vo.pol2,
        ts2: vo.pol3,
        ts3: vo.pol4,
        dlyPlc: vo.pod
      }
      cp.getCallActionHandler(cpS401).then((res) => {
        console.log('>>>>> CP / S401 <<<<<', res)
        this.vessel2ndButton = res.data.result
      })
    },
    // Schedule Detail
    async fnDetailLayer2 (vo) {
      // 220704. 출발지/도착지에 따라 수입/수출 구분을 넣어준다.
      this.eiCatCd = this.getUsrCtrEiCatCd(this.scheduleForm.startCtrCd, this.scheduleForm.destCtrCd) // 수입/수출 구분코드
      // 240208. KMTC PREMIUM 체크.ICC로 부터 받은 스케줄 정보의 프리미엄 구간 여부 세팅
      this.kmtcPremiumLineYn = vo.kmtcPremiumLineYn
      //중국 춘절 기간동안 홍콩 T/S가 중단 체크
      //춘절 완료 후 삭제 필요
      //SR 000001380718 [고객서비스팀] 춘절 HKG T/S 피더스케줄 제약 관련 요청
      let honghongTSJson = {
        FOC: { start: '20240121', end: '20240220' },
        HAK: { start: '20240128', end: '20240220' },
        YPG: { start: '20240128', end: '20240220' },
        BHY: { start: '20240129', end: '20240219' },
        FCN: { start: '20240129', end: '20240219' },
        QZH: { start: '20240129', end: '20240219' },
        WUZ: { start: '20240130', end: '20240219' },
        GGL: { start: '20240130', end: '20240219' },
        BCN: { start: '20240131', end: '20240219' },
        BJO: { start: '20240131', end: '20240219' },
        DOU: { start: '20240131', end: '20240219' },
        LUU: { start: '20240131', end: '20240219' },
        ROQ: { start: '20240131', end: '20240219' },
        SBU: { start: '20240131', end: '20240219' },
        SDN: { start: '20240131', end: '20240219' },
        XIY: { start: '20240131', end: '20240219' },
        ZSN: { start: '20240131', end: '20240219' },
        FQG: { start: '20240201', end: '20240224' },
        QZJ: { start: '20240201', end: '20240224' },
        ZHA: { start: '20240201', end: '20240224' },
        GOM: { start: '20240202', end: '20240220' },
        HWN: { start: '20240202', end: '20240220' },
        HUD: { start: '20240202', end: '20240220' },
        HUA: { start: '20240202', end: '20240220' },
        JMN: { start: '20240202', end: '20240220' },
        JAX: { start: '20240202', end: '20240220' },
        JIU: { start: '20240202', end: '20240220' },
        LIH: { start: '20240202', end: '20240220' },
        MFG: { start: '20240202', end: '20240220' },
        NNS: { start: '20240202', end: '20240220' },
        SNR: { start: '20240202', end: '20240220' },
        SSN: { start: '20240202', end: '20240220' },
        SJQ: { start: '20240202', end: '20240220' },
        SWA: { start: '20240202', end: '20240220' },
        STN: { start: '20240202', end: '20240220' },
        TPN: { start: '20240202', end: '20240220' },
        XON: { start: '20240202', end: '20240220' },
        XIN: { start: '20240202', end: '20240220' },
        ZQG: { start: '20240202', end: '20240220' },
        YTN: { start: '20240206', end: '20240214' }
      }

      if (this.scheduleForm.startCtrCd === 'KR') {
          if (vo.vslCd2 === 'FDR' && vo.pol2 === 'HKG' && vo.pod != null) {
            let row = honghongTSJson[vo.pod]
            if (row != null) {
              if (row.start < vo.podEtd1 && row.end > vo.podEtd1) {
                  this.hongkongTsYn = 'Y'
                  this.hongkongTsMsg = '중국 춘절 기간동안 홍콩 T/S가 중단될 예정입니다.\n'
                  this.hongkongTsMsg += this.$ekmtcCommon.changeDatePattern(row.start) + '~' + this.$ekmtcCommon.changeDatePattern(row.end) + ' 기간내 홍콩 도착하는 스케쥴의 경우 진행이 불가하오니 참고 부탁드립니다.'
              } else {
                this.hongkongTsYn = ''
                this.hongkongTsMsg = ''
              }
            } else {
              this.hongkongTsYn = ''
              this.hongkongTsMsg = ''
            }
          } else {
            this.hongkongTsYn = ''
            this.hongkongTsMsg = ''
          }
      } else {
        this.hongkongTsYn = ''
        this.hongkongTsMsg = ''
      }

      // console.log(vo)
      // CP S401
      if (vo.ts === 'Y') {
        this.getCpS401(vo)
      }
      this.show2ndVessel = 'N'
      let longParam = vo.ts + ';' +
                      vo.vslNm + ';' +
                      vo.vslCd + ';' +
                      vo.voyNo + ';' +
                      vo.transitTime + ';' +
                      vo.polEtb + ';' +
                      vo.polEtbTm + ';' +
                      vo.etd + ';' +
                      vo.etdTm + ';' +
                      vo.eta + ';' +
                      vo.etaTm + ';' +
                      vo.polTml + ';' +
                      vo.podTml + ';' +
                      vo.polPicTel + ';' +
                      vo.itrmlNm + ';' +
                      vo.podPicTel + ';' +
                      vo.closeTime + ';' +
                      vo.cct + ';' +
                      vo.mrnNo + ';' +
                      vo.callSign + ';' +
                      vo.bkgClose + ';' +
                      vo.mfCls + ';' +
                      vo.webCls + ';' +
                      vo.cgoCls + ';' +
                      vo.jrstCstm + ';' +
                      vo.info + ';' +
                      vo.pol + ';' +
                      vo.pod + ';' +
                      vo.bkgDocCls + ';' +
                      vo.bkgCgoCls + ';' +
                      vo.bkgMfCls + ';' +
                      vo.cfsCls + ';' +
                      vo.pod1 + ';' +
                      vo.podEtd1 + ';' +
                      vo.podTrml1 + ';' +
                      vo.podPicTel1 + ';' +
                      vo.etaTm1 + ';' +
                      vo.transitTime1 + ';' +
                      vo.pol1 + ';' +
                      vo.vslNm2 + ';' +
                      vo.vslCd2 + ';' +
                      vo.voyNo2 + ';' +
                      vo.polEtd2 + ';' +
                      vo.podEtd2 + ';' +
                      vo.polTrml2 + ';' +
                      vo.podTrml2 + ';' +
                      vo.polPicNm2 + ';' +
                      vo.polPicTel2 + ';' +
                      vo.podPicTel2 + ';' +
                      vo.etdTm2 + ';' +
                      vo.etaTm2 + ';' +
                      vo.transitTime2 + ';' +
                      vo.tsDegree + ';' +
                      vo.vslNm3 + ';' +
                      vo.vslCd3 + ';' +
                      vo.voyNo3 + ';' +
                      vo.polEtd3 + ';' +
                      vo.etdTm3 + ';' +
                      vo.polTrml3 + ';' +
                      vo.polPicTel3 + ';' +
                      vo.podEtd3 + ';' +
                      vo.etaTm3 + ';' +
                      vo.podTrml3 + ';' +
                      vo.podPicTel3 + ';' +
                      vo.transitTime3 + ';' +
                      vo.vslNm4 + ';' +
                      vo.vslCd4 + ';' +
                      vo.voyNo4 + ';' +
                      vo.polEtd4 + ';' +
                      vo.etdTm4 + ';' +
                      vo.polTrml4 + ';' +
                      vo.polPicTel4 + ';' +
                      vo.podEtd4 + ';' +
                      vo.etaTm4 + ';' +
                      vo.podTrml4 + ';' +
                      vo.podPicTel4 + ';' +
                      vo.transitTime4 + ';' +
                      vo.polTrml1 + ';' +
                      vo.pol2 + ';' +
                      vo.pod3 + ';' +
                      vo.pod4 + ';' +
                      vo.pod2 + ';' +
                      vo.bkgDocPic + ';' +
                      vo.bkgCgoPic + ';' +
                      vo.vslNm1 + ';' +
                      vo.pol3 + ';' +
                      vo.pol4 + ';' +
                      vo.bkgCfsPic + ';' +
                      vo.bkgVslCd + ';' +
                      vo.bkgVoyNo + ';' +
                      vo.polCtrCd + ';' +
                      vo.rteCd
      const params = {
        param: longParam,
        bound: 'O',
        rfYn: vo.rfYn
      }

      await schedules.getLegScheduleDetail(params).then((rtnData) => {
        // 조회 결과 없으면 리턴. 안할경우 달력 prev, next 버튼 오동작함.
        // if (rtnData.length === undefined) {
        //   return
        // }

        if (this.calendarOrList === 'C') {
          this.bkgScheduleData = vo
          // this.bkgDetailData = rtnData.data
          this.fnDetailLayerResponse2(rtnData, vo)
          this.insertWB242H('02', this.logPgmCatCd, vo)
        } else {
          this.bkgScheduleData = vo
        }
      })

      if (this.bound === 'O') {
        // 마감정보
        const vesselParams = {
          vslCd: vo.vslCd,
          voyNo: vo.voyNo,
          polPortCd: vo.pol,
          podPortCd: vo.pod,
          bound: this.bound,
          polTrmlCd: vo.polTml
        }
        const TH = this
        await vslScheduleInfo.getVslScheduleInfo(vesselParams).then(response => {
          // console.log('>>>>> getVslScheduleInfo <<<<<')
          // console.log(response)
          // 선박 상세정보 세팅
          TH.vesselInfoDetailSch = response.data.vesselInfoDetail
          console.log(this.vesselInfoDetailSch)
          setTimeout(() => {
            TH.$refs.CloseInfo.setVesselInfo()
          }, 1000)
        })
      }
      // 프로모션 배너 선택시 운임확인 버튼 호출
      if (this.parentInfo.promotionChk === 'Y') {
        await this.popFreAppNoCheck('Y')
        this.openFreSurchargePopup()
      }
    },
    fnDetailLayerResponse2 (rtnData, vo) {
       console.log('>>>>> fnDetailLayerResponse2 <<<<<')
       console.log(vo)
       console.log(rtnData)
      const defaults = {
        tsDegree: '',
        vslNm1: '',
        voyNo: '',
        rteCd: '',
        polNm: '',
        podNm: '',
        pod1Nm: '',
        transitTime: '',
        transitTime1: '',
        polEtbDT: '',
        etdDT: '',
        polTml: '',
        polTmlCd: '',
        etaDT: '',
        podTml: '',
        vslNm2: '', // 2nd
        voyNo2: '',
        rteCd2: '',
        pod2Nm: '',
        transitTime2: '',
        polEtbDT2: '',
        polTml2: '',
        etaDT2: '',
        podTml2: '',
        vslNm3: '', // 3rd
        voyNo3: '',
        rteCd3: '',
        pod3Nm: '',
        transitTime3: '',
        polEtbDT3: '',
        polTml3: '',
        etaDT3: '',
        podTml3: '',
        vslNm4: '', // 4th
        voyNo4: '',
        rteCd4: '',
        pod4Nm: '',
        transitTime4: '',
        polEtbDT4: '',
        polTml4: '',
        etaDT4: '',
        podTml4: '',
        bkgDocCls: '',
        bkgCgoCls: '',
        bkgMfCls: '',
        cfsCls: '',
        mrnNo: '',
        apoTcnt: '',
        callSign: '',
        jrstCstm: '',
        ts: '',
        vslCd: '',
        pol: '',
        pod: '',
        bkgClose: '',
        dtBkgYn: '',
        bkgVslCd: '',
        bkgVoyNo: '',
        kmtcSpotLineYn: 'N', // KMTC SPOT 구간 여부
        kmtcSpotUserYn: 'N', // KMTC SPOT 화주 여부
        kmtcSpotClosYn: 'Y' // KMTC SPOT 마감 여부
      }

      this.detailResp2 = this._.cloneDeep(defaults)

      var tsDegree = vo.tsDegree

      // KMTC ON 대상 화주, 대상 구간 세팅이며, ICC 스케줄 조회 API 정보가 아닌
      // KMTC ON 화주 세팅, KMTC ON 구간 세팅 테이블을 직접 조회하여 세팅함
      // 비로그인인 경우 kmtcSpotInfo 배열이 없어 오류 발생함
      if (this.auth.userId) {
        this.detailResp2.kmtcSpotLineYn = rtnData.data.kmtcSpotInfo[0].kmtcSpotLineYn
        this.detailResp2.kmtcSpotUserYn = rtnData.data.kmtcSpotInfo[0].kmtcSpotUserYn
        this.detailResp2.kmtcSpotClosYn = rtnData.data.kmtcSpotInfo[0].kmtcSpotClosYn
        this.kmtcPremiumClosYn = rtnData.data.kmtcSpotInfo[0].kmtcSpotClosYn
      }

      this.detailResp2.bkgVslCd = vo.bkgVslCd
      this.detailResp2.bkgVoyNo = vo.bkgVoyNo
      this.detailResp2.tsDegree = vo.tsDegree
      this.raTsParam = ''
      this.detailResp2.ts = vo.ts
      this.detailResp2.vslCd = vo.vslCd
      this.detailResp2.pol = vo.pol
      this.detailResp2.pod = vo.pod
      this.detailResp2.etd = vo.etd
      this.detailResp2.etdTm = vo.etdTm
      this.detailResp2.eta = vo.eta
      this.detailResp2.etaTm = vo.etaTm
      this.detailResp2.vslNm = vo.vslNm
      this.detailResp2.bkgClose = vo.bkgClose

      if (rtnData.data.bkgMdlCloseYn === 'Y') {
        this.detailResp2.bkgClose = 'Y'
      }

      this.detailResp2.dtBkgYn = rtnData.data.dtBkgYn
      // this.detailResp2.closInfVar = rtnData.data.closInfVar
      // 1st Vessel
      this.detailResp2.vslNm1 = vo.vslNm1
      this.detailResp2.voyNo = vo.voyNo
      this.detailResp2.rteCd = rtnData.data.rteCd
      this.detailResp2.polNm = rtnData.data.polNm
      this.detailResp2.podNm = rtnData.data.podNm
      this.detailResp2.pod1Nm = rtnData.data.pod1Nm
      if (vo.transitTime.indexOf('Day') > -1) {
        let dayCountString = vo.transitTime.substring(0, vo.transitTime.indexOf('Day'))
        if (Number(dayCountString) > 1) {
          this.detailResp2.transitTime = vo.transitTime.replace(/Day/g, 'Days')
        } else {
          this.detailResp2.transitTime = vo.transitTime
        }
      } else {
        this.detailResp2.transitTime = vo.transitTime
      }

      if (vo.transitTime1.indexOf('Day') > -1) {
        let dayCountString = vo.transitTime1.substring(0, vo.transitTime1.indexOf('Day'))
        if (Number(dayCountString) > 1) {
          this.detailResp2.transitTime1 = vo.transitTime1.replace(/Day/g, 'Days')
        } else {
          this.detailResp2.transitTime1 = vo.transitTime1
        }
      } else {
        this.detailResp2.transitTime1 = vo.transitTime1
      }

      this.detailResp2.polEtbDT = this.changeDate2Display(vo.polEtb, vo.polEtbTm, this.lang)
      this.detailResp2.etdDT = this.changeDate2Display(vo.etd, vo.etdTm, this.lang)
      // CloseInfo에서 CloseYn(Arrival/Departure를 구하기 위해서) polEtb, polEtd를 추가.
      this.detailResp2.polEta = vo.polEtb + vo.polEtbTm // 선적항 Arrival 일시 (여긴 왜 etb일까??)
      this.detailResp2.polEtd = vo.etd + vo.etdTm // 선적항 Departure 일시
      this.detailResp2.polTml = this.fnTrmlText(vo.polTml, rtnData.data.polTmlNm)
      this.detailResp2.etaDT = this.changeDate2Display(vo.podEtd1, vo.etaTm1, this.lang)
      this.detailResp2.podTml = this.fnTrmlText(vo.podTrml1, rtnData.data.podTrml1Nm)
      this.detailResp2.polTmlCd = vo.polTml
      this.detailResp2.podTmlCd = vo.podTml
      this.detailResp2.polCtrCd = vo.polCtrCd
      this.detailResp2.podCtrCd = vo.podCtrCd
      if (vo.ts === 'Y') {
        // 2nd Vessel
        this.detailResp2.vslNm2 = vo.vslNm2
        this.detailResp2.vslCd2 = vo.vslCd2
        this.detailResp2.voyNo2 = vo.voyNo2
        this.detailResp2.rteCd2 = rtnData.data.rteCd2
        this.detailResp2.pod1Nm = rtnData.data.pod1Nm
        this.detailResp2.pod2Nm = rtnData.data.pod2Nm
        this.detailResp2.transitTime2 = vo.transitTime2
        this.detailResp2.transitTSTime2 = vo.transitTSTime2
        this.detailResp2.polEtbDT2 = this.changeDate2Display(vo.polEtd2, vo.etdTm2, this.lang)
        this.detailResp2.polTml2 = this.fnTrmlText(vo.polTrml2, rtnData.data.polTrml2Nm)
        this.detailResp2.polTmlCd2 = vo.polTrml2
        this.detailResp2.etaDT2 = this.changeDate2Display(vo.podEtd2, vo.etaTm2, this.lang)
        this.detailResp2.podTml2 = this.fnTrmlText(vo.podTrml2, rtnData.data.podTrml2Nm)
        this.detailResp2.podTmlCd2 = vo.podTrml2
        this.detailResp2.pol1 = vo.pol1
        this.detailResp2.pod1 = vo.pod1
        this.detailResp2.pol2 = vo.pol2
        this.detailResp2.pod2 = vo.pod2
        // 2nd Vessel
        this.scheduleDetailData.pol2nd = vo.pol
        this.scheduleDetailData.pod2nd = vo.pod
        this.scheduleDetailData.etd2nd = vo.etd
        // raTsParam
        this.raTsParam = '1;'
        this.raTsParam += vo.pol1 + ';'
        this.raTsParam += vo.pod1 + ';'
        this.raTsParam += vo.vslCd + ';'
        this.raTsParam += vo.voyNo + ';'
        this.raTsParam += vo.rteCd + ';'
        this.raTsParam += vo.etd + ';'
        this.raTsParam += '@'
        this.raTsParam += '2;'
        this.raTsParam += vo.pol2 + ';'
        this.raTsParam += vo.pod2 + ';'
        this.raTsParam += vo.vslCd2 + ';'
        this.raTsParam += vo.voyNo2 + ';'
        this.raTsParam += vo.rteCd2 + ';'
        this.raTsParam += vo.polEtd2 + ';'
        if (tsDegree >= 3) {
          this.detailResp2.vslNm3 = vo.vslNm3
          this.detailResp2.vslCd3 = vo.vslCd3
          this.detailResp2.voyNo3 = vo.voyNo3
          this.detailResp2.rteCd3 = rtnData.data.rteCd3
          this.detailResp2.pod2Nm = rtnData.data.pod2Nm
          this.detailResp2.pod3Nm = rtnData.data.pod3Nm
          this.detailResp2.transitTime3 = vo.transitTime3
          this.detailResp2.transitTSTime3 = vo.transitTSTime3
          this.detailResp2.polEtbDT3 = this.changeDate2Display(vo.polEtd3, vo.etdTm3, this.lang)
          this.detailResp2.polTml3 = this.fnTrmlText(vo.polTrml3, rtnData.data.polTrml3Nm)
          this.detailResp2.polTmlCd3 = vo.polTrml3
          this.detailResp2.etaDT3 = this.changeDate2Display(vo.podEtd3, vo.etaTm3, this.lang)
          this.detailResp2.podTml3 = this.fnTrmlText(vo.podTrml3, rtnData.data.podTrml3Nm)
          this.detailResp2.podTmlCd3 = vo.podTrml3
          this.raTsParam += '@'
          this.raTsParam += '3;'
          this.raTsParam += vo.pol3 + ';'
          this.raTsParam += vo.pod3 + ';'
          this.raTsParam += vo.vslCd3 + ';'
          this.raTsParam += vo.voyNo3 + ';'
          this.raTsParam += vo.rteCd3 + ';'
          this.raTsParam += vo.polEtd3 + ';'
        }
        if (tsDegree >= 4) {
          this.detailResp2.vslNm4 = vo.vslNm4
          this.detailResp2.vslCd4 = vo.vslCd4
          this.detailResp2.voyNo4 = vo.voyNo4
          this.detailResp2.rteCd4 = rtnData.data.rteCd4
          this.detailResp2.pod3Nm = rtnData.data.pod3Nm
          this.detailResp2.pod4Nm = rtnData.data.pod4Nm
          this.detailResp2.transitTime4 = vo.transitTime4
          this.detailResp2.transitTSTime4 = vo.transitTSTime4
          this.detailResp2.polEtbDT4 = this.changeDate2Display(vo.polEtd4, vo.etdTm4, this.lang)
          this.detailResp2.polTml4 = this.fnTrmlText(vo.polTrml4, rtnData.data.polTrml4Nm)
          this.detailResp2.polTmlCd4 = vo.polTrml4
          this.detailResp2.etaDT4 = this.changeDate2Display(vo.podEtd4, vo.etaTm4, this.lang)
          this.detailResp2.podTml4 = this.fnTrmlText(vo.podTrml4, rtnData.data.podTrml4Nm)
          this.detailResp2.podTmlCd4 = vo.podTrml4
          this.raTsParam += '@'
          this.raTsParam += '4;'
          this.raTsParam += vo.pol4 + ';'
          this.raTsParam += vo.pod4 + ';'
          this.raTsParam += vo.vslCd4 + ';'
          this.raTsParam += vo.voyNo4 + ';'
          this.raTsParam += vo.rteCd4 + ';'
          this.raTsParam += vo.polEtd4 + ';'
        }
      }
      // 서류마감
      this.detailResp2.bkgDocCls = this.changeDateTime2Display(vo.bkgDocCls, this.lang)
      // CY Open
      // VGM 마감 >> bkgDocCls 동일? -> vgmDocCls
      this.detailResp2.vgmDocCls = this.changeDateTime2Display(vo.vgmDocCls || '', this.lang)
      // Container 반입마감
      this.detailResp2.bkgCgoCls = this.changeDateTime2Display(vo.bkgCgoCls, this.lang)
      // EDI 신고마감
      //this.detailResp2.bkgMfCls = this.changeDateTime2Display(vo.bkgMfCls, this.lang) // ICC 데이타와 맞지 않아 변경.
      this.detailResp2.bkgMfCls = this.changeDateTime2Display(rtnData.data.bkgMfCls || '', this.lang)
      // CFS 반입마감
      this.detailResp2.cfsCls = this.changeDateTime2Display(vo.cfsCls, this.lang)
      // MRN No.
      this.detailResp2.mrnNo = vo.mrnNo
      // CALL SIGN
      this.detailResp2.callSign = vo.callSign
      // 입항횟수
      this.detailResp2.apoTcnt = vo.apoTcnt
      // 관할세관
      this.detailResp2.jrstCstm = rtnData.data.jrstCstm
      // 출항 Terminal >> polTml 동일
      // 선박 변경 취소용
      this.tempDetailResp2.vslNm2BK = this.detailResp2.vslNm2
      this.tempDetailResp2.voyNo2BK = this.detailResp2.voyNo2
      this.tempDetailResp2.rteCd2BK = this.detailResp2.rteCd2
      this.tempDetailResp2.transitTime2BK = this.detailResp2.transitTime2
      this.tempDetailResp2.polEtbDT2BK = this.detailResp2.polEtbDT2
      this.tempDetailResp2.etaDT2BK = this.detailResp2.etaDT2
      // R/A check
      if (this.auth.userId !== '') {
        // popFreAppNo 조회시 로그 저장 안함
        this.popFreAppNoCheck('N')
      }
      // console.log('>>>>>>>>>> this.detailResp2 <<<<<<<<<<')
      // console.log(this.detailResp2)
      // closeInfoSch
      if (this.bound === 'O') {
        this.closeInfoSch.menuCd = '01'
        this.closeInfoSch.vslNm = this.detailResp2.vslNm1
        this.closeInfoSch.vslCd = this.detailResp2.vslCd
        this.closeInfoSch.voyNo = this.detailResp2.voyNo
        this.closeInfoSch.polPortCd = this.detailResp2.pol
        this.closeInfoSch.podPortCd = this.detailResp2.pod
        this.closeInfoSch.polTrmlCd = this.detailResp2.polTmlCd
        this.closeInfoSch.rteCd = this.detailResp2.rteCd
        this.closeInfoSch.bound = this.bound
        this.closeInfoSch.bkgDocCls = this.detailResp2.bkgDocCls
        this.closeInfoSch.bkgCgoCls = this.detailResp2.bkgCgoCls
        this.closeInfoSch.bkgMfCls = this.detailResp2.bkgMfCls
        this.closeInfoSch.vgmDocCls = this.detailResp2.vgmDocCls
        this.closeInfoSch.cfsCls = this.detailResp2.cfsCls
        this.closeInfoSch.mrnNo = this.detailResp2.mrnNo
        this.closeInfoSch.polCtrCd = this.detailResp2.polCtrCd
        this.closeInfoSch.podCtrCd = this.detailResp2.podCtrCd
        this.closeInfoSch.polTml = this.detailResp2.polTml
        this.closeInfoSch.callSign = this.detailResp2.callSign
        this.closeInfoSch.bkgCfsPic = vo.bkgCfsPic
        this.closeInfoSch.bkgCgoPic = vo.bkgCgoPic
        this.closeInfoSch.bkgDocPic = vo.bkgDocPic
        // this.closeInfoSch.cyOpen = this.detailResp2.closInfVar
        this.closeInfoSch.polEtaDt = this.detailResp2.polEta // 선적항 Arrival 일시
        this.closeInfoSch.polEtdDt = this.detailResp2.polEtd // 선적항 Departure 일시
        // this.closeInfoSch.prfmEtaDt = vo.prfmEta + vo.prfmEtaTm // 선적항 Arrival 일시
        // this.closeInfoSch.prfmEtdDt = vo.prfmEtd + vo.prfmEtdTm // 선적항 Departure 일시
        this.closeInfoSch.apoTcnt = this.detailResp2.apoTcnt
      }
      // 상세정보 표시
      this.detailLayer2 = 'Y'
      if (vo.bkgClose === 'N' || vo.bkgClose === 'A') {
        this.afterClickDetail = 'Y'
      } else if (this.bound === 'I') {
        this.afterClickDetail = 'Y'
      } else {
        this.afterClickDetail = 'N'
      }

      this.getVslDelayReason()
      this.makeBookingParam(vo)
      const objDiv = document.querySelector('.popup_wrap')
      if (objDiv) {
        setTimeout(() => {
          objDiv.scrollTop = objDiv.scrollHeight
        }, 1000)
      }
      this.$store.commit('updatePersonalizedFlag')
      setTimeout(() => { window.scrollTo(0, 2000) }, 300)
    },
    changeDate2Display (strDate, strTime, lang) {
      var rtnStrDate = ''
      if (strDate === null || strDate === '') {
        rtnStrDate = ''
      } else {
        if (lang === 'ENG') {
          if (strDate.substring(4, 6) === '01') {
            rtnStrDate = strDate.substring(0, 4) + '.Jan.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '02') {
            rtnStrDate = strDate.substring(0, 4) + '.Feb.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '03') {
            rtnStrDate = strDate.substring(0, 4) + '.Mar.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '04') {
            rtnStrDate = strDate.substring(0, 4) + '.Apr.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '05') {
            rtnStrDate = strDate.substring(0, 4) + '.May.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '06') {
            rtnStrDate = strDate.substring(0, 4) + '.Jun.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '07') {
            rtnStrDate = strDate.substring(0, 4) + '.Jul.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '08') {
            rtnStrDate = strDate.substring(0, 4) + '.Aug.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '09') {
            rtnStrDate = strDate.substring(0, 4) + '.Sep.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '10') {
            rtnStrDate = strDate.substring(0, 4) + '.Oct.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '11') {
            rtnStrDate = strDate.substring(0, 4) + '.Nov.' + strDate.substring(6, 8)
          } else {
            rtnStrDate = strDate.substring(0, 4) + '.Dec.' + strDate.substring(6, 8)
          }
        } else {
          rtnStrDate = strDate.substring(0, 4) + '.' + strDate.substring(4, 6) + '.' + strDate.substring(6, 8)
        }
      }
      if (strTime === null || strTime === '') {
        rtnStrDate = ''
      } else {
        rtnStrDate = rtnStrDate + ' ' + strTime.substring(0, 2) + ':' + strTime.substring(2, 4)
      }
      return rtnStrDate
    },
    changeDateTime2Display (strDate, lang) {
      var rtnStrDate = ''
      if (strDate === null || strDate === '') {
        rtnStrDate = ''
      } else {
        if (lang === 'ENG') {
          if (strDate.substring(4, 6) === '01') {
            rtnStrDate = strDate.substring(0, 4) + '.Jan.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '02') {
            rtnStrDate = strDate.substring(0, 4) + '.Feb.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '03') {
            rtnStrDate = strDate.substring(0, 4) + '.Mar.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '04') {
            rtnStrDate = strDate.substring(0, 4) + '.Apr.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '05') {
            rtnStrDate = strDate.substring(0, 4) + '.May.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '06') {
            rtnStrDate = strDate.substring(0, 4) + '.Jun.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '07') {
            rtnStrDate = strDate.substring(0, 4) + '.Jul.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '08') {
            rtnStrDate = strDate.substring(0, 4) + '.Aug.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '09') {
            rtnStrDate = strDate.substring(0, 4) + '.Sep.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '10') {
            rtnStrDate = strDate.substring(0, 4) + '.Oct.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '11') {
            rtnStrDate = strDate.substring(0, 4) + '.Nov.' + strDate.substring(6, 8)
          } else {
            rtnStrDate = strDate.substring(0, 4) + '.Dec.' + strDate.substring(6, 8)
          }
        } else {
          rtnStrDate = strDate.substring(0, 4) + '.' + strDate.substring(4, 6) + '.' + strDate.substring(6, 8)
        }
        rtnStrDate = rtnStrDate + ' ' + strDate.substring(8, 10) + ':' + strDate.substring(10, 12)
      }
      return rtnStrDate
    },
    fnTrmlText (trml, trml_nm) {
      var str = ''
      if (trml !== '') {
        str += trml
      } else {
        str = ''
      }
      if (trml_nm !== '' && trml_nm !== undefined) {
        str += ' (' + trml_nm + ')'
      } else if (trml_nm === undefined) {
        str = ''
      } else {
        str = ''
      }
      return str
    },
    fnChangeCalendar (yearMonth) {
      this.yyyymmLeg = yearMonth
      this.getLegSchedule(yearMonth.substring(0, 4), yearMonth.substring(4, 6))
    },
    fnDeleteMySchedule (schId) {
      mySchedules.deleteMySchedule(schId).then((resultCode) => {
        if (resultCode.data.result === 1) {
          mySchedules.getMySchedule().then((rtnData) => {
            this.myScheduleList = rtnData.data.mySchedule
          })
        }
      })
    },
    deleteMyScheduleStar (schId) {
      mySchedules.deleteMySchedule(schId).then((resultCode) => {
        if (resultCode.data.result === 1) {
          mySchedules.getMySchedule().then((rtnData) => {
            this.myScheduleList = rtnData.data.mySchedule
          })
        }
      })
      this.myScheduleOrNot = 'N'
    },
    fnAddMySchedule (rtnLegData) {
      const params = {
        startPolCn: rtnLegData.arrayStartCtrCd[0],
        startPolCd: rtnLegData.arrayStartPlcCd[0],
        destPodCn: rtnLegData.arrayDestCtrCd[0],
        destPodCd: rtnLegData.arrayDestPlcCd[0],
        startCtrNm: rtnLegData.startCtrNameSel[0],
        startPlcNm: rtnLegData.startPlcNameSel[0],
        destCtrNm: rtnLegData.destCtrNameSel[0],
        destPlcNm: rtnLegData.destPlcNameSel[0]
      }
      mySchedules.addMySchedule(params).then((resultCode) => {
        if (resultCode.data.result === 1) {
          mySchedules.getMySchedule().then((rtnData) => {
            this.myScheduleList = rtnData.data.mySchedule
          })
          mySchedules.checkMySchedule(params).then((rtnMySchData) => {
            this.myScheduleOrNot = rtnMySchData.data.myScheduleOrNot
            this.schId = rtnMySchData.data.schId
          })
        }
      })
      // this.myScheduleOrNot = 'Y'
    },
    selectMySchedule (list) {
      this.scheduleForm.startPolName = list.polNm
      this.scheduleForm.destPodName = list.podNm
      this.scheduleForm.startCtrCd = list.polCn
      this.scheduleForm.startPlcCd = list.polCd
      this.scheduleForm.startPlcName = list.polNm2
      this.scheduleForm.destCtrCd = list.podCn
      this.scheduleForm.destPlcCd = list.podCd
      this.scheduleForm.destPlcName = list.podNm2
      this.pol.ctrCd = list.polCn
      this.pol.plcCd = list.polCd
      this.pod.ctrCd = list.podCn
      this.pod.plcCd = list.podCd
      const frm = document.querySelector('#frmLeg')
      const elemPol = frm.querySelector('#polPol')
      const elemPod = frm.querySelector('#podPod')
      this.$ekmtcCommon.hideErrorTooltip(elemPol)
      this.$ekmtcCommon.hideErrorTooltip(elemPod)
      this.filterYn = 'N'
    },
    change2ndVessel (data) {
      const params = {
        asPolPort: data.pol2nd,
        asPodPort: data.pod2nd,
        asFrom: data.etd2nd
      }
      schedules.get2ndVessel(params).then((rtnData) => {
        this.vessel2ndList = rtnData.data.vessel2ndList
        this.show2ndVessel = 'Y'
      })
    },
    change2ndVessel4List (vo, idx) {
      this.listIndexNo = idx
      const params = {
        asPolPort: vo.pol,
        asPodPort: vo.pod,
        asFrom: vo.etd
      }
      schedules.get2ndVessel(params).then((rtnData) => {
        this.vessel2ndList = rtnData.data.vessel2ndList
        this.show2ndVessel = 'Y'
      })
    },
    select2ndVessel (data) {
      // to display
      this.detailResp2.vslNm2 = data.vslNm
      this.detailResp2.voyNo2 = data.voyNo
      this.detailResp2.rteCd2 = data.rteCd
      this.detailResp2.transitTime2 = data.transitTime
      this.detailResp2.polEtbDT2 = data.etdStrDT
      this.detailResp2.etaDT2 = data.etaStrDT
      // set parameter
      var hidCS008I2nd = data.vslNm + ':' +
                    data.oportCd + ':' +
                    data.orvsdEndpDt +
                    data.orvsdEndpTm + ':' +
                    data.iportCd + ':' +
                    data.irvsdEndpDt +
                    data.irvsdEndpTm + ':' +
                    data.voyNo + ':' +
                    data.schSeq + ':' +
                    data.schSeq1 + ':' +
                    data.vslCatCd + ':' +
                    data.voyNo + ':' +
                    data.ivoyNo + ':' +
                    data.otrmlCd + ':' +
                    data.itrmlCd + ':' +
                    data.schSeq + ':' +
                    data.schSeq1 + ':' +
                    data.cnntDds + ':' +
                    data.trstDds + ':' +
                    data.vslCd
        var hidCS008I = this.bkgParams.hidCS008I.split('@_@')
        var nd2hidCS008I = ''
        var length2 = hidCS008I.length - 1
        for (let i = 0; i < length2; i++) {
          if (i === 2) {
            nd2hidCS008I += hidCS008I2nd + '@_@'
          } else {
            nd2hidCS008I += hidCS008I[i] + '@_@'
          }
        }
        this.bkgParams.hidCS008I = nd2hidCS008I
        this.show2ndVessel = 'N'
    },
    select2ndVessel4List (data, idx) {
      var listData = this.listSchedule4List[idx]
      listData.polEtd2 = data.orvsdEndpDt
      listData.etdTm2 = data.orvsdEndpTm
      listData.eta = data.irvsdEndpDt
      listData.etaTm = data.irvsdEndpTm
      listData.vslNm2 = data.vslNm
      listData.voyNo2 = data.voyNo
      listData.rteCd2 = data.rteCd
      listData.transitTime2 = data.transitTime
      listData.polEtbDT2 = data.etdStrDT
      listData.etaDT2 = data.etaStrDT
      // set parameter
      this.vessel2ndParam = data.vslNm + ':' +
                    data.oportCd + ':' +
                    data.orvsdEndpDt +
                    data.orvsdEndpTm + ':' +
                    data.iportCd + ':' +
                    data.irvsdEndpDt +
                    data.irvsdEndpTm + ':' +
                    data.voyNo + ':' +
                    data.schSeq + ':' +
                    data.schSeq1 + ':' +
                    data.vslCatCd + ':' +
                    data.voyNo + ':' +
                    data.ivoyNo + ':' +
                    data.otrmlCd + ':' +
                    data.itrmlCd + ':' +
                    data.schSeq + ':' +
                    data.schSeq1 + ':' +
                    data.cnntDds + ':' +
                    data.trstDds + ':' +
                    data.vslCd
      this.show2ndVessel = 'N'
    },
    viewCalendar (view) {
      if (view === 'L') {
        //
        this.rmPopOpenFlag = false
        // if (this.memberDetail !== undefined) {
        //   const oonlYn = this.memberDetail.oonlYn // 수출영업(Export) 기준으로 온라인 견적 대상 화주인 경우
        //   const jonlYn = this.memberDetail.jonlYn // 일본영업 기준으로 온라인 견적 대상 화주인 경우
        //   if (oonlYn === 'Y' && jonlYn === 'Y') {
        //     if (this.scheduleForm.startCtrCd === 'KR') {
        //       this.rmPopOpenFlag = true
        //     }
        //   } else {
        //     if (oonlYn === 'Y') {
        //       if (this.scheduleForm.startCtrCd === 'KR' && this.scheduleForm.destCtrCd !== 'JP') {
        //         this.rmPopOpenFlag = true
        //       }
        //     } else if (jonlYn === 'Y') {
        //       if (this.scheduleForm.startCtrCd === 'KR' && this.scheduleForm.destCtrCd === 'JP') {
        //         this.rmPopOpenFlag = true
        //       }
        //       if (this.scheduleForm.startCtrCd === 'JP' && this.scheduleForm.destCtrCd === 'KR') {
        //         this.rmPopOpenFlag = true
        //       }
        //     }
        //   }
        // }
        //
        this.afterClickDetail = 'N'
        if (this.afterSearch === 'N') {
          // alert(this.$t('art.CMATK139')) // 조회 후 선택하세요.
          this.$ekmtcCommon.alertDefault(this.$t('art.CMATK139'))
          return
        } else {
          this.calendarOrList = view
          // this.getLegSchedule(this.searchYear, this.searchMonth)
        }
      } else {
        this.afterClickDetail = 'N'
      }
      this.calendarOrList = view
    },
    cancel2ndVessel () {
      this.show2ndVessel = 'N'
      // to display
      this.detailResp2.vslNm2 = this.tempDetailResp2.vslNm2BK
      this.detailResp2.voyNo2 = this.tempDetailResp2.voyNo2BK
      this.detailResp2.rteCd2 = this.tempDetailResp2.rteCd2BK
      this.detailResp2.transitTime2 = this.tempDetailResp2.transitTime2BK
      this.detailResp2.polEtbDT2 = this.tempDetailResp2.polEtbDT2BK
      this.detailResp2.etaDT2 = this.tempDetailResp2.etaDT2BK
      // set parameter
      this.bkgParams.hidCS008I = this.bkgParams.originInfo
    },
    cancel2ndVessel4List (idx) {
      this.show2ndVessel = 'N'
    },
    getList4Schedule (idx) {
      if (idx < 1) {
        // alert(this.$t('js.common.002')) // 처음페이지입니다.
        this.$ekmtcCommon.alertDefault(this.$t('js.common.002'))
        this.curPage = 1
        return
      }
      if (idx > this.finalPage) {
        // alert(this.$t('js.common.001')) // 마지막페이지입니다.
        this.$ekmtcCommon.alertDefault(this.$t('js.common.001'))
        this.curPage = this.finalPage
        return
      }
      this.curPage = idx
      this.startIdx = (idx * this.pageSize) - this.pageSize
      this.endIdx = idx * this.pageSize
    },
    showRFinfo (flag) {
      this.RFinfo = flag
    },
    // 부킹 넘어 가기전에 실적 체크 / fnRateChkYn(As-is)
    findSA271Yn (bkgClose) {
      var eiCatCd = (this.bkgParams.podCtrCd === 'KR') ? 'I' : 'O'
      if (this.oengOnlYn === 'Y' && this.userCtrCd !== 'KR') {
        eiCatCd = 'O'
      }
      if ('TH|CN|HK|ID|IN'.indexOf(this.userCtrCd) > -1) {
        eiCatCd = 'O'
      }
      var rteCd = this.detailResp2.rteCd
      if (rteCd === undefined) {
        if (this.detailResp2.tsDegree >= 2) {
          rteCd = this.detailResp2.rteCd2
        }
      }
      if (rteCd === undefined) {
        if (this.detailResp2.tsDegree >= 3) {
          rteCd = this.detailResp2.rteCd3
        }
      }
      if (rteCd === undefined) {
        if (this.detailResp2.tsDegree >= 4) {
          rteCd = this.detailResp2.rteCd4
        }
      }
      const params = {
        porCtrCd: this.bkgParams.polCtrCd,
        porPlcCd: this.bkgParams.polPortCd,
        dlyCtrCd: this.bkgParams.podCtrCd,
        dlyPlcCd: this.bkgParams.podPortCd,
        etd: this.bkgParams.etd,
        vslCd: this.bkgParams.vslCd,
        voyNo: this.bkgParams.voyNo,
        rteCd: rteCd,
        eiCatCd: eiCatCd,
        raTsParam: this.raTsParam
      }
      schedules.findSA271Yn(params).then((data) => {
        if ('Y|X'.indexOf(data.data.rtn) > -1) {
          this.popFreAppNo(bkgClose, 'Y')
        } else {
          this.popFreAppNo(bkgClose)
        }
      })
    },
    // 견적운임문의 클릭 / fnPopReqestApp(As-is)
    // 1.실적이 존재하면 해당 구간의 정보를 팝업
    // 2.실적이 없고 Rate Applicationd에 존재시 해당 구간 정보 팝업.
    // 3.실적이 없고 Rate Applicationd에 없는 경우, Rate Management 정보를 팝업.
    // 4.위 모두 미해당 시 구간 정보 Rate Management 등록
    popFreAppNo (bkgClose, rateYn) {
      var eiCatCd = (this.bkgParams.podCtrCd === 'KR') ? 'I' : 'O'
      if (this.oengOnlYn === 'Y' && this.userCtrCd !== 'KR') {
        eiCatCd = 'O'
      }
      if ('TH|CN|HK|ID|IN'.indexOf(this.userCtrCd) > -1) {
        eiCatCd = 'O'
      }
      const params = {
        porCtrCd: this.bkgParams.polCtrCd,
        porPlcCd: this.bkgParams.polPortCd,
        dlyCtrCd: this.bkgParams.podCtrCd,
        dlyPlcCd: this.bkgParams.podPortCd,
        etd: this.bkgParams.etd,
        vslCd: this.bkgParams.vslCd,
        voyNo: this.bkgParams.voyNo,
        rteCd: this.detailResp2.rteCd,
        eiCatCd: eiCatCd,
        hotDealYn: 'N',
        hotDealReqRno: '',
        logYn: 'N',
        raTsParam: this.raTsParam
      }
      // 부킹 클로즈 유무 추가
      if (!bkgClose || this.detailResp2.dtBkgYn === 'Y') {
        bkgClose = 'N'
      }
      schedules.popFreAppNo(params).then((data) => {
        // this.bkgParams.frtAppNo = data.data.frtAppNo
        if (data.data.frtResult === 'Y') {
          // 1. 실적이 존재하면 해당 구간의 정보를 팝업
          // 2. 실적이 없고 Rate Applicationd에 존재시 해당 구간 정보 팝업
          if (rateYn && rateYn === 'Y') {
            // this.setScheduleInfo() // Booking gogosing
          } else {
            this.popFreSurcharge(data.data.frtAppNo, data.data.frtResult, data.data.reqRno, bkgClose)
          }
        } else if (data.data.frtResult === 'A') {
          // 3. 실적이 없고 Rate Applicationd에 없는 경우, Rate Management 정보를 팝업
          if (this.lang !== 'KOR') {
            // this.fnPopFindPrice(data.data.frtAppNo, data.data.frtResult, data.data.reqRno, bkgClose)
            // 운임문의 팝업(SCHD010P080)?!
          } else {
            // this.popFreSurcharge(data.data.frtAppNo, data.data.frtResult, data.data.reqRno, bkgClose)
          }
        } else {
          // 4. 운임요청
          // fnpopRatePicNm(bkgClose); >> 운임문의 팝업(SCHD010P080)?!
        }
        this.throwBookingParams()
      })
    },
    popFreSurcharge (frtAppNo, frtResult, reqRno, bkgClose) {
      const params = {
        porCtrCd: this.bkgParams.polCtrCd,
        porPlcCd: this.bkgParams.polPortCd,
        dlyCtrCd: this.bkgParams.podCtrCd,
        dlyPlcCd: this.bkgParams.podPortCd,
        etd: this.bkgParams.etd,
        frtAppNo: frtAppNo,
        bkgVslCd: this.bkgParams.vslCd,
        bkgVoyNo: this.bkgParams.voyNo,
        vslCd: this.bkgParams.vslCd,
        voyNo: this.bkgParams.voyNo,
        rteCd: this.detailResp2.rteCd,
        eiCatCd: (this.bkgParams.podCtrCd === 'KR') ? 'I' : 'O',
        frtResult: frtResult,
        reqRno: reqRno,
        hotDealYn: 'N',
        hotReqRno: '',
        bkgClose: bkgClose,
        raTsParam: this.raTsParam
      }
      schedules.popFreSurcharge(params).then((data) => {
        // console.log('@@@@@ popFreSurcharge Return Data @@@@@')
        // console.log(data)
      })
    },
    throwBookingParams () {
      const obj = {
        params: this.bkgParams,
        schedule: this.bkgScheduleData,
        detail: this.detailResp2, //this.bkgDetailData
        isCollectTermBkg: this.isCollectTermBkg,
        urlParam: this.urlParam, // JamSin DSM
        urlOrNot: this.urlOrNot, // JamSin DSM
        promotionChk: this.parentInfo.promotionChk
      }

      // if (this.bkgOrNot) {
      //   this.$emit('ScheduleLegPop', obj)
      //   this.$emit('close')
      // } else if (this.mainOrNot) {
      //   this.$emit('close')
      //   this.$router.push({ name: 'booking-new', params: { scheduleObj: obj } }).catch(() => {})
      // } else {
      //   this.$router.push({ name: 'booking-new', params: { scheduleObj: obj } }).catch(() => {})
      //   //this.$router.push('/booking-new?obj=' + obj)
      // }
      if (this.bkgOrNot) {
        this.$emit('ScheduleLegPop', obj)
        this.$emit('close')
      } else {
        if (this.mainOrNot) {
          this.$emit('close')
        }

        if (!this.auth.userId) {
          const queryObj = {
            params: JSON.stringify(obj.params),
            schedule: JSON.stringify(obj.schedule),
            detail: JSON.stringify(obj.detail)
          }
          this.$router.push({ name: 'booking-new', query: queryObj }).catch(() => {})
        } else {
          // JamSin DSM 선호항로, 선복보장 은 URL로 스케줄진입하여 부킹으로 이동할 경우 URL params 유지하기 위해 query에 추가.
          // Booking New 에서는 query param을 다른곳에서도 사용하고 있기때문에 분기처리 해야함.
          //if (this.urlOrNot && (this.urlParam.dsmType === '015' || this.urlParam.dsmType === '018')) {
          //  this.$router.push({ name: 'booking-new', params: { scheduleObj: obj }, query: this.urlParam }).catch(() => {})
          //} else {
          //  this.$router.push({ name: 'booking-new', params: { scheduleObj: obj } }).catch(() => {})
          //}
          this.$router.push({ name: 'booking-new', params: { scheduleObj: obj } }).catch(() => {})
        }
      }
    },
    makeBookingParam (vo) {
      var finalEta = ''
      if (vo.ts === 'Y') {
        finalEta = vo.podEtd2 + vo.etaTm2
      } else {
        finalEta = vo.eta + vo.etaTm
      }
      //this.bkgParams.vslCd = vo.vslCd // [0]선명코드
      //this.bkgParams.voyNo = vo.voyNo // [1]항차
      this.bkgParams.vslCd = vo.bkgVslCd // [0]선명코드
      this.bkgParams.voyNo = vo.bkgVoyNo // [1]항차
      this.bkgParams.vslNm = vo.vslNm // [2]선명
      this.bkgParams.polName = vo.polNm // [3]출발지 포트명
      this.bkgParams.podName = vo.podNm // [4]도착지 포트명
      this.bkgParams.etd = vo.etd // [5]입항일
      this.bkgParams.etdTm = vo.etdTm // [6]입항시간
      this.bkgParams.eta = vo.polEtb // [7]출항일
      this.bkgParams.etaTm = vo.polEtbTm // [8]출항시간
      this.bkgParams.polPortCd = vo.pol // [9]출발지 포트코드
      this.bkgParams.podPortCd = vo.pod // [10]도착지 포트코드
      this.bkgParams.polCtrCd = vo.polCtrCd // [11]출발지 국가코드
      this.bkgParams.podCtrCd = vo.podCtrCd // [12]도착지 국가코드
      this.bkgParams.cct = vo.cct // [13] ?
      this.bkgParams.docCls = vo.bkgDocCls // [14]서류마감
      this.bkgParams.docPic = vo.bkgDocPic // [15]서류마감 담당자
      this.bkgParams.polTrmlCd = vo.otrmlCd // [16]출발지 터미널 코드
      this.bkgParams.podTrmlCd = vo.itrmlCd // [18]도착지 터미널 코드
      this.bkgParams.mrnNo = vo.mrnNo // [18]MRN Number
      this.bkgParams.callSign = vo.callSign // [19]Call sign
      this.bkgParams.hidCS008I = vo.info // [20]info (@_@로 붙어있는 정보)
      this.bkgParams.originInfo = vo.info // [20]info Backup (@_@로 붙어있는 정보)
      this.bkgParams.mfCls = vo.bkgMfCls // [21]EDI 신고마감
      this.bkgParams.cgoCls = vo.bkgCgoCls // [22]반입마감
      this.bkgParams.cgoPic = vo.bkgCgoPic // [23]반입마감 담당자
      this.bkgParams.finalEta = finalEta // [24]최종ETA
      this.bkgParams.cfsCls = vo.cfsCls // [25]cfs 반입마감
      this.bkgParams.cfsPic = vo.bkgCfsPic // [26]cfs 반입마감 담당자
      this.bkgParams.bkgCloseYn = vo.bkgClose // [27] booking close 여부
      this.bkgParams.prrmSgEtd = vo.prrmSgEtd // [28] EDI 마감시간
      this.bkgParams.polTrmlNm = vo.otrmlNm // [29] 출발지 터미널명
      this.bkgParams.railCheck = '' // [30] rail booking 여부 ? >> schedule 처리 부분 없음(주석처리)
      // this.bkgParams.frtAppNo = '_notyet' // [31] 운임 APPLICATION 번호 >> 견적운임조회
      this.bkgParams.logDtm = '' // [32] >> 견적운임조회 >> _notyet
      // this.bkgParams.srRest = '_notyet' // [33] SR 생성여부 >> 부킹 제약사항체크
      // this.bkgParams.localDtm = '20210906151954_notyet' // [34] 현재 시간 >> 부킹 제약사항체크
      // this.bkgParams.vslRest = '0_notyet' // [35] 선박 close 여부 >> 부킹 제약사항체크
      // this.bkgParams.cgoRest = '202109071600_notyet' // [36] 반입 마감시한 >> 부킹 제약사항체크
      this.bkgParams.rfYn = this.regParams.rf
      this.bkgParams.apoTcnt = vo.apoTcnt
      this.bkgParams.mcOpenYn = vo.mcOpenYn
    },
    async getCntrTypeMessage () {
      let message = this.$t('msg.CSCT060G040.010') + '<br><br>'

      message += '<div class="d_flex">'
      message += '  <div class="inp_space30">Type : </div>'
      message += '  <div class="inp_space70">'
      message += '  <select id="popCntrTypCd">'
      message += '  <option value="">' + this.$t('msg.CSBL200.075') + '</option>'
      message += '  <option value="GP">Dry</option>'
      message += '  <option value="RF">Reefer</option>'
      message += '  <option value="FR">Flat Rack</option>'
      message += '  <option value="OT">Open Top</option>'
      message += '  </select>'
      message += '  </div>'
      message += '</div>'

      let rtnVal = ''
      const isOk = await this.$ekmtcCommon.asyncAlertMessage({
        message: message,
        useConfirmBtn: true,
        onConfirmSelf: (resolve, $Simplert) => {
          const popCntrTypCd = document.querySelector('#popCntrTypCd')

          if (this.$ekmtcCommon.isEmpty(popCntrTypCd.value)) {
            this.$ekmtcCommon.showErrorTooltip(popCntrTypCd, this.$t('msg.CSBK100.147'))
          } else {
            rtnVal = popCntrTypCd.value
            resolve(true)
            $Simplert.close()
          }
        }
      })

      return {
        isOk: isOk,
        rtnVal: rtnVal
      }
    },
    async beforeGoBooking (vo) {
      console.log('@@@@ enter beforeGoBooking')

      // KMTC ON 구간이면서 확정운임이 있는 화주는 운임확인 버튼을 눌러 운임을 선택해야 함
      if (this.kmtcPremiumUserYn === 'Y' && this.kmtcPremiumLineYn === 'Y' && this.frtResult === 'Y') {
        this.$ekmtcCommon.asyncAlertMessage({ message: '운임확인 버튼을 눌러 운임을 먼저 선택해주시고 부킹 부탁드립니다.' })
        return
      }

      if (!this.$ekmtcCommon.isEmpty(this.etaBookingMsg)) {
        this.$ekmtcCommon.asyncAlertMessage({ message: this.etaBookingMsg }) // 해당 국가 ( 한국 ) 수출화물에 대해서만 부킹이 가능합니다.
        return
      } else if (!this.auth.userId) {
        // 20220324 비로그인시 제한 추가
        // 로그인이 필요한 메뉴입니다.
        if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.MAIN010G030.034') })) {
          if (!this.$store.getters['auth/requireLogin']) {
            this.$store.commit('auth/requireLogin', !this.$store.getters['auth/requireLogin'])
          }
        }
        return
      }

      if (this.hongkongTsYn === 'Y') {
        this.$ekmtcCommon.alertDefault(this.hongkongTsMsg) // 중국 춘절 기간동안 홍콩 T/S가 중단
        return
      }

      // Booking 버튼
      if (this.frtResult === 'Y' || this.userCtrCd === 'CN') {
        // - 확정 운임이 있는 화주 : Booking 화면(NEWB010010)으로 이동
        if (this.kmtcPremiumUserYn === 'N' && this.kmtcPremiumLineYn === 'Y') {
          this.kmtcPremiumLineYn = 'N'
          this.bkgScheduleData.kmtcPremiumLineYn = 'N'
          vo.kmtcSpotLineYn = 'N'
        }
        this.bookingBooking(vo)
      } else if (this.userCtrCd === 'JP' && this.memberDetail.fwdrCstCd === '01') { // 일본 포워더는 운임여부 확인 없이 부킹 가능하게
        this.bookingBooking(vo)
      } else {
        // - 확정 운임이 없는 온라인 견적 대상 화주 : ‘A01’ 얼럿메시지 확인 후 운임확인(2) 팝업((SCHD010P071)에서 운임 확정 후 Booking 화면(NEWB010010)으로 이동
        // - [A01] 예 : Booking 화면(NEWB010010)으로 이동 / 아니오 : 얼럿 닫힘
        // if (this.oonlYn === 'Y' || this.jonlYn === 'Y' || this.ionlYn === 'Y' || this.oengOnlYn === 'Y') {
        if (this.rmPopOpenFlag) {
          // R/M(Tariff) check
          const params = {
            porCtrCd: this.scheduleForm.startCtrCd,
            porPlcCd: this.scheduleForm.startPlcCd,
            dlyCtrCd: this.scheduleForm.destCtrCd,
            dlyPlcCd: this.scheduleForm.destPlcCd,
            etd: vo.etd,
            frtAppNo: this.detailResp2.frtAppNo,
            vslCd: vo.vslCd,
            voyNo: vo.voyNo,
            rteCd: vo.rteCd,
            eiCatCd: this.eiCatCd,
            frtResult: this.frtResult,
            reqRno: this.detailResp2.reqRno,
            bkgClose: vo.bkgClose,
            raTsParam: this.raTsParam
          }
          vslScheduleInfo.getPopFreSurcharge(params).then(async (rtnData) => {
            // console.log('######### [ScheduleLeg] getPopFreSurcharge #########')
            // console.log(rtnData)
            if (rtnData.data.surChargeList.length === 0) {
              // this.bookingBooking(vo)
              // 온라인 견적이 적용된 경우
              const cntrMsgInfo = await this.getCntrTypeMessage()

              if (cntrMsgInfo.isOk) {
                params.cntrTypCd = cntrMsgInfo.rtnVal
                await schedules.fnPopFreReq(params).then((response) => {
                  const result = response.data.insertRateMngResult
                  let msg = ''
                  if (result !== undefined && result === 1) {
                    console.log('111')
                    //msg = this.$t('msg.FARE010T010.022') // 문의하신 구간에 대한 운임등록 요청이 접수되었습니다. 잠시 후에 다시 확인하시기 바랍니다.
                    const confirmOpt = {
                      alertType: 'simple',
                      customCloseBtnText: this.$t('msg.VOSD100_M5.004'), // 닫기
                      customConfirmBtnText: this.$t('msg.VOSD100_M5.003'), // 확인
                      useConfirmBtn: true,
                      message: `${this.$t('msg.FARE010T010.025')}\n${this.$t('msg.FARE010T010.026')}`, // 현재 요청하신 기간 및 구간에 등록된 운임이 없습니다.\n영업 사원에게 문의 하시겠습니까?
                      onConfirm: this.sendSalEmail
                    }

                    this.$ekmtcCommon.alert(confirmOpt)
                  } else if (result !== undefined && result === 2) { // 등록된 운임이 있음
                    msg = this.$t('msg.FARE010T010.024')
                    this.$ekmtcCommon.alertDefault(msg)
                  } else if (result !== undefined && result === 3) { // 요청중인 내역이 있는경우 메시지만 동일하게 출력
                    //msg = this.$t('msg.FARE010T010.022') // 문의하신 구간에 대한 운임등록 요청이 접수되었습니다. 잠시 후에 다시 확인하시기 바랍니다.
                    console.log('222')

                    const confirmOpt = {
                      alertType: 'simple',
                      customCloseBtnText: this.$t('msg.VOSD100_M5.004'), // 닫기
                      customConfirmBtnText: this.$t('msg.VOSD100_M5.003'), // 확인
                      useConfirmBtn: true,
                      message: `${this.$t('msg.FARE010T010.025')}\n${this.$t('msg.FARE010T010.026')}`, // 현재 요청하신 기간 및 구간에 등록된 운임이 없습니다.\n영업 사원에게 문의 하시겠습니까?
                      onConfirm: this.sendSalEmail
                    }

                    this.$ekmtcCommon.alert(confirmOpt)
                  } else {
                    msg = this.$t('msg.FARE010T010.023') // 처리 중 오류가 발생하였습니다. 시스템 관리자에게 문의해 주세요.
                    this.$ekmtcCommon.alertDefault(msg)
                  }
                })
              }
            } else {
              let obj = {
                // onClose: this.closeConfirm,
                alertType: 'simple',
                customCloseBtnText: this.$t('msg.VOSD100_M5.004'),
                customConfirmBtnText: this.$t('msg.VOSD100_M5.003'),
                useConfirmBtn: true,
                customEtcBtnText: this.$t('msg.VOSD100_M5.127'), // Collect Term
                useEtcBtn: true,
                message: this.$t('msg.VOSD100_M5.062') + '<br/>' + this.$t('msg.VOSD100_M5.126'), // 부킹하기 위해서 운임확정이 필요합니다. 진행하시겠습니까? (Collect 건의 경우 운임 확정 없이 부킹 가능)
                type: 'success',
                onConfirm: () => {
                  this.openPopup('FreSurchargePop2')
                  window.app.$Simplert.close() //컨텀창 안닫혀서 추가
                },
                onEtcClick: () => {
                  console.log('>>>>> call onEtcClick')
                  // KMTC ON 구간인 경우 운임 선택 없이 Collect Term으로 부킹하기에 일반 운임 구간으로 세팅하여 부킹 진행
                  this.kmtcPremiumLineYn = 'N'
                  this.bkgScheduleData.kmtcPremiumLineYn = 'N'
                  vo.kmtcSpotLineYn = 'N'
                  this.bookingBooking(vo, true)
                }
              }
              this.$ekmtcCommon.alert(obj)
            }
          })
        } else {
          // - 온라인 견적 대상 外 화주 : Booking 화면(NEWB010010)으로 이동
          // this.bookingBooking(vo)

          // 온라인 견적이 적용되지 않은 경우 - 일반화주
          // "문의하신 구간에 등록된 운임이 없습니다. 견적 문의를 요청하시겠습니까?" 팝업 띄움
          // 예 - 운임문의 팝업(SCHD010P080)
          // 아니오 - Alert 닫기
          if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.FARE010T010.021'), useConfirmBtn: true })) {
            this.surChargeParams = this.params
            this.openPopup('RequestQuotePop')
          } else {
            // 비온라인 화주이면서 KMTC ON 구간 스케줄 선택한 경우 부킹 버튼을 누르면 이곳에 오게 되고,
            // 확정 운임이 없는 상태이므로, KMTC ON 구간 세팅 Y를 N으로 바꿔 일반 구간 부팅으로 진행하게 함
            vo.kmtcSpotLineYn = 'N'
            this.bkgParams.kmtcSpotYn = 'N'
            this.bkgScheduleData.kmtcPremiumLineYn = 'N'
            this.bookingBooking(vo)
          }
        }
      }
    },
    closeConfirm () {
      console.log('######### closeConfirm #########')
    },
    FreSurchargePop2 () {
      this.openPopup('FreSurchargePop2')
    },
    bookingBooking (vo, isCollectTermBkg = false) {
      console.log('@@@@ enter bookingBooking')
      this.isCollectTermBkg = isCollectTermBkg // 운임이 없는 부킹여부 (Collect Term 을 클릭하여 부킹하는 경우)

      const params = {
        asVslCd: vo.vslCd,
        asVoyNo: vo.voyNo,
        asPolPortCd: vo.pol,
        asPodPortCd: (vo.pod1 ? vo.pod1 : vo.pod)
      }
      schedules.checkBkgRestriction(params).then((data) => {
        this.bkgParams.srRest = data.data.srRest // [33] SR 생성여부 >> 부킹 제약사항체크
        this.bkgParams.localDtm = data.data.localDtm // [34] 현재 시간 >> 부킹 제약사항체크
        this.bkgParams.vslRest = data.data.vslRest // [35] 선박 close 여부 >> 부킹 제약사항체크
        this.bkgParams.cgoRest = data.data.cgoRest // [36] 반입 마감시한 >> 부킹 제약사항체크
      })
      this.insertWB242H('03', this.logPgmCatCd, vo)
      this.findSA271Yn()
      //this.popFreAppNo() //findSA271Yn 에서 popFreAppNo 호출 하기때문에 중복호출
    },
    bookingBooking2 (vo, kmtcPremiumFrtChk) { // freightRnP - KMTC ON 운임선택시 일반, 프리미엄 운임 선택 확인
      this.isCollectTermBkg = false // 운임이 없는 부킹여부 (Collect Term 을 클릭하여 부킹하는 경우)

      // KMTC ON 구간에서 일반 운임 선택시 일반으로 진행
      if (kmtcPremiumFrtChk !== 'P') {
        this.kmtcPremiumLineYn = 'N'
        this.bkgScheduleData.kmtcPremiumLineYn = 'N'
      }

      const params = {
        asVslCd: vo.vslCd,
        asVoyNo: vo.voyNo,
        asPolPortCd: vo.pol,
        asPodPortCd: vo.pod1
      }
      schedules.checkBkgRestriction(params).then((data) => {
        this.bkgParams.srRest = data.data.srRest // [33] SR 생성여부 >> 부킹 제약사항체크
        this.bkgParams.localDtm = data.data.localDtm // [34] 현재 시간 >> 부킹 제약사항체크
        this.bkgParams.vslRest = data.data.vslRest // [35] 선박 close 여부 >> 부킹 제약사항체크
        this.bkgParams.cgoRest = data.data.cgoRest // [36] 반입 마감시한 >> 부킹 제약사항체크
      })
      this.bkgParams.frtAppNo = vo.frtAppNo
      this.insertWB242H('03', this.logPgmCatCd, vo)
      this.findSA271Yn()
      //this.popFreAppNo() //findSA271Yn 에서 popFreAppNo 호출 하기때문에 중복호출
    },
    bookingBooking4List (vo) {
      if (!this.$ekmtcCommon.isEmpty(this.etaBookingMsg)) {
        this.$ekmtcCommon.asyncAlertMessage({ message: this.etaBookingMsg }) // 해당 국가 ( 한국 ) 수출화물에 대해서만 부킹이 가능합니다.
        return
      }

      // 리스트 타입 부킹 버튼으로 부킹시 무조건 부킹으로 이동하게 되어 있어 프리미엄 부킹 안되도록 강제 설정함
      vo.kmtcPremiumLineYn = 'N'

      this.makeBookingParam(vo)
      if (this.vessel2ndParam !== '') {
        var hidCS008I = this.bkgParams.hidCS008I.split('@_@')
        var nd2hidCS008I = ''
        var length2 = hidCS008I.length - 1
        for (let i = 0; i < length2; i++) {
          if (i === 2) {
            nd2hidCS008I += this.vessel2ndParam + '@_@'
          } else {
            nd2hidCS008I += hidCS008I[i] + '@_@'
          }
        }
        this.bkgParams.hidCS008I = nd2hidCS008I
      }
      this.bkgScheduleData = vo
      this.detailInfo4Booking(vo)
    },
    // 스케줄 상세조회 (달력에서 선택한 항로 조회)
    detailInfo4Booking (vo) {
      let longParam = vo.ts + ';' +
                      vo.vslNm + ';' +
                      vo.vslCd + ';' +
                      vo.voyNo + ';' +
                      vo.transitTime + ';' +
                      vo.polEtb + ';' +
                      vo.polEtbTm + ';' +
                      vo.etd + ';' +
                      vo.etdTm + ';' +
                      vo.eta + ';' +
                      vo.etaTm + ';' +
                      vo.polTml + ';' +
                      vo.podTml + ';' +
                      vo.polPicTel + ';' +
                      vo.itrmlNm + ';' +
                      vo.podPicTel + ';' +
                      vo.closeTime + ';' +
                      vo.cct + ';' +
                      vo.mrnNo + ';' +
                      vo.callSign + ';' +
                      vo.bkgClose + ';' +
                      vo.mfCls + ';' +
                      vo.webCls + ';' +
                      vo.cgoCls + ';' +
                      vo.jrstCstm + ';' +
                      vo.info + ';' +
                      vo.pol + ';' +
                      vo.pod + ';' +
                      vo.bkgDocCls + ';' +
                      vo.bkgCgoCls + ';' +
                      vo.bkgMfCls + ';' +
                      vo.cfsCls + ';' +
                      vo.pod1 + ';' +
                      vo.podEtd1 + ';' +
                      vo.podTrml1 + ';' +
                      vo.podPicTel1 + ';' +
                      vo.etaTm1 + ';' +
                      vo.transitTime1 + ';' +
                      vo.pol1 + ';' +
                      vo.vslNm2 + ';' +
                      vo.vslCd2 + ';' +
                      vo.voyNo2 + ';' +
                      vo.polEtd2 + ';' +
                      vo.podEtd2 + ';' +
                      vo.polTrml2 + ';' +
                      vo.podTrml2 + ';' +
                      vo.polPicNm2 + ';' +
                      vo.polPicTel2 + ';' +
                      vo.podPicTel2 + ';' +
                      vo.etdTm2 + ';' +
                      vo.etaTm2 + ';' +
                      vo.transitTime2 + ';' +
                      vo.tsDegree + ';' +
                      vo.vslNm3 + ';' +
                      vo.vslCd3 + ';' +
                      vo.voyNo3 + ';' +
                      vo.polEtd3 + ';' +
                      vo.etdTm3 + ';' +
                      vo.polTrml3 + ';' +
                      vo.polPicTel3 + ';' +
                      vo.podEtd3 + ';' +
                      vo.etaTm3 + ';' +
                      vo.podTrml3 + ';' +
                      vo.podPicTel3 + ';' +
                      vo.transitTime3 + ';' +
                      vo.vslNm4 + ';' +
                      vo.vslCd4 + ';' +
                      vo.voyNo4 + ';' +
                      vo.polEtd4 + ';' +
                      vo.etdTm4 + ';' +
                      vo.polTrml4 + ';' +
                      vo.polPicTel4 + ';' +
                      vo.podEtd4 + ';' +
                      vo.etaTm4 + ';' +
                      vo.podTrml4 + ';' +
                      vo.podPicTel4 + ';' +
                      vo.transitTime4 + ';' +
                      vo.polTrml1 + ';' +
                      vo.pol2 + ';' +
                      vo.pod3 + ';' +
                      vo.pod4 + ';' +
                      vo.pod2 + ';' +
                      vo.bkgDocPic + ';' +
                      vo.bkgCgoPic + ';' +
                      vo.vslNm1 + ';' +
                      vo.pol3 + ';' +
                      vo.pol4 + ';' +
                      vo.bkgCfsPic + ';' +
                      vo.bkgVslCd + ';' +
                      vo.bkgVoyNo
      const params = {
        param: longParam,
        bound: 'O'
      }
      schedules.getLegScheduleDetail(params).then((rtnData) => {
        this.fnDetailLayerResponse2(rtnData, vo)
        this.bookingBooking(vo)
      })
    },
    // WEB 스케줄 조회 로그 저장
    insertWB242H (logCatCd, pgmCatCd, vo) {
      var params = {}
      if (logCatCd === '01') {
        params = {
          logCatCd: logCatCd,
          pgmCatCd: pgmCatCd,
          polCtrCd: this.scheduleForm.startCtrCd,
          polPortCd: this.scheduleForm.startPlcCd,
          podCtrCd: this.scheduleForm.destCtrCd,
          podPortCd: this.scheduleForm.destPlcCd,
          eiCatCd: this.regParams.bound,
          schInqYm: this.searchYear + this.searchMonth,
          tsYn: this.bkgScheduleData.ts,
          pod1PortCd: this.bkgScheduleData.pod1
        }
      } else {
        params = {
          logCatCd: logCatCd,
          pgmCatCd: pgmCatCd,
          polCtrCd: this.scheduleForm.startCtrCd,
          polPortCd: vo.pol,
          podCtrCd: this.scheduleForm.destCtrCd,
          podPortCd: vo.pod,
          dpoDt: vo.etd,
          dpoTm: vo.etdTm,
          apoDt: vo.eta,
          apoTm: vo.etaTm,
          eiCatCd: this.bound,
          schInqYm: this.searchYear + this.searchMonth,
          vslCd: vo.vslCd,
          voyNo: vo.voyNo,
          closYn: vo.bkgClose,
          tsYn: this.bkgScheduleData.ts,
          pod1PortCd: this.bkgScheduleData.pod1
        }
      }
      //postgre CP 세션 문제로 임시로 막음 jamsin 20220627
      if (logCatCd === '01') {
        schedules.insertWB242H(params).then((resultCode) => {
          // console.log('%%%%%%%%% insertWB242H %%%%%%%%%')
          console.log(resultCode)
        })
      } else {
        if (params.dpoDt !== undefined && params.apoDt !== undefined) {
          schedules.insertWB242H(params).then((resultCode) => {
            // console.log('%%%%%%%%% insertWB242H %%%%%%%%%')
            console.log(resultCode)
          })
        }
      }
      // insert WB242H
    },
    excelDownload () {
      const params = {
        searchYear: this.searchYear,
        searchMonth: this.searchMonth,
        bound: this.regParams.bound,
        startCtrCd: this.scheduleForm.startCtrCd,
        startPlcCd: this.scheduleForm.startPlcCd,
        destCtrCd: this.scheduleForm.destCtrCd,
        destPlcCd: this.scheduleForm.destPlcCd,
        legIdx: '0',
        pointChangeYN: '',
        filterPolCd: '',
        pointLength: '',
        startPlcName: this.scheduleForm.startPlcName,
        filterYn: this.filterYn,
        searchYN: 'Y',
        filterPodCd: '',
        hiddestPlcCd: '',
        polTrmlStr: this.polTrmlStr,
        podTrmlStr: this.podTrmlStr,
        filterTs: this.regParams.filterTS,
        filterDirect: this.regParams.filterDirect,
        filterTranMax: '0',
        filterTranMin: '0',
        hidstartPlcCd: '',
        destPlcName: this.scheduleForm.destPlcName,
        main: 'N',
        vslType01: this.regParams.vslType01,
        vslType03: this.regParams.vslType03
      }
      schedules.excelDownloadLeg(params).then((response) => {
        // console.log('%%%%%%%%% excelDownloadLeg %%%%%%%%%')
        // console.log(resultCode)
        const fileName = params.startPlcCd + '_' + params.destPlcCd + '_LegSchedule.xls'
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(new Blob([response.data]), fileName)
        } else {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]))
          const fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', fileName)
          document.body.appendChild(fileLink)

          fileLink.click()
          fileLink.remove()
          window.URL.revokeObjectURL(fileURL)
        }
      })
    },
    // make a calendar
    calendarData (year, month) {
      const [
        monthFirstDay,
        monthLastDate,
        lastMonthLastDate
      ] = this.getFirstDayLastDate(year, month)

      this.dates = this.getMonthOfDays(
        monthFirstDay,
        monthLastDate,
        lastMonthLastDate
      )
    },
    getFirstDayLastDate (year, month) {
      const firstDay = new Date(year, month - 1, 1).getDay() // 이번 달 시작 요일
      const lastDate = new Date(year, month, 0).getDate() // 이번 달 마지막 날짜
      let lastYear = year
      let lastMonth = month - 1
      if (month === 1) {
        lastMonth = 12
        lastYear -= 1
      }
      const prevLastDate = new Date(lastYear, lastMonth, 0).getDate() // 지난 달 마지막 날짜
      return [firstDay, lastDate, prevLastDate]
    },
    getMonthOfDays (
      monthFirstDay,
      monthLastDate,
      prevMonthLastDate
    ) {
      let day = 1
      let prevDay = (prevMonthLastDate - monthFirstDay) + 1
      const dates = []
      let weekOfDays = []
      while (day <= monthLastDate) {
        if (day === 1) {
          // 1일이 어느 요일인지에 따라 테이블에 그리기 위한 지난 셀의 날짜들을 구할 필요가 있다.
          for (let j = 0; j < monthFirstDay; j += 1) {
            // weekOfDays.push(prevDay) // 전달 날짜 No 필요
            weekOfDays.push('')
            prevDay += 1
          }
        }
        weekOfDays.push(day)
        if (weekOfDays.length === 7) {
          // 일주일 채우면
          dates.push(weekOfDays)
          weekOfDays = [] // 초기화
        }
        day += 1
      }
      const len = weekOfDays.length
      if (len > 0 && len < 7) {
        for (let k = 1; k <= 7 - len; k += 1) {
          // weekOfDays.push(k) // 다음달 날짜 No 필요
          weekOfDays.push('')
        }
      }
      if (weekOfDays.length > 0) dates.push(weekOfDays) // 남은 날짜 추가
      return dates
    },
    // make a calendar
    openUnnoPopup () {
      this.parentInfoSch = { scheduleFlag: 'Y' }
      this.selectFunc = this.callbackUnno
      this.openPopup('UnnoPop')
    },
    callbackUnno (obj) {
      if (obj !== undefined) {
        // TODO: DG(상세검색) 표시부분 확인 >> unno(imdunNo) / class(imoClasCd) / pg(pakgGrpCd)
        this.unnoDesc = obj.imdunNo + '|' + obj.imoClasCd + '|' + obj.pakgGrpCd

        let unnoDescTxt = 'UN No.' + obj.imdunNo
        if (this.$ekmtcCommon.isNotEmpty(obj.imoClasCd)) {
          unnoDescTxt += ', Class ' + obj.imoClasCd
        }

        if (this.$ekmtcCommon.isNotEmpty(obj.pakgGrpNm)) {
          unnoDescTxt += ', Packing group ' + obj.pakgGrpNm
        }

        this.unnoDescTxt = unnoDescTxt
      } else {
        this.$ekmtcCommon.alertDefault(this.$t('js.common.005'))
      }
    },
    async openFreSurchargePopup (vo) {
      // 운임 클릭시 로그저장
      if (this.rmPopOpenFlag) {
        await this.popFreAppNoCheck('Y')
      }
      this.popupVo = vo
      // [SB]해당 구간의 확정 운임(SC No. 발행된 운임)이 있는 화주는 운임확인(1) 팝업(SCHD010P070) 호출, 확정 운임이 없는 온라인 견적대상 화주는 운임확인(2) 팝업(SCHD010P071) 호출
      // 2024.02.23 기준
      // 온라인 화주 : RA운임이 있는 경우 FreSurchargePop.vue에서 운임 보여주고, RA운임이 없는 경우 RM 운임을 FreSurchargePop2.vue에서 보여준다.
      // 비온라인 화주 : RA운임이 있는 경우 FreSurchargePop.vue에서 운임 보여주고, RA운임이 없는 경우 운임 문의 메세지를 노출한다.
      // 즉 비온라인 화주는 RM운임을 보여주지 않음.
      if (this.frtResult === 'Y') {
        // ===========================================
        // [1] R/A No(Rate Application) 정보가 있는 경우
        // ===========================================
        this.openPopup('FreSurchargePop')
      } else if (this.frtResult === 'A') {
        // ===========================================
        // [2] R/M No(Rate Management) 정보가 있는 경우
        // ===========================================
        this.openPopup('FreSurchargePop2')
      }
      // 프로모션 배너 유입으로 스케줄 실행 후 호출 파라미터 초기화 시킴
      if (this.parentInfo.promotionChk === 'Y') {
        this.$store.commit('schedule/updateSuggestionSchedule', null)
//        this.$store.state.schedule.suggestionSchedule = null
      }
    },
    getUsrCtrEiCatCd (porCtrCd, dlyCtrCd) { // 로그인한 사용자의 국가 기준으로 수입수출 구분을 조회한다
      let eiCatCd = this.eiCatCd
      if (this.auth.userId) {
        // 220704. 출발지/도착지에 따라 수입/수출 구분을 넣어준다.
        if (this.auth.userCtrCd === porCtrCd) {
          eiCatCd = 'O' // 수출
        } else if (this.auth.userCtrCd === dlyCtrCd) {
          eiCatCd = 'I' // 수입
        } else {
          eiCatCd = 'O' // 수출(해외->해외)
        }
      } /*else { // 로그인 안했을대는 기존 세팅 로직 그대로
        this.eiCatCd = (this.bkgParams.podCtrCd === 'KR') ? 'I' : 'O'
        if (this.oengOnlYn === 'Y' && this.userCtrCd !== 'KR') {
          this.eiCatCd = 'O'
        }
        if ('TH|CN|HK|ID|IN'.indexOf(this.userCtrCd) > -1) {
          this.eiCatCd = 'O'
        }
      }*/
      console.log(`>>>>> call getUsrCtrEiCatCd : usrId => ${this.auth.userId}, eiCatCd => ${eiCatCd}`)

      return eiCatCd
    },
    async openRequestQuotePopup () {
      // 20220704 비로그인시 제한 추가
      if (!this.auth.userId) {
        // 로그인이 필요한 메뉴입니다.
        if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.MAIN010G030.034') })) {
          if (!this.$store.getters['auth/requireLogin']) {
            this.$store.commit('auth/requireLogin', !this.$store.getters['auth/requireLogin'])
          }
        }
        return
      }

      if (this.rmPopOpenFlag) {
        this.popFreAppNoCheck('Y')
        // 온라인 견적이 적용된 경우 - 온라인화주
        const params4Fre = {
          porCtrCd: this.scheduleForm.startCtrCd,
          porPlcCd: this.scheduleForm.startPlcCd,
          dlyCtrCd: this.scheduleForm.destCtrCd,
          dlyPlcCd: this.scheduleForm.destPlcCd,
          eiCatCd: this.eiCatCd,
          etd: this.detailResp2.etd
        }

        const cntrMsgInfo = await this.getCntrTypeMessage()

        if (cntrMsgInfo.isOk) {
          params4Fre.cntrTypCd = cntrMsgInfo.rtnVal
            await schedules.fnPopFreReq(params4Fre).then((response) => {
              const result = response.data.insertRateMngResult
              let msg = ''
              if (result !== undefined && result === 1) {
                //msg = this.$t('msg.FARE010T010.022') // 문의하신 구간에 대한 운임등록 요청이 접수되었습니다. 잠시 후에 다시 확인하시기 바랍니다.
                console.log('333')

                const confirmOpt = {
                  alertType: 'simple',
                  customCloseBtnText: this.$t('msg.VOSD100_M5.004'), // 닫기
                  customConfirmBtnText: this.$t('msg.VOSD100_M5.003'), // 확인
                  useConfirmBtn: true,
                  message: `${this.$t('msg.FARE010T010.025')}\n${this.$t('msg.FARE010T010.026')}`, // 현재 요청하신 기간 및 구간에 등록된 운임이 없습니다.\n영업 사원에게 문의 하시겠습니까?
                  onConfirm: this.sendSalEmail
                }

                this.$ekmtcCommon.alert(confirmOpt)
              } else if (result !== undefined && result === 2) { // 등록된 운임이 있음
                msg = this.$t('msg.FARE010T010.024')
                this.$ekmtcCommon.alertDefault(msg)
              } else if (result !== undefined && result === 3) { // 요청중인 내역이 있는경우 메시지만 동일하게 출력
                //msg = this.$t('msg.FARE010T010.022') // 문의하신 구간에 대한 운임등록 요청이 접수되었습니다. 잠시 후에 다시 확인하시기 바랍니다.
                console.log('444')

                const confirmOpt = {
                  alertType: 'simple',
                  customCloseBtnText: this.$t('msg.VOSD100_M5.004'), // 닫기
                  customConfirmBtnText: this.$t('msg.VOSD100_M5.003'), // 확인
                  useConfirmBtn: true,
                  message: `${this.$t('msg.FARE010T010.025')}\n${this.$t('msg.FARE010T010.026')}`, // 현재 요청하신 기간 및 구간에 등록된 운임이 없습니다.\n영업 사원에게 문의 하시겠습니까?
                  onConfirm: this.sendSalEmail
                }

                this.$ekmtcCommon.alert(confirmOpt)
              } else {
                msg = this.$t('msg.FARE010T010.023') // 처리 중 오류가 발생하였습니다. 시스템 관리자에게 문의해 주세요.
                this.$ekmtcCommon.alertDefault(msg)
              }
              // this.$ekmtcCommon.alertDefault(msg)
            })
          }
      } else {
        // 온라인 견적이 적용되지 않은 경우 - 일반화주
        // 운임 클릭시 로그저장
        this.popFreAppNoCheck('Y')
        const TH = this
        // 문의하신 구간에 등록된 운임이 없습니다. 견적 문의를 요청하시겠습니까?
        if (await TH.$ekmtcCommon.asyncAlertMessage({ message: TH.$t('msg.FARE010T010.021'), useConfirmBtn: true })) {
          TH.surChargeParams = TH.params
          TH.openPopup('RequestQuotePop')
        }
      }
    },
    // 영업사원 설정
    async fnSalUnoSet () {
      const porCd = this.scheduleForm.startCtrCd
      const dlyCd = this.scheduleForm.destCtrCd

      if (this.$ekmtcCommon.isEmpty(porCd) || this.$ekmtcCommon.isEmpty(dlyCd)) {
        return
      }

      const tmpParam = {
        porCd: porCd,
        dlyCd: dlyCd
      }

      await schedules.findSalUno(tmpParam)
        .then((response) => {
          console.log('response @@@@@ ', response)

          if (response.status === 200) {
            const respData = response.data

            if (respData.eiCatCd !== undefined) {
              const eiCatCd = respData.eiCatCd
              let frtTerm

              if (eiCatCd === 'O' || eiCatCd === 'OJ' || eiCatCd === 'OJRF') {
                frtTerm = 'P'
              } else if (eiCatCd === 'I') {
                frtTerm = 'C'
              }
            }
            if (respData.salNm !== undefined) {
              this.salInfo.salNm = respData.salNm
            }
            if (respData.salTelNo !== undefined) {
              this.salInfo.salTelNo = respData.salTelNo
            }
            if (respData.salUno !== undefined) {
              this.salInfo.salUno = respData.salUno
            }
            if (respData.salEmlAddr !== undefined) {
              this.salInfo.salEmlAddr = respData.salEmlAddr
            }
          }
        })
    },
    // 운임 관련 담당 영업사원 이메일 전송
    async sendSalEmail () {
      const param = {
        polCtrCd: this.scheduleForm.startCtrCd,
        podCtrCd: this.scheduleForm.destCtrCd,
        polPortCd: this.scheduleForm.startPlcCd,
        podPortCd: this.scheduleForm.destPlcCd,
        salEmlAddr: this.salInfo.salEmlAddr
      }

      schedules.sendSalEmail(param)
        .then(async (response) => {
          console.log('sendSalEmail response @@@@@ ', response)

          if (response.headers.respmsg === 'SUCC') {
            this.$ekmtcCommon.alertDefault(this.$t('art.CMATK146')) // 메일이 전송되었습니다.
          } else {
            this.$ekmtcCommon.alertDefault(this.$t('mgs.SETT060G010.049')) // 전송에 실패했습니다.
          }
        })
        .catch(err => {
          console.log(err)
          this.$ekmtcCommon.alertDefault(this.$t('mgs.SETT060G010.049')) // 전송에 실패했습니다.
        })
    },
    goRequestQuote () {
      this.openPopup('RequestQuotePop', 'N')
    },
    async openPopup (compNm, etc, etc2) {
      // 팝업 오픈전 kmtc on 관련 초기화
      this.popupParams.FreSurchargePop.kmtcSpotYn = 'N'
      this.popupParams.FreSurchargePop2.kmtcSpotYn = 'N'

      this.fromBkg = 'N'
      const elemSchPopup = document.querySelector('#sch_popup')
      if (this.customComponentSch !== null) {
        this.customComponentSch = null
        this.$ekmtcCommon.layerClose('.content_box > .popup_dim')
        elemSchPopup.style.display = 'none'
      }
      if (compNm === 'EmailSchedulePop') {
        if (!this.auth.userId) {
          // 로그인이 필요한 메뉴입니다.
          if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.MAIN010G030.034') })) {
          // 로그인을 확인해주세요.
          // if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.REPORT_PDF.115') })) {
            if (!this.$store.getters['auth/requireLogin']) {
              this.$store.commit('auth/requireLogin', !this.$store.getters['auth/requireLogin'])
            }
          }
          return
        }

        this.popupParams.EmailSchedulePop.polCtrCd = this.scheduleForm.startCtrCd
        this.popupParams.EmailSchedulePop.polPlcCd = this.scheduleForm.startPlcCd
        this.popupParams.EmailSchedulePop.polPlcNm = this.scheduleForm.startPlcName
        this.popupParams.EmailSchedulePop.podCtrCd = this.scheduleForm.destCtrCd
        this.popupParams.EmailSchedulePop.podPlcCd = this.scheduleForm.destPlcCd
        this.popupParams.EmailSchedulePop.podPlcNm = this.scheduleForm.destPlcName
        this.popupParams.EmailSchedulePop.eiCatCd = this.bound
      } else if (compNm === 'VesselScheduleInfoPop') {
        this.selectFunc = ''
        this.popupParams.VesselScheduleInfoPop.vesselSeq = etc
        if (this.calendarOrList === 'C') {
          // Calendar
          if (etc === 2) {
            this.popupParams.VesselScheduleInfoPop.vslNm = this.detailResp2.vslNm2
            this.popupParams.VesselScheduleInfoPop.vslCd = this.detailResp2.vslCd2
            this.popupParams.VesselScheduleInfoPop.voyNo = this.detailResp2.voyNo2
            this.popupParams.VesselScheduleInfoPop.polPortCd = this.detailResp2.pol2
            this.popupParams.VesselScheduleInfoPop.podPortCd = this.detailResp2.pod2
            this.popupParams.VesselScheduleInfoPop.polTrmlCd = this.detailResp2.polTml2
            this.popupParams.VesselScheduleInfoPop.rteCd = this.detailResp2.rteCd2
          } else if (etc === 3) {
            this.popupParams.VesselScheduleInfoPop.vslNm = this.detailResp2.vslNm3
            this.popupParams.VesselScheduleInfoPop.vslCd = this.detailResp2.vslCd3
            this.popupParams.VesselScheduleInfoPop.voyNo = this.detailResp2.voyNo3
            this.popupParams.VesselScheduleInfoPop.polPortCd = this.detailResp2.pol3
            this.popupParams.VesselScheduleInfoPop.podPortCd = this.detailResp2.pod3
            this.popupParams.VesselScheduleInfoPop.polTrmlCd = this.detailResp2.polTml3
            this.popupParams.VesselScheduleInfoPop.rteCd = this.detailResp2.rteCd3
          } else if (etc === 4) {
            this.popupParams.VesselScheduleInfoPop.vslNm = this.detailResp2.vslNm4
            this.popupParams.VesselScheduleInfoPop.vslCd = this.detailResp2.vslCd4
            this.popupParams.VesselScheduleInfoPop.voyNo = this.detailResp2.voyNo4
            this.popupParams.VesselScheduleInfoPop.polPortCd = this.detailResp2.pol4
            this.popupParams.VesselScheduleInfoPop.podPortCd = this.detailResp2.pod4
            this.popupParams.VesselScheduleInfoPop.polTrmlCd = this.detailResp2.polTml4
            this.popupParams.VesselScheduleInfoPop.rteCd = this.detailResp2.rteCd4
          } else {
            this.popupParams.VesselScheduleInfoPop.vslNm = this.detailResp2.vslNm1
            this.popupParams.VesselScheduleInfoPop.vslCd = this.detailResp2.vslCd
            this.popupParams.VesselScheduleInfoPop.voyNo = this.detailResp2.voyNo
            this.popupParams.VesselScheduleInfoPop.polPortCd = this.detailResp2.ts === 'Y' ? this.detailResp2.pol1 : this.detailResp2.pol
            this.popupParams.VesselScheduleInfoPop.podPortCd = this.detailResp2.ts === 'Y' ? this.detailResp2.pod1 : this.detailResp2.pod
            this.popupParams.VesselScheduleInfoPop.polTrml = this.detailResp2.polTml
            this.popupParams.VesselScheduleInfoPop.rteCd = this.detailResp2.rteCd
            this.popupParams.VesselScheduleInfoPop.polTrmlCd = this.detailResp2.polTmlCd
          }
          this.popupParams.VesselScheduleInfoPop.bound = this.bound
          this.popupParams.VesselScheduleInfoPop.bkgDocCls = this.detailResp2.bkgDocCls
          this.popupParams.VesselScheduleInfoPop.bkgCgoCls = this.detailResp2.bkgCgoCls
          this.popupParams.VesselScheduleInfoPop.bkgMfCls = this.detailResp2.bkgMfCls
          this.popupParams.VesselScheduleInfoPop.cfsCls = this.detailResp2.cfsCls
          this.popupParams.VesselScheduleInfoPop.mrnNo = this.detailResp2.mrnNo
          this.popupParams.VesselScheduleInfoPop.polCtrCd = this.detailResp2.polCtrCd
          this.popupParams.VesselScheduleInfoPop.podCtrCd = this.detailResp2.podCtrCd
          this.popupParams.VesselScheduleInfoPop.polTml = this.detailResp2.polTml
          this.popupParams.VesselScheduleInfoPop.polTrmlCd = this.detailResp2.polTmlCd
          this.popupParams.VesselScheduleInfoPop.vgmDocCls = this.detailResp2.vgmDocCls
        } else {
          // List
          if (etc === 2) {
            this.popupParams.VesselScheduleInfoPop.vslNm = etc2.vslNm2
            this.popupParams.VesselScheduleInfoPop.vslCd = etc2.vslCd2
            this.popupParams.VesselScheduleInfoPop.voyNo = etc2.voyNo2
            this.popupParams.VesselScheduleInfoPop.polPortCd = etc2.pol2
            this.popupParams.VesselScheduleInfoPop.podPortCd = etc2.pod2
            this.popupParams.VesselScheduleInfoPop.polTrmlCd = etc2.polTrml2
          } else if (etc === 3) {
            this.popupParams.VesselScheduleInfoPop.vslNm = etc2.vslNm3
            this.popupParams.VesselScheduleInfoPop.vslCd = etc2.vslCd3
            this.popupParams.VesselScheduleInfoPop.voyNo = etc2.voyNo3
            this.popupParams.VesselScheduleInfoPop.polPortCd = etc2.pol3
            this.popupParams.VesselScheduleInfoPop.podPortCd = etc2.pod3
            this.popupParams.VesselScheduleInfoPop.polTrmlCd = etc2.polTrml3
          } else if (etc === 4) {
            this.popupParams.VesselScheduleInfoPop.vslNm = etc2.vslNm4
            this.popupParams.VesselScheduleInfoPop.vslCd = etc2.vslCd4
            this.popupParams.VesselScheduleInfoPop.voyNo = etc2.voyNo4
            this.popupParams.VesselScheduleInfoPop.polPortCd = etc2.pol4
            this.popupParams.VesselScheduleInfoPop.podPortCd = etc2.pod4
            this.popupParams.VesselScheduleInfoPop.polTrmlCd = etc2.polTrml4
          } else {
            this.popupParams.VesselScheduleInfoPop.vslNm = etc2.vslNm1
            this.popupParams.VesselScheduleInfoPop.vslCd = etc2.vslCd
            this.popupParams.VesselScheduleInfoPop.voyNo = etc2.voyNo
            this.popupParams.VesselScheduleInfoPop.polPortCd = etc2.pol1
            this.popupParams.VesselScheduleInfoPop.podPortCd = etc2.pod1
            this.popupParams.VesselScheduleInfoPop.polTrmlCd = etc2.polTml
          }
          this.popupParams.VesselScheduleInfoPop.bound = this.bound
          this.popupParams.VesselScheduleInfoPop.bkgDocCls = this.changeDateTime2Display(etc2.bkgDocCls, this.lang)
          this.popupParams.VesselScheduleInfoPop.bkgCgoCls = this.changeDateTime2Display(etc2.bkgCgoCls, this.lang)
          this.popupParams.VesselScheduleInfoPop.bkgMfCls = this.changeDateTime2Display(etc2.bkgMfCls, this.lang)
          this.popupParams.VesselScheduleInfoPop.cfsCls = this.changeDateTime2Display(etc2.cfsCls, this.lang)
          this.popupParams.VesselScheduleInfoPop.mrnNo = etc2.mrnNo
          this.popupParams.VesselScheduleInfoPop.polCtrCd = etc2.polCtrCd
          this.popupParams.VesselScheduleInfoPop.podCtrCd = etc2.podCtrCd
        }
        this.popupParams.VesselScheduleInfoPop.menuCd = '01'
      } else if (compNm === 'RoutePop') {
        this.selectFunc = ''
        this.popupParams.RoutePop.rteCd = etc
        this.popupParams.RoutePop.vesselNm = etc2
      } else if (compNm === 'PortTerminalCYPop') {
        this.selectFunc = ''
        // alert('PortTerminalCY (SCHD010P050) Pop-up')
        this.popupParams.PortTerminalCYPop.asPortCd = etc
        this.popupParams.PortTerminalCYPop.asCyCd = etc2
        this.popupParams.PortTerminalCYPop.asSvcLang = '01'
      } else if (compNm === 'SCHD010P060') {
        alert('Map (SCHD010P060) Pop-up')
      } else if (compNm === 'FreSurchargePop') {
        this.selectFunc = ''
        // 운임확인(1) > SCHD010P070
        this.popupParams.FreSurchargePop.etaBookingMsg = this.etaBookingMsg // 부킹시 출력 메시지를 운임확인 팝업에 전달
        this.popupParams.FreSurchargePop.promotionChk = this.parentInfo.promotionChk // 프로모션 배너 통한 진입 여부 전달
        this.popupParams.FreSurchargePop.scenarioCd = this.parentInfo.scenarioCd // 프로모션 배너 통한 진입 여부 전달
        this.popupParams.FreSurchargePop.promoNo = this.parentInfo.promoNo // 프로모션 배너 통한 진입 여부 전달
        this.popupParams.FreSurchargePop.hongkongTsYn = this.hongkongTsYn
        this.popupParams.FreSurchargePop.hongkongTsMsg = this.hongkongTsMsg
        if (this.calendarOrList === 'C') {
          this.popupParams.FreSurchargePop.porCtrCd = this.scheduleForm.startCtrCd
          this.popupParams.FreSurchargePop.porPlcCd = this.detailResp2.pol
          this.popupParams.FreSurchargePop.dlyCtrCd = this.scheduleForm.destCtrCd
          this.popupParams.FreSurchargePop.dlyPlcCd = this.detailResp2.pod
          this.popupParams.FreSurchargePop.polNm = this.detailResp2.polNm
          this.popupParams.FreSurchargePop.podNm = this.detailResp2.podNm
          this.popupParams.FreSurchargePop.startDt = this.detailResp2.strDt
          this.popupParams.FreSurchargePop.endDt = this.detailResp2.endDt
          this.popupParams.FreSurchargePop.etd = this.detailResp2.etd
          this.popupParams.FreSurchargePop.frtAppNo = this.detailResp2.frtAppNo
          this.popupParams.FreSurchargePop.frtEstnYn = this.detailResp2.frtEstnYn
          this.popupParams.FreSurchargePop.vslNm = this.detailResp2.vslNm
          this.popupParams.FreSurchargePop.vslCd = this.detailResp2.vslCd
          this.popupParams.FreSurchargePop.voyNo = this.detailResp2.voyNo
          this.popupParams.FreSurchargePop.rteCd = this.detailResp2.rteCd
          this.popupParams.FreSurchargePop.eiCatCd = this.eiCatCd

          // 기존 소스에 영향 주지 않고 프로모션인 경우만 처리함
          if (this.parentInfo.promotionChk === 'Y') {
            this.popupParams.FreSurchargePop.frtResult = this.frtResult
          } else {
            this.popupParams.FreSurchargePop.frtResult = this.detailResp2.frtResult
          }

          this.popupParams.FreSurchargePop.reqRno = this.detailResp2.reqRno
          this.popupParams.FreSurchargePop.bkgClose = this.detailResp2.bkgClose
          this.popupParams.FreSurchargePop.raTsParam = this.raTsParam
          this.popupParams.FreSurchargePop.detailResp2 = this.detailResp2
        } else {
          this.popupParams.FreSurchargePop.porCtrCd = this.scheduleForm.startCtrCd
          this.popupParams.FreSurchargePop.porPlcCd = this.detailResp2.pol
          this.popupParams.FreSurchargePop.dlyCtrCd = this.scheduleForm.destCtrCd
          this.popupParams.FreSurchargePop.dlyPlcCd = this.detailResp2.pod
          this.popupParams.FreSurchargePop.polNm = this.popupVo.polNm
          this.popupParams.FreSurchargePop.podNm = this.popupVo.podNm
          this.popupParams.FreSurchargePop.startDt = this.popupVo.strDt
          this.popupParams.FreSurchargePop.endDt = this.popupVo.endDt
          this.popupParams.FreSurchargePop.etd = this.popupVo.etd
          this.popupParams.FreSurchargePop.frtAppNo = this.popupVo.frtAppNo
          this.popupParams.FreSurchargePop.frtEstnYn = this.popupVo.frtEstnYn
          this.popupParams.FreSurchargePop.vslCd = this.popupVo.vslCd
          this.popupParams.FreSurchargePop.voyNo = this.popupVo.voyNo
          this.popupParams.FreSurchargePop.rteCd = this.popupVo.rteCd
          this.popupParams.FreSurchargePop.eiCatCd = this.eiCatCd
          this.popupParams.FreSurchargePop.frtResult = this.popupVo.frtResult
          this.popupParams.FreSurchargePop.reqRno = this.popupVo.reqRno
          this.popupParams.FreSurchargePop.bkgClose = this.popupVo.bkgClose
          this.popupParams.FreSurchargePop.raTsParam = this.raTsParam
          this.popupParams.FreSurchargePop.detailResp2 = this.popupVo
        }
        this.popupParams.FreSurchargePop.refFrtAppNo = this.detailResp2.refFrtAppNo
        this.popupParams.FreSurchargePop.hotDealYn = this.detailResp2.hotDealYn
        this.popupParams.FreSurchargePop.rmPopOpenFlag = this.rmPopOpenFlag ? 'Y' : 'N'
        this.popupParams.FreSurchargePop.urlOrNot = this.urlOrNot
        this.popupParams.FreSurchargePop.kmtcPremiumNegoYn = this.kmtcPremiumNegoYn

        // KMTC ON 화주이면서 선택한 구간이 KMTC ON인 경우 운임 확인 팝업창에 KMTC ON 운임 노출하도록 설정
        if (this.kmtcPremiumUserYn === 'Y' && this.kmtcPremiumLineYn === 'Y') {
          this.popupParams.FreSurchargePop.kmtcSpotYn = 'Y'
        }
      } else if (compNm === 'FreSurchargePop2') {
        this.selectFunc = ''
        // 운임확인(2) > SCHD010P071
        this.popupParams.FreSurchargePop2.etaBookingMsg = this.etaBookingMsg // 부킹시 출력 메시지를 운임확인 팝업에 전달
        this.popupParams.FreSurchargePop2.promotionChk = this.parentInfo.promotionChk // 프로모션 배너 통한 진입 여부 전달
        this.popupParams.FreSurchargePop2.scenarioCd = this.parentInfo.scenarioCd // 프로모션 배너 통한 진입 여부 전달
        this.popupParams.FreSurchargePop2.promoNo = this.parentInfo.promoNo // 프로모션 배너 통한 진입 여부 전달
        this.popupParams.FreSurchargePop2.hongkongTsYn = this.hongkongTsYn
        this.popupParams.FreSurchargePop2.hongkongTsMsg = this.hongkongTsMsg
        if (this.calendarOrList === 'C') {
          this.popupParams.FreSurchargePop2.porCtrCd = this.scheduleForm.startCtrCd
          this.popupParams.FreSurchargePop2.porPlcCd = this.detailResp2.pol
          this.popupParams.FreSurchargePop2.dlyCtrCd = this.scheduleForm.destCtrCd
          this.popupParams.FreSurchargePop2.dlyPlcCd = this.detailResp2.pod
          this.popupParams.FreSurchargePop2.polNm = this.detailResp2.polNm
          this.popupParams.FreSurchargePop2.podNm = this.detailResp2.podNm
          this.popupParams.FreSurchargePop2.startDt = this.detailResp2.strDt
          this.popupParams.FreSurchargePop2.endDt = this.detailResp2.endDt
          this.popupParams.FreSurchargePop2.etd = this.detailResp2.etd
          this.popupParams.FreSurchargePop2.frtAppNo = this.detailResp2.frtAppNo
          this.popupParams.FreSurchargePop2.vslCd = this.detailResp2.vslCd
          this.popupParams.FreSurchargePop2.vslNm = this.detailResp2.vslNm
          this.popupParams.FreSurchargePop2.voyNo = this.detailResp2.voyNo
          this.popupParams.FreSurchargePop2.rteCd = this.detailResp2.rteCd
          this.popupParams.FreSurchargePop2.eiCatCd = this.eiCatCd
          this.popupParams.FreSurchargePop2.frtResult = this.detailResp2.frtResult
          this.popupParams.FreSurchargePop2.reqRno = this.detailResp2.reqRno
          this.popupParams.FreSurchargePop2.bkgClose = this.detailResp2.bkgClose
          this.popupParams.FreSurchargePop2.raTsParam = this.raTsParam
          this.popupParams.FreSurchargePop2.detailResp2 = this.detailResp2
        } else {
          this.popupParams.FreSurchargePop2.porCtrCd = this.scheduleForm.startCtrCd
          this.popupParams.FreSurchargePop2.porPlcCd = this.detailResp2.pol
          this.popupParams.FreSurchargePop2.dlyCtrCd = this.scheduleForm.destCtrCd
          this.popupParams.FreSurchargePop2.dlyPlcCd = this.detailResp2.pod
          this.popupParams.FreSurchargePop2.polNm = this.popupVo.polNm
          this.popupParams.FreSurchargePop2.podNm = this.popupVo.podNm
          this.popupParams.FreSurchargePop2.startDt = this.popupVo.strDt
          this.popupParams.FreSurchargePop2.endDt = this.popupVo.endDt
          this.popupParams.FreSurchargePop2.etd = this.popupVo.etd
          this.popupParams.FreSurchargePop2.frtAppNo = this.popupVo.frtAppNo
          this.popupParams.FreSurchargePop2.vslCd = this.popupVo.vslCd
          this.popupParams.FreSurchargePop2.vslNm = this.popupVo.vslNm
          this.popupParams.FreSurchargePop2.voyNo = this.popupVo.voyNo
          this.popupParams.FreSurchargePop2.rteCd = this.popupVo.rteCd
          this.popupParams.FreSurchargePop2.eiCatCd = this.eiCatCd
          this.popupParams.FreSurchargePop2.frtResult = this.popupVo.frtResult
          this.popupParams.FreSurchargePop2.reqRno = this.popupVo.reqRno
          this.popupParams.FreSurchargePop2.bkgClose = this.popupVo.bkgClose
          this.popupParams.FreSurchargePop2.raTsParam = this.raTsParam
          this.popupParams.FreSurchargePop2.detailResp2 = this.popupVo
        }
        this.popupParams.FreSurchargePop2.refFrtAppNo = this.detailResp2.refFrtAppNo
        this.popupParams.FreSurchargePop2.hotDealYn = this.detailResp2.hotDealYn
        this.popupParams.FreSurchargePop2.urlOrNot = this.urlOrNot
        this.popupParams.FreSurchargePop2.kmtcPremiumNegoYn = this.kmtcPremiumNegoYn

        // KMTC ON 화주이면서 선택한 구간이 KMTC ON인 경우 운임 확인 팝업창에 KMTC ON 운임 노출하도록 설정
        if (this.kmtcPremiumUserYn === 'Y' && this.kmtcPremiumLineYn === 'Y') {
          this.popupParams.FreSurchargePop2.kmtcSpotYn = 'Y'
        }
      } else if (compNm === 'RequestQuotePop') {
        this.selectFunc = ''
        this.popupParams.RequestQuotePop.porCtrCd = this.scheduleForm.startCtrCd
        this.popupParams.RequestQuotePop.porPlcCd = this.scheduleForm.startPlcCd
        this.popupParams.RequestQuotePop.dlyCtrCd = this.scheduleForm.destCtrCd
        this.popupParams.RequestQuotePop.dlyPlcCd = this.scheduleForm.destPlcCd
        this.popupParams.RequestQuotePop.negoYN = etc
        this.popupParams.RequestQuotePop.yyyymmdd = this.detailResp2.etd
        //this.popupParams.RequestQuotePop.vslCd = this.detailResp2.vslCd
        //this.popupParams.RequestQuotePop.voyNo = this.detailResp2.voyNo
      } else if (compNm === 'ChangeNoticePop') {
        //alert('운임문의 (111) Pop-up')
        this.selectFunc = ''
        //this.popupParams.ChangeNoticePop.items = [
        this.parentInfoSch.items = [
          {
            vslCd: this.detailResp2.vslCd,
            vslNm: this.detailResp2.vslNm,
            voyNo: this.detailResp2.voyNo,
            polPortCd: this.detailResp2.ts === 'Y' ? this.detailResp2.pol1 : this.detailResp2.pol,
            podPortCd: this.detailResp2.ts === 'Y' ? this.detailResp2.pod1 : this.detailResp2.pod,
            gubun: '99Vs',
            docNo: '',
            editTable: '',
            bound: this.eiCatCd
          }
        ]
      } else if (compNm === '222') {
        alert('운임문의 (222) Pop-up')
      }

      if (compNm !== 'ChangeNoticePop') {
        this.parentInfoSch = this.popupParams[compNm]
      }
      // console.log('=====================================')
      // console.log(compNm)
      // console.log('-------------------------------------')
      // console.log(this.parentInfoSch)
      // console.log('-------------------------------------')
      this.customComponentSch = compNm
      if (compNm === 'EmailSchedulePop' ||
          compNm === 'VesselScheduleInfoPop' ||
          compNm === 'RoutePop' ||
          compNm === 'PortTerminalCYPop' ||
          compNm === 'FreSurchargePop' ||
          compNm === 'FreSurchargePop2' ||
          compNm === 'RequestQuotePop' ||
          compNm === 'ChangeNoticePop' ||
          compNm === 'UnnoPop'
      ) {
        this.$ekmtcCommon.layerOpen('#sch_popup')
        elemSchPopup.style.display = 'block'
      }
    },
    closePopup (obj) {
      const elemSchPopup = document.querySelector('#sch_popup')
      if (this.customComponentSch === 'BusinessNumberPop') {
        this.popupParams.BusinessNumberPop.bsnNm = obj.bsnNm
        this.popupParams.BusinessNumberPop.bzrgNo = obj.bzrgNo
        this.popupParams.BusinessNumberPop.cstNo = obj.cstNo
        this.popupParams.BusinessNumberPop.addr = obj.addr
        this.popupParams.BusinessNumberPop.faxNo = obj.faxNo
        this.popupParams.BusinessNumberPop.telNo = obj.telNo
        this.customComponentSch = null
        this.$ekmtcCommon.layerClose('.content_box > .popup_dim')
        elemSchPopup.style.display = 'none'
      } else if (this.customComponentSch === 'FreSurchargePop2') {
        if (obj.acceptYn) {
          this.popFreAppNoCheck('Y')
        }
        this.customComponentSch = null
        this.$ekmtcCommon.layerClose('.content_box > .popup_dim')
        elemSchPopup.style.display = 'none'

        // 운임확정 후 네고요청을 한경우 네고팝업 호출
        if (obj && obj.negoYN === 'Y') {
          this.popupParams.RequestQuotePop = obj
          this.openPopup('RequestQuotePop', obj.negoYN)
        }
      } else {
        if (obj && obj.negoYN) {
          this.customComponentSch = null
          this.$ekmtcCommon.layerClose('.content_box > .popup_dim')
          elemSchPopup.style.display = 'none'
          this.popupParams.RequestQuotePop = obj
          this.openPopup('RequestQuotePop', obj.negoYN)
        } else {
          this.customComponentSch = null
          this.$ekmtcCommon.layerClose('.content_box > .popup_dim')
          elemSchPopup.style.display = 'none'
        }
      }
    },
    resetUnnoTxt () {
      this.unnoDescTxt = ''
      this.unnoDesc = ''
    },
    clickChk (searchYear, searchMonth) {
      // 프로모션 배너가 아닌 스케줄 화면 검색시 프로모션 비활성화 처리
      this.parentInfo.promotionChk = 'N'
      this.getLegSchedule(searchYear, searchMonth)
    },
    async pdfDownload (fileName) {
      const result = await intergis.excelDownload(fileName)
      console.log('excelDownload')
      console.log(result)
      //blob 생성
      const blob = new Blob([result.data], { type: result.headers['content-type'] })

      if (window.navigator.msSaveOrOpenBlob) { //browser Check - IE
        window.navigator.msSaveOrOpenBlob(blob, fileName)
      } else { //browser Check - 그 외
        //<a>태그에 URL화 시킨 blob객체를 연결시킨 후 다운로드
        const link = document.createElement('a')
        const url = window.URL.createObjectURL(blob)
        link.href = url
        link.target = '_self'
        link.download = fileName
        link.click()

        //url 사용 후 메모리에서 제거
        window.URL.revokeObjectURL(url)
      }
    },
    async goScheduleKmtcSpot () {
      const params = {
        porCtrCd: this.scheduleForm.startCtrCd,
        porPlcCd: this.scheduleForm.startPlcCd,
        dlyCtrCd: this.scheduleForm.destCtrCd,
        dlyPlcCd: this.scheduleForm.destPlcCd,
        yyyymm: this.yyyymmLeg.substring(0, 4) + '' + this.yyyymmLeg.substring(4, 6),
        loginChk: ''// 로그인 없이 스케쥴 메뉴 진입하도록 예외 처리함
      }
      this.$router.push({ name: 'kmtc-spot', query: params }).catch(() => {})
      //this.$router.push({ name: 'long-term', query: params }).catch(() => {})
    }
  }
}
</script>

<style scoped>
  .td_underline {cursor: pointer; color: #000 !important;}
</style>
